import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPerformanceSummaryAndResultsAction,
  getSummaryAction,
  previewDocumentsAction,
} from "../actions";
import {
  getLoading,
  getData,
  getSummaryData,
  getIsAcceptRejectEnabled,
} from "../selectors";
import { getSocietySelected } from "@icarius-pages/login/selectors";
import { getUserData } from "src/app/selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getFeedbackAction, getFeedbackPrivacyOptionsAction } from "@icarius-pages/feedback/actions";
import {
  getIsLoadingPrivacyData,
  getTypeList,
  getModeList,
  getPeople,
  getGroups,
  getTasks,
  getGoals,
  getIsLoadingGridData,
  getIsLoadingFeed,
  getComments,
  getIsLoadingComments,
} from "@icarius-pages/feedback/selectors";

const usePerformanceEvaluations = () => {

  const [selectedProcessCode, setSelectedProcessCode] = useState(null); // se guarda solo el code facilitar la actualizacion de datos despues de hacer el acceptReject
  const [selectedEvaluation, setSelectedEvaluation] = useState(null);
  const [acceptRejectData, setAcceptRejectData] = useState(null);
  const [feedbackFormData, setFeedbackFormData] = useState(null);
  const [viewFeedbackIsOpen, setViewFeedbackIsOpen] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const data = useSelector(getData);
  const summaryData = useSelector(getSummaryData);
  const isAcceptRejectEnabled = useSelector(getIsAcceptRejectEnabled);
  const isLoading = useSelector(getLoading);
  const societySelected = useSelector(getSocietySelected);

  const { name, level, code } = userData;

  const selectedProcess = useMemo(() => {
    if (!selectedProcessCode || !data) return null;
    return data.find((item) => item.process === selectedProcessCode)
  }, [data, selectedProcessCode]);

  //feedback
  const typeList = useSelector(getTypeList);
  const modeList = useSelector(getModeList);
  const people = useSelector(getPeople);
  const groups = useSelector(getGroups);
  const tasks = useSelector(getTasks);
  const goals = useSelector(getGoals);
  const comments = useSelector(getComments);

  const isLoadingPrivacyData = useSelector(getIsLoadingPrivacyData);
  const isLoadingGridData = useSelector(getIsLoadingGridData);
  const isLoadingFeed = useSelector(getIsLoadingFeed);
  const isLoadingComments = useSelector(getIsLoadingComments);

  const isLoadingFeedback = {
    gridData: isLoadingGridData,
    privacyData: isLoadingPrivacyData,
    feed: isLoadingFeed,
    comments: isLoadingComments,
  }

  useEffect(() => {
    dispatch(getPerformanceSummaryAndResultsAction())
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(getFeedbackAction(false))
            .then((resp) => {
              if (resp.status === 200) {
                dispatch(getFeedbackPrivacyOptionsAction())
                  .then((resp) => {
                    if (resp.status === 200) {
                    }
                  })
              }
            })
        }
      })
  }, [dispatch])

  const handleSelectProcess = (data) => {
    setSelectedProcessCode(data.process);
  }

  const handleUnselectProcess = () => {
    setSelectedProcessCode(null);
  }

  const handleOpenSummary = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(getSummaryAction(selectedProcess.process, selectedRows[0].evaluatedCode))
      .then((resp) => {
        if (resp.status === 200) {
          setSelectedEvaluation(selectedRows[0]);
        }
      })
  }

  const handleCloseSummary = () => {
    setSelectedEvaluation(null);
  }

  const handleOpenFeedbackForm = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (selectedRows[0].evaluatedCode === code) {
      dispatch(openSnackbarAction({ msg: 'No puedes enviar feedback de una evaluación en la que eres el evaluado', severity: "warning" }));
      return;
    }

    if (selectedRows[0].noteStatus !== 'Final') {
      dispatch(openSnackbarAction({ msg: 'Para poder dar feedback, el estado de la nota del evaluado debe ser “Nota Final”', severity: "warning" }));
      return;
    }

    if (!['K'].includes(selectedProcess.processStatus)) {
      dispatch(openSnackbarAction({ msg: 'La evaluación debe estar en estado “En Feedback”', severity: "warning" }));
      return;
    }

    if (selectedRows[0].feedback !== 'No') {
      dispatch(openSnackbarAction({ msg: 'La evaluación seleccionada ya tiene asociado un feedback. Si desea modificarlo acceda a Mis Feedbacks desde Mi Escritorio', severity: "warning" }));
      return;
    }

    setFeedbackFormData(selectedRows[0]);
  }

  const handleCloseFeedbackForm = () => {
    setFeedbackFormData(null);
  }

  const handleOpenAcceptReject = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (selectedRows[0].noteStatus !== 'Final') {
      dispatch(openSnackbarAction({ msg: 'Para aceptar u objetar tu evaluación del desempeño, el estado de la nota debe ser final', severity: "warning" }));
      return;
    }

    if (selectedRows[0].evaluatedCode !== code) {
      dispatch(openSnackbarAction({ msg: 'Para aceptar u objetar tu evaluación del desempeño, debes ser el evaluado', severity: "warning" }));
      return;
    }

    setAcceptRejectData(selectedRows[0]);
  }

  const handleCloseAcceptReject = () => {
    setAcceptRejectData(null);
  }

  const handleOpenViewFeedback = () => {
    setViewFeedbackIsOpen(true);
  }

  const handleCloseViewFeedback = () => {
    setViewFeedbackIsOpen(false);
  }

  const handleCreateFeedbackCallback = () => {
    dispatch(getPerformanceSummaryAndResultsAction());
  }

  const handleDownloadSummary = gridRef => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }
    const data = { process: selectedProcess.process, evaluatedCode: selectedRows[0].evaluatedCode };
    dispatch(previewDocumentsAction(data));
  }

  const state = {
    societySelected,
    selectedProcess,
    selectedEvaluation,
    acceptRejectData,
    feedbackFormData,
    viewFeedbackIsOpen,
  }

  const handlers = {
    handleSelectProcess,
    handleUnselectProcess,
    handleOpenSummary,
    handleCloseSummary,
    handleOpenAcceptReject,
    handleCloseAcceptReject,
    handleOpenFeedbackForm,
    handleCloseFeedbackForm,
    handleCreateFeedbackCallback,
    handleOpenViewFeedback,
    handleCloseViewFeedback,
    handleDownloadSummary,
  }

  return {
    name,
    level,
    data,
    summaryData,
    isAcceptRejectEnabled,
    isLoading,
    state,
    handlers,

    //feedback
    typeList,
    modeList,
    people,
    groups,
    tasks,
    goals,
    comments,
    isLoadingFeedback,
  }
}

export default usePerformanceEvaluations;