import React from "react";
import { Typography } from "@material-ui/core";
import { formatNumberExactDecimals } from "@icarius-utils/format";
import { getProgressColor } from "@icarius-utils/colors";

const ProgressBar = ({ value }) => {

    return (
        <div style={{ width: '100%', position: 'relative' }}>
            <div style={{ position: 'absolute', top: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Typography style={{ color: "black", fontSize: 16, fontWeight: 600 }}>
                    {`${formatNumberExactDecimals(value, 2)}%`}
                </Typography>
            </div>
            {
                Number(value) > 0 &&
                <div style={{ height: 22, display: "inline-block", backgroundColor: getProgressColor(value), width: `${value}%` }} />
            }
        </div>
    );
}

export default ProgressBar;
