import { useState } from "react";
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const skillsFields = {
  "evaluateSpecificSkills": "specificSkillsWeight",
  "evaluateTransversalSkills": "transversalSkillsWeight",
};

const objetivesFields = {
  "evaluateOrganizationalTargets": "organizationalTargetsWeight",
  "evaluatePersonalTargets": "personalTargetsWeight",
}

const evaluateFields = {
  ...skillsFields, ...objetivesFields
};

const useHandleForm = (allRows, data, scaleList, transversalSkillsList, createCallback, modifyCallback, invalidDataCallback, handleOpenScaleWarningDialog) => {

  const isCreate = !Boolean(data);
  const dispatch = useDispatch();

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {

      if (fieldName === "group") {
        return isCreate ? "" : data[fieldName];
      }

      if (fieldName === "type") {
        return isCreate ? "DES" : data[fieldName];
      }

      if ([
        'evaluateOrganizationalTargets',
        'evaluatePersonalTargets',
        'evaluatePotential',
        'evaluateSpecificSkills',
        'evaluateTransversalSkills',
        'targetsOnlyVisualization',
        'targetsWithPercentage'
      ].includes(fieldName)
      ) {
        return isCreate ? false : data[fieldName];
      }

      if (
        [
          "skillsWeight",
          "specificSkillsWeight",
          "transversalSkillsWeight",
          "targetsWeight",
          "organizationalTargetsWeight",
          "personalTargetsWeight",
        ].includes(fieldName)) {
        return isCreate ? "0" : data[fieldName];
      }

      if (fieldName === "transversalSkills") {
        let skills = [...transversalSkillsList];
        if (isCreate) {
          // Inicializo en 0
          skills = skills.map(obj => ({ ...obj, weight: 0 }));
          return skills;
        } else {
          skills = skills.map(obj => ({ ...obj, weight: 0 }));
          if (data["transversalSkills"].length !== 0) {
            data["transversalSkills"].forEach(el => {
              // Busco la referencia
              let foundElementIndex = skills.findIndex(skill => skill.key === el.key);
              if (foundElementIndex > -1) {
                skills[foundElementIndex]['weight'] = el.weight;
              }
            });
          }
          return skills;
        }
      }

      if (fieldName === "potentialQuestions") {
        return isCreate ? [] : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      "code",
      "type",
      "group",
      "name",
      "evaluateSkills",
      "evaluateSpecificSkills",
      "evaluateTransversalSkills",
      "evaluateTargets",
      "evaluateOrganizationalTargets",
      "evaluatePersonalTargets",
      "evaluatePotential",
      "skillsWeight",
      "specificSkillsWeight",
      "transversalSkillsWeight",
      "targetsWeight",
      "organizationalTargetsWeight",
      "personalTargetsWeight",
      "skillsScale",
      "targetsScale",
      "potentialScale",
      "transversalSkills",
      'targetsOnlyVisualization',
      'targetsWithPercentage',
      "potentialQuestions",
      "inUse",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const stepOneIsValid = async () => {
    const codeRegex = /^([A-Za-z])([A-Za-z]|[0-9]|_|-){0,19}$/;

    const schema = yup.object().shape({
      'code': yup.string().matches(codeRegex),
      'name': yup.string().required(),
      'type': yup.string().required(),
      'group': yup.string(),
    });

    const isValid = await schema.isValid(formData).then((valid) => valid);
    if (isValid) {
      return true;
    }


    invalidDataCallback();
    return false;
  }

  const stepTwoIsValid = async () => {
    const schema = yup.object().shape({
      "skillsScale": yup.string()
        .when('evaluateSpecificSkills', {
          is: (value) => value === true,
          then: yup.string().required()
        })
        .when('evaluateTransversalSkills', {
          is: (value) => value === true,
          then: yup.string().required()
        }),
      "skillsWeight": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null)
        .when('evaluateSpecificSkills', {
          is: (value) => value === true,
          then: yup.number().transform((_, val) => val === Number(val) ? val : null).required().positive()
        }).when('evaluateTransversalSkills', {
          is: (value) => value === true,
          then: yup.number().transform((_, val) => val === Number(val) ? val : null).required().positive()
        }),
      "specificSkillsWeight": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null)
        .when('evaluateSpecificSkills', {
          is: (value) => value === true,
          then: yup.number().required().transform((_, val) => val === Number(val) ? val : null).positive()
        }),
      "transversalSkillsWeight": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null)
        .when('evaluateTransversalSkills', {
          is: (value) => value === true,
          then: yup.number().required().transform((_, val) => val === Number(val) ? val : null).positive()
        }),
      "targetsWeight": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null)
        .when('evaluateOrganizationalTargets', {
          is: (value) => value === true,
          then: yup.number().required().transform((_, val) => val === Number(val) ? val : null).positive()
        })
        .when('evaluatePersonalTargets', {
          is: (value) => value === true,
          then: yup.number().required().transform((_, val) => val === Number(val) ? val : null).positive()
        }),
      "targetsScale": yup.string()
        .when('evaluateOrganizationalTargets', {
          is: (value) => value === true,
          then: yup.string().required()
        })
        .when('evaluatePersonalTargets', {
          is: (value) => value === true,
          then: yup.string().required()
        }),
      "organizationalTargetsWeight": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null)
        .when('evaluateOrganizationalTargets', {
          is: (value) => value === true,
          then: yup.number().required().transform((_, val) => val === Number(val) ? val : null).positive()
        }),
      "personalTargetsWeight": yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null)
        .when('evaluatePersonalTargets', {
          is: (value) => value === true,
          then: yup.number().required().transform((_, val) => val === Number(val) ? val : null).positive()
        }),
    });

    const isValid = await schema.isValid(formData).then((valid) => valid);

    if (isValid) {
      if (formData.targetsOnlyVisualization && !formData.skillsScale) {
        dispatch(openSnackbarAction({ msg: 'Especifique la escala de competencias', severity: 'info' }));
        return false;
      }

      if (formData.targetsWithPercentage && !formData.targetsScale) {
        dispatch(openSnackbarAction({ msg: 'Especifique la escala de objetivos', severity: 'info' }));
        return false;
      }

      if (formData.skillsScale && !formData.evaluateSpecificSkills && !formData.evaluateTransversalSkills) {
        dispatch(openSnackbarAction({ msg: 'Especifique las competencias a evaluar', severity: 'info' }));
        return false;
      }

      if (formData.targetsScale && !formData.evaluateOrganizationalTargets && !formData.evaluatePersonalTargets) {
        dispatch(openSnackbarAction({ msg: 'Especifique los objetivos a evaluar', severity: 'info' }));
        return false;
      }

      if (formData.evaluateSkills && !formData.evaluateSpecificSkills && !formData.evaluateTransversalSkills) {
        dispatch(openSnackbarAction({ msg: 'Se deben evaluar competencias de al menos un tipo', severity: 'info' }));
        return false;
      }

      if (formData.evaluateTargets && !formData.evaluateOrganizationalTargets && !formData.evaluatePersonalTargets) {
        dispatch(openSnackbarAction({ msg: 'Se deben evaluar objetivos de al menos un tipo', severity: 'info' }));
        return false;
      }

      if (
        (formData.evaluateOrganizationalTargets || formData.evaluatePersonalTargets) &&
        (Number(formData.organizationalTargetsWeight) + Number(formData.personalTargetsWeight) !== 100)
      ) {
        dispatch(openSnackbarAction({ msg: 'La suma de los ponderados debe ser 100', severity: 'info' }));
        return false;
      }

      if (
        (formData.evaluateSpecificSkills || formData.evaluateTransversalSkills) &&
        (Number(formData.specificSkillsWeight) + Number(formData.transversalSkillsWeight) !== 100)
      ) {
        dispatch(openSnackbarAction({ msg: 'La suma de los ponderados debe ser 100', severity: 'info' }));
        return false;
      }

      if (
        (
          (formData.evaluateOrganizationalTargets || formData.evaluatePersonalTargets) ||
          (formData.evaluateSpecificSkills || formData.evaluateTransversalSkills)
        )
        &&
        (
          Number(formData.skillsWeight) + Number(formData.targetsWeight) !== 100)
      ) {
        dispatch(openSnackbarAction({ msg: 'La suma de los ponderados debe ser 100', severity: 'info' }));
        return false;
      }

      const skillsScaleListElement = scaleList.find(el => el.code === formData.skillsScale);
      const targetsScaleListElement = scaleList.find(el => el.code === formData.targetsScale);
      const rowCoincidence = allRows.filter(el => el.internalCode !== data?.internalCode && el.code === formData?.code);
      let foundDifference = false;
      rowCoincidence.forEach(row => {
        const skillsRowScaleListElement = scaleList.find(el => el.code === row.skillsScale);
        const targetsRowScaleListElement = scaleList.find(el => el.code === row.targetsScale);
        if (skillsScaleListElement && !foundDifference &&
          skillsScaleListElement?.details.length > 0 && skillsRowScaleListElement?.details.length > 0 &&
          targetsRowScaleListElement?.details.length > 0 && targetsScaleListElement?.details.length > 0
        ) {
          const maxValueSkillsScale = skillsScaleListElement?.details[skillsScaleListElement?.details.length - 1]["value"];
          const maxValueSkillsRowScale = skillsRowScaleListElement?.details[skillsRowScaleListElement?.details.length - 1]["value"];
          const minValueSkillsScale = skillsScaleListElement?.details[0]["value"];
          const minValueSkillsRowScale = skillsRowScaleListElement?.details[0]["value"];


          const maxValueTargetsScale = targetsScaleListElement?.details[targetsScaleListElement?.details.length - 1]["value"];
          const maxValueTargetsRowScale = targetsRowScaleListElement?.details[targetsRowScaleListElement?.details.length - 1]["value"];
          const minValueTargetsScale = targetsScaleListElement?.details[0]["value"];
          const minValueTargetsRowScale = targetsRowScaleListElement?.details[0]["value"];

          foundDifference =
            maxValueSkillsScale !== maxValueSkillsRowScale ||
            minValueSkillsScale !== minValueSkillsRowScale ||
            minValueTargetsScale !== minValueTargetsRowScale ||
            maxValueTargetsScale !== maxValueTargetsRowScale
        }
      }

      )
      if (foundDifference) {
        handleOpenScaleWarningDialog(formData.code);
        return false;
      }

      const maxValueSkillsScale = skillsScaleListElement?.details[skillsScaleListElement?.details.length - 1]["value"];
      const minValueSkillsScale = skillsScaleListElement?.details[0]["value"];
      const minValueTargetsScale = targetsScaleListElement?.details[0]["value"];
      const maxValueTargetsScale = targetsScaleListElement?.details[targetsScaleListElement?.details.length - 1]["value"];

      if (
        skillsScaleListElement && targetsScaleListElement && (
          maxValueSkillsScale !== maxValueTargetsScale || minValueSkillsScale !== minValueTargetsScale
        )
      ) {
        handleOpenScaleWarningDialog();
        return false;
      }

      return true;
    }
    invalidDataCallback();
    return false;
  }

  const stepThreeIsValid = async () => {
    if (formData.evaluateTransversalSkills === true) {
      const { transversalSkills } = formData;
      if (transversalSkills.some(el => el.weight > 0 && el.indicators.length === 0)) {
        dispatch(openSnackbarAction({ msg: 'Ha seleccionado competencias transversales que no tiene indicadores definidos para evaluar', severity: 'info' }));
        return false;
      } else {
        if (transversalSkills.some(el => el.weight > 0)) {
          const sum = transversalSkills.reduce((a, b) => parseFloat(a) + parseFloat(b.weight || 0), 0);
          if (sum !== 100) {
            dispatch(openSnackbarAction({ msg: 'La suma de los ponderados debe ser 100', severity: 'info' }));
            return false;
          }
          return true;
        } else {
          dispatch(openSnackbarAction({ msg: 'Se debe seleccionar al menos una competencia transversal', severity: 'info' }));
          return false;
        }
      }
    }

    return true;
  }

  const stepFourIsValid = async () => {
    if (
      formData.type === "DES" &&
      formData.evaluatePotential && (formData.potentialScale === "" || formData.potentialQuestions.length === 0)
    ) {
      invalidDataCallback();
      return false;
    }

    return true;
  }

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      "annotationRecordMedalBronze": yup.string().required(),
      "annotationRecordMedalSilver": yup.string().required(),
      "annotationRecordMedalGold": yup.string().required(),
      "annotationRecordShieldBronze": yup.string().required(),
      "annotationRecordShieldSilver": yup.string().required(),
      "annotationRecordShieldGold": yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = () => {
    if (dataIsValid()) {
      isCreate ? createCallback(formData) : modifyCallback({ ...formData, internalCode: data.internalCode });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName, code) => {
    if (fieldName === "targetsOnlyVisualization" && value === true) {
      // Voy a setar tambien en 0 el ponderado
      setFormData({
        ...formData,
        [fieldName]: value,
        "targetsWeight": 0,
        "skillsWeight": 100,

        "organizationalTargetsWeight": 0,
        "personalTargetsWeight": 0,
        "evaluateTargets": false,
        "evaluateOrganizationalTargets": false,
        "evaluatePersonalTargets": false,
        "targetsWithPercentage": false,
        "targetsScale": "",
      })
      return;
    }

    if (fieldName === "targetsWithPercentage" && value === true) {
      // Voy a setar tambien en 0 el ponderado
      setFormData({
        ...formData,
        [fieldName]: value,
        "targetsOnlyVisualization": false,
      })
      return;
    }

    if (fieldName === "evaluateTransversalSkills" && value === false) {
      // Voy a setar tambien en 0 el ponderado
      setFormData({
        ...formData,
        [fieldName]: value,
        [evaluateFields[fieldName]]: 0,
        "transversalSkills": [...transversalSkillsList].map(obj => ({ ...obj, weight: 0 }))
      })
      return;
    }

    if (Object.keys(evaluateFields).includes(fieldName) && value === false) {
      // Si seteo competencias especificas/transversales en NO, entonces tengo que setear el ponderado
      if (
        (fieldName === "evaluateSpecificSkills" && value === false && formData.evaluateTransversalSkills === false) ||
        (fieldName === "evaluateTransversalSkills" && value === false && formData.evaluateSpecificSkills === false)
      ) {
        // Voy a setar tambien en 0 el ponderado
        setFormData({
          ...formData,
          [fieldName]: value,
          [evaluateFields[fieldName]]: 0,
          "skillsWeight": 0,
          "transversalSkills": fieldName === "evaluateTransversalSkills" && value === false ?
            [...transversalSkillsList].map(obj => ({ ...obj, weight: 0 })) : [...formData.transversalSkills]
        })
        return;
      } else if (
        (fieldName === "evaluateOrganizationalTargets" && value === false && formData.evaluatePersonalTargets === false) ||
        (fieldName === "evaluatePersonalTargets" && value === false && formData.evaluateOrganizationalTargets === false)
      ) {
        // Voy a setar tambien en 0 el ponderado
        setFormData({
          ...formData,
          [fieldName]: value,
          [evaluateFields[fieldName]]: 0,
          "targetsWeight": 0,
        })
      }

      // Seteo en 0 el ponderado
      setFormData({
        ...formData,
        [fieldName]: value,
        [evaluateFields[fieldName]]: 0,
      })
      return;
    }

    if (fieldName === "evaluatePotential" && value === false) {
      setFormData({
        ...formData,
        "evaluatePotential": false,
        "potentialQuestions": [],
      })
      return;
    }

    if (fieldName === "type" && value !== "DES") {
      setFormData({
        ...formData,
        "type": value,
        "evaluatePotential": false,
        "potentialQuestions": [],
      })
      return;
    }


    if (fieldName === "transversalSkills") {
      let transversalSkillsClone = [...formData.transversalSkills];
      let index = transversalSkillsClone.findIndex(el => el.key === code);

      if (index > -1) {
        transversalSkillsClone.splice(index, 1, { ...transversalSkillsClone[index], weight: parseInt(value || 0) });
      }
      setFormData({
        ...formData,
        "transversalSkills": transversalSkillsClone,
      })
      return;
    }


    if (fieldName === "skillsWeight") {
      if (value > 0) {
        setFormData({
          ...formData,
          [fieldName]: value,
          "evaluateSkills": true,
        })
      } else {
        setFormData({
          ...formData,
          [fieldName]: value,
          "evaluateSkills": false,
          "skillsScale": "",
        })
      }

      return;
    }


    if (fieldName === "targetsWeight") {
      if (value > 0) {
        setFormData({
          ...formData,
          [fieldName]: value,
          "evaluateTargets": true,
        })
      } else {
        setFormData({
          ...formData,
          [fieldName]: 0,
          "evaluateTargets": false,
          "targetsScale": "",
        })
      }

      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    isCreate,
    formData,
    setFormValue,
    submit,
    stepOneIsValid,
    stepTwoIsValid,
    stepThreeIsValid,
    stepFourIsValid,
  };
}

export default useHandleForm;
