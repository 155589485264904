import React from "react";
import { Grid, Typography } from "@material-ui/core";
import CommonPage from "@icarius-common/commonPage";
import Row from "./row";

const PerformanceEvaluations = ({ level, isLoading, data, handleOpenControl, handleSelectProcess }) => {

    return (
        <CommonPage
            isNotGridPage
            isLoading={isLoading}
            title={"Evaluaciones del desempeño"}
        >
            <div style={{ maxWidth: 1280, margin: '0 auto', padding: 24, paddingTop: 0 }}>
                {
                    !isLoading && !data?.length &&
                    <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
                        <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 32, fontWeight: 700, marginBottom: 16 }}>
                            {'No se encontraron evaluaciones del desempeño activas'}
                        </Typography>
                    </Grid>
                }
                {
                    data?.length > 0 &&
                    <Grid container item xs={12}>
                        {
                            data.map((el, index) => {
                                return (
                                    <Row
                                        key={index}
                                        data={el}
                                        handleClick={handleSelectProcess}
                                        handleOpenControl={handleOpenControl}
                                        level={level}
                                    />
                                )
                            })}
                    </Grid>
                }
            </div>
        </CommonPage>
    );
}

export default PerformanceEvaluations;