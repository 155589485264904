import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getAppColor } from "src/app/selectors";
import { createPerformanceEvaluationsAction } from "../../actions";
import StepFive from "./steps/stepFive/index";
import useHandleForm from "./useHandleForm";

const Form = (props) => {
  
  const {
    isReadOnly,
    selectedProcess,
    data,
    handleClose,
    handleSetCommentFunction,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);

  const create = (dataToSend) => {
    dispatch(createPerformanceEvaluationsAction({
      dataToSend: { ...dataToSend, calibrationCommittee: selectedProcess.commiteeCode },
      process: data.process,
      evaluatedName: data.evaluatedName,
      photo: data.photo,
    }))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit,
    newOptionRef,
  } = useHandleForm(data, create, openValidationError);

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12} style={{ paddingBottom: 20 }}>
          <StepFive
            newOptionRef={newOptionRef}
            isReadOnly={isReadOnly}
            setFormValue={setFormValue}
            handleSetCommentFunction={handleSetCommentFunction}
            data={data}
            formData={formData}
            color={color}
            headerData={{
              commitee: selectedProcess.commitee,
              processName: selectedProcess.value,
              processType: data.typeString,
              evaluatedPhoto: data.photo,
              evaluated: data.evaluatedName,
              evaluator: `Evaluador: ${data.evaluatorName || "Sin evaluador asignado"}`,
            }}
          />
        </Grid>
      </Grid>
      <Grid container item sm={12} justify="center">
        <Grid container item sm={12} md={10} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20, paddingRight: 10 }}>
          {!isReadOnly && <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />}
          {isReadOnly && <ButtonDialogAction isAccept onClick={handleClose} text={"Volver"} />}
        </Grid>
      </Grid>
    </>
  );
}

export default Form;