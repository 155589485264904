import React from "react";
import { Grid, Typography, Card } from "@material-ui/core";
import { borneo } from "@icarius-utils/colors";
import { Alert } from "@material-ui/lab";
import { formatNumberExactDecimals } from "@icarius-utils/format";
import Pie from "./pie";
import Bar from "./bar";
import Table from "./table";

const getAlertText = (isPartialNote, isPendingCalibration) => {
  if (isPartialNote) {
    if (!isPendingCalibration) return "Las calificaciones y notas mostradas no son notas finales y solo consideran las evaluaciones que se han realizado hasta este momento.";
    if (isPendingCalibration) return (
      <>
        Las calificaciones y notas mostradas no son notas finales y solo consideran las evaluaciones que se han realizado hasta este momento.
        <br />Tampoco consideran posibles acciones del comité de calibración.
      </>
    );
  }

  if (isPendingCalibration) return (
    <>
      Las calificaciones y notas mostradas son notas finales porque todas las evaluaciones han sido realizadas.
      <br />Sin embargo el comité de calibración, de requerirlo, puede intervenir y modificarlas.
    </>
  );
  return "";
}

const NoDataContainer = ({ text }) => {
  return (
    <Card style={{ width: '100%', padding: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography className="whiteText" style={{ fontSize: 28 }}>
        {text}
      </Typography>
    </Card>
  )
}

const Graphics = (props) => {

  const {
    isPartialNote,
    isPendingCalibration,
    graphData,
  } = props;

  const {
    skillList,
    skillData,
    objectiveList,
    objectiveData,
  } = graphData;

  const getAverage = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

  const getTableData = (list, data) => {
    const hasSelfEvaluation = data.selfEvaluation.every((item) => item);
    const hasOthers = data.others.every((item) => item);

    return list.map((item, index) => {
      return [
        item,
        formatNumberExactDecimals(data.selfEvaluation[index], 1),
        formatNumberExactDecimals(data.others[index], 1),
        (hasSelfEvaluation && hasOthers) ? formatNumberExactDecimals(data.others[index] - data.selfEvaluation[index], 1) : '--,-',
      ]
    })
  }

  const averageTableData = (() => {
    const auxAverageTableData = [];

    if (skillList.length) {
      auxAverageTableData.push(['Competencias', formatNumberExactDecimals(getAverage(skillData.others), 1)]);
    }

    if (objectiveList.length) {
      auxAverageTableData.push(['Objetivos', formatNumberExactDecimals(getAverage(objectiveData.others), 1)]);
    }

    return auxAverageTableData;
  })();

  return (
    <>
      <Grid container item xs={12} spacing={2} style={{ marginBottom: 20 }}>
        {
          (isPartialNote || isPendingCalibration) &&
          <Grid container item xs={12}>
            <Alert variant="filled" severity="error" style={{ width: '100%' }}>
              {getAlertText(isPartialNote, isPendingCalibration)}
            </Alert>
          </Grid>
        }
        {
          Boolean(skillList.length) &&
          <Grid container item xs={12}>
            <Table
              list={['Competencias', 'Autoevaluación', 'Mis evaluadores', 'Diferencia']}
              data={getTableData(skillList, skillData)}
            />
          </Grid>
        }
        {
          Boolean(objectiveList.length) &&
          <Grid container item xs={12}>
            <Table
              list={['Objetivos', 'Autoevaluación', 'Mis evaluadores', 'Diferencia']}
              data={getTableData(objectiveList, objectiveData)}
            />
          </Grid>
        }
        {
          Boolean(averageTableData.length) &&
          <Grid container item xs={12} md={6}>
            <Table
              list={['Tipo', 'Promedio de mis evaluadores']}
              data={averageTableData}
            />
          </Grid>
        }
      </Grid>
      <Grid container item xs={12} spacing={1} style={{ margin: '20px auto' }}>
        <Grid container item xs={12}>
          <Typography className="whiteText" align="center" style={{ width: '100%', fontSize: 20, fontWeight: 600 }}>
            {'Calificaciones de competencias vs. objetivos'}
          </Typography>
        </Grid>
        {
          Boolean(skillList.length && objectiveList.length) &&
          <Grid container item xs={12}>
            <Pie
              title={'Mis evaluadores'}
              list={['Competencias', 'Objetivos']}
              data={[getAverage(skillData.others), getAverage(objectiveData.others)]}
              colors={borneo.fills}
            />
          </Grid>
        }
        <Grid container item xs={12} md={6}>
          {
            Boolean(skillList.length) ?
              <Pie
                title={'Por competencia'}
                list={skillList}
                data={skillData.others}
                colors={borneo.fills}
              />
              :
              <NoDataContainer text={'Sin competencias'} />
          }
        </Grid>
        <Grid container item xs={12} md={6}>
          {
            Boolean(objectiveList.length) ?
              <Pie
                title={'Por objetivo'}
                list={objectiveList}
                data={objectiveData.others}
                colors={borneo.fills}
              />
              :
              <NoDataContainer text={'Sin objetivos'} />
          }
        </Grid>
      </Grid>
      {
        [
          {
            list: skillList,
            data: skillData,
          },
          {
            list: objectiveList,
            data: objectiveData,
          }
        ].map((item, index) => {
          if (!item.list.length) return null;

          const verticalSkillsData = item.list.map((listItem, index) => {
            return ({
              name: listItem,
              data: [item.data.selfEvaluation[index], item.data.others[index]]
            })
          });

          const verticalDifferencesData = [
            {
              name: 'Autoevaluación',
              data: item.data.selfEvaluation,
            },
            {
              name: 'Mis evaluadores',
              data: item.data.others,
            },
            {
              name: 'Diferencia',
              data: item.list.map((_, index) => item.data.others[index] - item.data.selfEvaluation[index]),
            }
          ];

          const hasSelfEvaluation = item.data.selfEvaluation.every((item) => item);
          const hasOthers = item.data.others.every((item) => item);

          const horizontalDifferenceData = item.list.map((_, index) => item.data.others[index] - item.data.selfEvaluation[index]);

          return (
            <Grid key={index} container item xs={12} spacing={1} style={{ margin: '20px auto' }}>
              <Grid container item xs={12}>
                <Typography className="whiteText" align="center" style={{ width: '100%', fontSize: 20, fontWeight: 600 }}>
                  {index === 0 ? 'Análisis de competencias' : 'Análisis de objetivos'}
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Bar
                  title={index === 0 ? 'Competencias por evaluación' : 'Objetivos por evaluación'}
                  list={['Autoevaluación', 'Mis evaluadores']}
                  data={verticalSkillsData}
                  colors={borneo.fills}
                  minWidth={item.data.selfEvaluation.length * 40}
                />
              </Grid>
              {
                hasSelfEvaluation && hasOthers &&
                <>
                  <Grid container item xs={12}>
                    <Bar
                      title={index === 0 ? 'Evaluaciones por competencia (diferencias)' : 'Evaluaciones por objetivo (diferencias)'}
                      list={item.list}
                      data={verticalDifferencesData}
                      colors={borneo.fills}
                      minWidth={200}
                    />
                  </Grid>
                  <Grid container item xs={12} md={4}>
                    <Bar
                      title={'Autoevaluación'}
                      list={item.list}
                      data={[{ data: item.data.selfEvaluation }]}
                      colors={[borneo.fills[1]]}
                      horizontal
                    />
                  </Grid>
                  <Grid container item xs={12} md={4}>
                    <Bar
                      title={'Mis evaluadores'}
                      list={item.list}
                      data={[{ data: item.data.others }]}
                      colors={[borneo.fills[3]]}
                      horizontal
                    />
                  </Grid>
                  <Grid container item xs={12} md={4}>
                    <Bar
                      title={'Diferencia'}
                      list={item.list}
                      data={[{ data: horizontalDifferenceData }]}
                      colors={horizontalDifferenceData.map((item) => item < 0 ? borneo.fills[0] : borneo.fills[2])}
                      horizontal
                    />
                  </Grid>
                </>
              }
            </Grid>
          )
        })
      }
    </>
  )
}

export default Graphics;