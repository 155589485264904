import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import Form from "./form";

const CalibrationForm = (props) => {

  const {
    selectedProcess,
    isReadOnly,
    isLoading,
    handleSetCommentFunction,
    data,
    handleClose,
  } = props;

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleClose}
      type={"goBack"}
    />

  return (
    <CommonPage
      title={`Calibración de evaluación de desempeño`}
      menuItems={[goBackItem]}
      isLoading={isLoading}
      isNotGridPage
    >
      <Form
        selectedProcess={selectedProcess}
        isReadOnly={isReadOnly}
        data={data}
        handleSetCommentFunction={handleSetCommentFunction}
        handleClose={handleClose}
      />
    </CommonPage>
  )
}
export default CalibrationForm;
