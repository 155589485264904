import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const GET_SUMMARY = NAME + "/GET_SUMMARY";
export const GET_SUMMARY_REJECTED = NAME + "/GET_SUMMARY_REJECTED";
export const GET_SUMMARY_FULFILLED = NAME + "/GET_SUMMARY_FULFILLED";

export const ACCEPT_REJECT = NAME + "/ACCEPT_REJECT";
export const ACCEPT_REJECT_REJECTED = NAME + "/ACCEPT_REJECT_REJECTED";
export const ACCEPT_REJECT_FULFILLED = NAME + "/ACCEPT_REJECT_FULFILLED";

export const PREVIEW_SUMMARY = NAME + "/PREVIEW_SUMMARY";
export const PREVIEW_SUMMARY_REJECTED = NAME + "/PREVIEW_SUMMARY_REJECTED";
export const PREVIEW_SUMMARY_FULFILLED = NAME + "/PREVIEW_SUMMARY_FULFILLED";