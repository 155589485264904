import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPerformanceEvaluationsResumeAction,
  getPerformanceEvaluationsInitialAction,
  getPerformanceEvaluationsAction,
  sendMailAction,
} from "../actions";
import {
  getLoading,
  getData,
  getInitialData,
  getResumeData,
} from "../selectors";
import { getSocietySelected } from "@icarius-pages/login/selectors";
import { getUserData } from "src/app/selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getAnnotationsAction } from "@icarius-pages/annotationsPage/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";

const usePerformanceEvaluations = () => {
  const userData = useSelector(getUserData);

  const [progressControlIsOpen, setProgressControlIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [commentFunctionDialogIsOpen, setCommentFunctionDialogIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null); //seleccionado para editar y detalle
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [processName, setProcessName] = useState("");
  const [periodName, setPeriodName] = useState("");
  const [commentFunctionArgs, setCommentFunctionArgs] = useState(null);
  const [annotationsData, setAnnotationsData] = useState(null);
  const [commentFunction, setCommentFunction] = useState(null);
  const [commentData, setCommentData] = useState(null);
  const [commentFunctionTitle, setCommentFunctionTitle] = useState("");

  const dispatch = useDispatch();
  const resumeData = useSelector(getResumeData);
  const initialData = useSelector(getInitialData);
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const societySelected = useSelector(getSocietySelected);

  const { name, level } = userData;

  const getResumeDataFromBackend = useCallback(() => {
    dispatch(getPerformanceEvaluationsResumeAction());
  }, [dispatch]);

  useEffect(() => {
    getResumeDataFromBackend();
  }, [dispatch, getResumeDataFromBackend])

  useEffect(() => {
    if (selectedProcess) {
      dispatch(getPerformanceEvaluationsInitialAction(selectedProcess, progressControlIsOpen));
    }
  }, [dispatch, selectedProcess, progressControlIsOpen])

  useEffect(() => {
    if (selectedData) {
      dispatch(getPerformanceEvaluationsAction({
        type: selectedData.type,
        group: selectedData.group,
        evaluatedCode: selectedData.evaluatedCode,
        evaluatedName: selectedData.evaluatedName,
        photo: selectedData.photo,
        code: selectedData.internalCode,
        process: selectedData.process,
      })).then(resp => resp.status === 200 && setCreateDialogIsOpen(true));
    }
  }, [dispatch, selectedData])

  const handleOpenCreateDialog = (row) => {
    if (row.data?.canEvaluate) {
      if (row.data?.isCalibrated) {
        dispatch(openDialogAction({
          title: "Atención",
          msg: "No podrá modificar la evaluación porque el evaluado ya ha sido calibrado, solo accederá en modo de visualización",
          onConfirm: () => setSelectedData(row.data)
        }));
      } else {
        setSelectedData(row.data);
      }
    } else {
      dispatch(openSnackbarAction(
        {
          msg: `Los ponderados de los objetivos del colaborador para el período "${periodName}" no totalizan 100%, no es posible evaluarlo`,
          duration: null,
          severity: "error"
        }
      ));
    }
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleSendMail = (gridRef, origin) => {
    let rows = gridRef.api.getSelectedRows();

    if (rows.length < 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    };

    if (origin === 'performanceEvaluations' && rows.some((el) => el.status !== "Pendiente")) {
      dispatch(openSnackbarAction({
        msg: 'Solo se puede solicitar a los evaluadores que realicen las calificaciones del desempeño cuando el estado de la misma sea Pendiente',
        duration: 10000,
        severity: "warning",
      }));
      return;
    }

    let selectedCodes = rows.map((item) => item?.evaluatorCode).filter((item) => item);
    selectedCodes = [...new Set(selectedCodes)];

    if (selectedCodes) {
      dispatch(openDialogAction({
        title: "Enviar mensaje",
        msg: origin === 'performanceEvaluations'
          ? "¿Desea enviar a los evaluadores seleccionados un mensaje solicitando la pronta atención de las calificaciones del desempeño?"
          : "¿Desea enviar a los evaluadores seleccionados un mensaje indicando que se da inicio al proceso de evaluación del desempeño y que ya pueden comenzar a realizar sus calificaciones?",
        onConfirm: () => dispatch(sendMailAction({
          users: selectedCodes,
          origin,
        })),
      }));
    }
  };

  const handleOpenAnnotations = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (selectedRows[0].type !== "DES" && level === "M") {
      dispatch(openSnackbarAction({ msg: 'La visualización de anotaciones solo se permiten en evaluaciones descendentes', severity: "warning", duration: 10000 }));
      return;
    }

    dispatch(getAnnotationsAction(selectedRows[0].evaluatedCode))
      .then(() => setAnnotationsData({
        employee: {
          "CODIGO DE EMPLEADO": selectedRows[0].evaluatedCode,
          "APELLIDO Y NOMBRE": selectedRows[0].evaluatedName,
          "MANAGER": selectedRows[0].manager,
        },
        userDates: selectedRows[0].userDates,
      }));
  }

  const handleCloseAnnotations = () => {
    setAnnotationsData(null);
  }

  const handleSetCommentFunction = (index, commentF, args) => {
    if (index === 0) {
      setCommentFunctionTitle("Ha seleccionado la menor calificación, justifique con sus comentarios por favor");
    } else {
      setCommentFunctionTitle("Ha seleccionado la mayor calificación, justifique con sus comentarios por favor");
    }
    setCommentFunctionArgs({ function: commentF, args: args.slice(0, 4) });
    setCommentData({ comment: args[4] || "" });
    setCommentFunctionDialogIsOpen(true);
  }

  const handleCloseCommentFunctionDialog = () => {
    setCommentFunctionDialogIsOpen(false);
    setCommentFunction(null);
    setCommentFunctionArgs(null);
    setCommentFunctionTitle("");
  }

  const handleUnselectProcess = () => {
    setSelectedProcess(null);
    setProgressControlIsOpen(false);
    getResumeDataFromBackend();
  }

  const handleOpenDetailTable = () => {
    setProgressControlIsOpen(true);
  }

  const handleCloseDetailTable = () => {
    setProgressControlIsOpen(false);
  }

  const state = {
    progressControlIsOpen,
    commentData,
    annotationsData,
    createDialogIsOpen,
    selectedData,
    societySelected,
    selectedProcess,
    processName,
    periodName,
    commentFunctionDialogIsOpen,
    commentFunction,
    commentFunctionTitle,
    commentFunctionArgs,
  }

  const handlers = {
    handleOpenCreateDialog,
    handleCloseCreateEditDialog,
    setSelectedProcess,
    handleUnselectProcess,
    setProcessName,
    setPeriodName,
    handleCloseAnnotations,
    handleOpenAnnotations,
    handleSetCommentFunction,
    handleCloseCommentFunctionDialog,
    handleOpenDetailTable,
    handleCloseDetailTable,
    handleSendMail,
  }

  return {
    name,
    level,
    initialData,
    resumeData,
    data,
    isLoading,
    state,
    handlers,
  }
}

export default usePerformanceEvaluations;