import React, { useMemo } from 'react';
import { Tooltip, TableCell } from "@material-ui/core";

const Score = ({ data, targetsOnlyVisualization, targetsWithPercentage }) => {

    const {
        content,
        isPending,
        qualificationString,
    } = useMemo(() => {
        let content = "(No aplica)";
        let isPending = false;
        let qualificationString = "";

        if (!targetsOnlyVisualization) {
            if (data) {
                const { type, qualification, qualificationIndex, qualificationString } = data;
                isPending = data.isPending;

                if (!isPending) {
                    switch (type) {
                        case "E":
                            content = targetsWithPercentage ? `${qualification}%` : Array(parseInt(qualificationIndex) + 1).join("⭐")
                            break;
                        case "N":
                        case "C":
                            content = targetsWithPercentage ? `${qualification}%` : qualificationString;
                            break;
                        case "V":
                            content = qualification;
                            break;
                        default:
                            content = null;
                            break;
                    }
                } else {
                    content = "Pendiente";
                }
            }
        }

        return {
            content,
            isPending,
            qualificationString,
        }
    }, [data, targetsWithPercentage, targetsOnlyVisualization]);

    return (
        <Tooltip arrow title={qualificationString !== content ? (qualificationString || "") : ""}>
            <TableCell align="center" style={isPending ? { color: "red" } : {}}>
                {content}
            </TableCell>
        </Tooltip>
    )
}

export default Score;