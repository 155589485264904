import React, { useRef } from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import ProgressRenderer from "@icarius-table/renderersAndFilters/progressRenderer";
import ProgressFilter from "@icarius-table/renderersAndFilters/progressFilter";
import ProgressByRankingRendererDynamicColor from "@icarius-table/renderersAndFilters/progressByRankingRendererDynamicColor";
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import FeedbackFormDialog from "@icarius-pages/feed/components/form/formDialog";
import { createFeedbackPublicationAction } from "@icarius-pages/feedback/actions";
import usePerformanceEvaluations from "./usePerformanceEvaluations";
import InitialScreen from "./initialScreen";
import SummaryScreen from "./summaryScreen";
import AcceptRejectDialog from "./acceptRejectDialog";
import FeedbackPublication from "./feedbackPublication";

const PerformanceEvaluations = () => {

  const {
    level,
    data,
    summaryData,
    isAcceptRejectEnabled,
    isLoading,
    state,
    handlers,

    //feedback
    typeList,
    modeList,
    people,
    groups,
    tasks,
    goals,
    comments,
    isLoadingFeedback,
  } = usePerformanceEvaluations();

  const ownGridRef = useRef(); // tengo que usar una ref propia para el aggrid porque tengo 3 commonPage y al salir y volver se pierden las ref internas

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handlers.handleUnselectProcess}
      type={"goBack"}
    />
  )

  const acceptRejectButton = () => isAcceptRejectEnabled && level === 'C' && ['K'].includes(state.selectedProcess?.processStatus) && (
    <CustomIconButton
      title={'Aceptar u objetar evaluación'}
      onClick={() => handlers.handleOpenAcceptReject(ownGridRef.current)}
    >
      <ThumbsUpDownIcon />
    </CustomIconButton>
  )

  const viewSummaryButton = () => (
    <CustomIconButton
      title={"Resumen final"}
      onClick={() => handlers.handleOpenSummary(ownGridRef.current)}
      type={"assessment"}
    />
  )

  const downloadItem = () => (
    <CustomIconButton
      title={'Descargar resumen final de la evaluación'}
      onClick={() => handlers.handleDownloadSummary(ownGridRef.current)}
      type={"download"}
    />
  )

  const feedbackButton = () => level !== 'C' && (
    <CustomIconButton
      title={"Dar feedback"}
      onClick={() => handlers.handleOpenFeedbackForm(ownGridRef.current)}
      type={"feedback"}
    />
  )

  const isLoadingEverything = isLoading || isLoadingFeedback.gridData || isLoadingFeedback.privacyData || isLoadingFeedback.feed;

  return (
    <>
      {
        !state.selectedProcess &&
        <InitialScreen
          isLoading={isLoadingEverything}
          data={data}
          handleSelectProcess={handlers.handleSelectProcess}
        />
      }
      {
        state.selectedEvaluation &&
        <SummaryScreen
          isLoading={isLoadingEverything}
          level={level}
          data={state.selectedEvaluation}
          summaryData={summaryData}
          process={state.selectedProcess}
          evaluatedName={state.selectedEvaluation?.evaluatedName}
          handleOpenViewFeedback={handlers.handleOpenViewFeedback}
          handleClose={handlers.handleCloseSummary}
        />
      }
      {
        state.selectedProcess && !state.selectedEvaluation &&
        <CommonPage
          isLoading={isLoadingEverything}
          title={`Resumen y resultados: ${state.selectedProcess.processName} - Fecha desde ${state.selectedProcess.periodFromDate} - Fecha hasta ${state.selectedProcess.periodEndDate}`}
          gridTitle={`Resumen y resultados - ${state.selectedProcess.processName}`}
          columnDefPage={paths.performanceSummaryAndResults}
          rowData={state.selectedProcess?.data}
          menuItems={[goBackButton, viewSummaryButton, acceptRejectButton, feedbackButton, downloadItem]}
          ownGridRef={ownGridRef}
          hasExpand
          hasHelp
          frameworkComponents={{ ProgressByRankingRendererDynamicColor, ProgressRenderer, ProgressFilter }}
        >
        </CommonPage>
      }
      {
        state.acceptRejectData &&
        <AcceptRejectDialog
          open={Boolean(state.acceptRejectData)}
          data={state.acceptRejectData}
          processData={state.selectedProcess}
          handleClose={handlers.handleCloseAcceptReject}
        />
      }
      {
        state.feedbackFormData &&
        <FeedbackFormDialog
          open={Boolean(state.feedbackFormData)}
          preselectedData={{
            userCodes: [state.feedbackFormData?.evaluatedCode],
            type: 'ENT_DES',
            mode: 'P',
            reference: state.selectedProcess.processName,
            evaluationProcess: state.selectedProcess.process,
          }}
          data={null}
          goals={goals}
          modeList={modeList}
          typeList={typeList}
          groups={groups}
          tasks={tasks}
          people={people}
          statusToCreate={'E'}
          createPublicationAction={(dataToSend) => createFeedbackPublicationAction(dataToSend, true)}
          isLoading={isLoadingEverything}
          handleClose={handlers.handleCloseFeedbackForm}
          handleAfterCreateCallback={handlers.handleCreateFeedbackCallback}
          isFeedback
          hideGoals
        />
      }
      {
        state.viewFeedbackIsOpen &&
        <FeedbackPublication
          isLoading={isLoadingFeedback}
          data={summaryData.feedback}
          people={people}
          groups={groups}
          tasks={tasks}
          comments={comments}
          handleClose={handlers.handleCloseViewFeedback}
        />
      }
    </>
  )
}

export default PerformanceEvaluations;