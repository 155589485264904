import * as actionTypes from "./actionTypes";

const initialState = {
  data: [],
  summaryData: {},
  isLoading: false,
  gettingPreview: false,
  isAcceptRejectEnabled: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        isAcceptRejectEnabled: action.payload.isAcceptRejectEnabled,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_SUMMARY:
      return { ...state, isLoading: true };
    case actionTypes.GET_SUMMARY_FULFILLED:
      return {
        ...state,
        isLoading: false,
        summaryData: action.payload.data,
      };
    case actionTypes.GET_SUMMARY_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.ACCEPT_REJECT:
      return { ...state, isLoading: true };
    case actionTypes.ACCEPT_REJECT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.ACCEPT_REJECT_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.PREVIEW_SUMMARY:
      return { ...state, gettingPreview: true };
    case actionTypes.PREVIEW_SUMMARY_FULFILLED:
      return {
        ...state,
        gettingPreview: false,
      };
    case actionTypes.PREVIEW_SUMMARY_REJECTED:
      return { ...state, gettingPreview: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
