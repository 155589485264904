import React from "react";
import { TextField, Grid, } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import useHandleForm from "./useHandleForm";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { withStyles } from "@material-ui/core/styles";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});
const Form = (props) => {
  const { data, handleClose, rowsToUse, classes, items, setParentFormValue, disabled } = props;
  const dispatch = useDispatch();


  const create = (dataToSend) => {
    // Inserto en el array
    const newItems = [...items, { ...dataToSend, number: items.length }];
    setParentFormValue(newItems.map((item, index) => ({ ...item, number: index + 1 })), "potentialQuestions");
    handleClose();
  }

  const modify = (dataToSend) => {
    // Busco segun el número
    let itemsClone = [...items];
    let elementToChange = itemsClone.findIndex(el => el.number === dataToSend.number);
    if (elementToChange > -1) {
      itemsClone[elementToChange] = dataToSend;
      setParentFormValue(itemsClone.map((item, index) => ({ ...item, number: index + 1 })), "potentialQuestions");
    }
    handleClose();
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, create, modify, openValidationError, rowsToUse);

  const handleSubmit = () => {
    submit();
  }

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* Pregunta */}
            <TextField
              disabled={disabled}
              required
              fullWidth
              margin={"none"}
              label={"Pregunta"}
              value={formData.question}
              onChange={(e) => setFormValue(e.target.value, "question")}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, marginBottom: 70 }}> {/* Descripción */}
            <TextField
              disable={disabled}
              required
              fullWidth
              label={'Descripción del comportamiento'}
              variant="outlined"
              multiline
              rows={5}
              margin={"none"}
              value={formData.description}
              onChange={(e) => setFormValue(e.target.value, "description")}
              inputProps={{ spellCheck: 'false' }}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                }
              }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={6} style={gridStyle}> {/* Sección */}
            <TextField
              fullWidth
              margin={"none"}
              label={"Sección"}
              value={formData.section}
              onChange={(e) => setFormValue(e.target.value, "section")}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          {
            !disabled &&
            <Grid container item xs={12} justify={"flex-end"} alignItems="center" >
              <ButtonDialogAction isAccept onClick={handleSubmit} text={getLocalizedString("save")} />
            </Grid>
          }
        </Grid>
      </Grid>
    </>
  );
}

export default withStyles(styles)(Form);
