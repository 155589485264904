import * as actionTypes from "./actionTypes";
import {
  getInitialPerformanceEvaluationsCalibrationAPI,
  getListPerformanceEvaluationsCalibrationAPI,
  getFormPerformanceEvaluationsCalibrationAPI,
  updateFormPerformanceEvaluationsCalibrationAPI,
  sendMailCalibrationAPI,
  getGraphicsPerformanceEvaluationsCalibrationAPI,
  updateSkillsPerformanceEvaluationsCalibrationAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'Ya existe un formulario de desempeño con el mismo código, tipo y grupo de cargos a evaluar';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}


export const getInitialPerformanceEvaluationsCalibrationAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_INITIAL });
  try {
    let response = await getInitialPerformanceEvaluationsCalibrationAPI();
    let status = response.data && response.data.status;

    let yearList = response.data && response.data.yearList;
    let performanceProcessList = response.data && response.data.performanceProcessList;
    let calibrationCommitteeList = response.data && response.data.calibrationCommitteeList;


    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_INITIAL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_INITIAL_FULFILLED,
      payload: {
        yearList,
        performanceProcessList,
        calibrationCommitteeList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_INITIAL_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getListPerformanceEvaluationsCalibrationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getListPerformanceEvaluationsCalibrationAPI(dataToSend);

    let data = response.data && response.data.data;
    let filterOptions = response.data && response.data.filterOptions;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        filterOptions,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getGraphicsPerformanceEvaluationsCalibrationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_GRAPHICS });
  try {
    let response = await getGraphicsPerformanceEvaluationsCalibrationAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_GRAPHICS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_GRAPHICS_FULFILLED,
      payload: {
        data,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_GRAPHICS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateSkillsPerformanceEvaluationsCalibrationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_SKILLS });
  try {
    let response = await updateSkillsPerformanceEvaluationsCalibrationAPI(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_SKILLS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_SKILLS_FULFILLED,
    });
    dispatch(openSnackbarAction({ msg: 'Competencias calibradas con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_SKILLS_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const getFormPerformanceEvaluationsCalibrationAction = ({ evaluatedCode, process, evaluatedName, photo }) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });

  try {
    let params = new URLSearchParams();
    params.set("evaluatedCode", evaluatedCode);
    params.set("process", process);

    let response = await getFormPerformanceEvaluationsCalibrationAPI(`?${params.toString()}`);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_FORM_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FORM_FULFILLED,
      payload: {
        data: {
          ...data, process, evaluatedName, photo
        },
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_FORM_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createPerformanceEvaluationsAction = ({ dataToSend }) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await updateFormPerformanceEvaluationsCalibrationAPI(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: {
        data
      },
    });

    dispatch(openSnackbarAction({ msg: 'Evaluación de desempeño calibrada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const sendMail = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.SEND_MAIL });
  try {
    let response = await sendMailCalibrationAPI(dataToSend);

    dispatch({
      type: actionTypes.SEND_MAIL_FULFILLED,
    });
    dispatch(openSnackbarAction({ msg: 'Mensaje enviado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.SEND_MAIL_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};