import React, { useState, useRef, useEffect } from "react";
import { Chart } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import Container from "./container";
import { getAppColor } from "src/app/selectors";
import { formatNumberExactDecimals } from "@icarius-utils/format";

const Line = ({ list, data, title }) => {

  const canvasRef = useRef();
  const [myChart, setMyChart] = useState();

  const color = useSelector(getAppColor);
  const theme = useSelector(getTheme);

  useEffect(() => {
    if (myChart) {
      myChart.update();
    }
  })

  useEffect(() => {
    let info = {
      labels: list,
      datasets: [
        {
          fill: false,
          pointHoverRadius: 6,
          pointRadius: 5,
          pointHitRadius: 6,
          borderColor: color,
          backgroundColor: color,
          borderWidth: 3,
          data: data,
        },
      ],
    };

    const options = {
      animation: {
        duration: 0
      },
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            const value = data.datasets[0].data[tooltipItem.index] || 0;
            return formatNumberExactDecimals(value, 1);
          }
        },
        titleFontSize: 14,
        bodyFontSize: 14,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: theme.theme === "dark" ? "white" : "black",
              beginAtZero: true,
              min: 0
            },
            gridLines: {
              color: theme.theme === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(1, 1, 1, 0.2)",
              zeroLineColor: "transparent",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              fontColor: theme.theme === "dark" ? "white" : "black",
              beginAtZero: true,
              min: 0
            },
            gridLines: {
              color: theme.theme === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(1, 1, 1, 0.2)",
              zeroLineColor: "transparent",
            },
          },
        ],
      }
    };

    const chartData = {
      type: "line",
      options: options,
      data: info,
    };

    setMyChart(new Chart(canvasRef.current, chartData));
  }, [data, list, color, theme])

  return (
    <Container title={title}>
      <div style={{ height: 350, marginTop: 30 }}>
        <canvas ref={canvasRef} />
      </div>
    </Container>
  );
}

export default Line;