import React from "react";
import { Grid, Divider, Chip } from "@material-ui/core";
import SkillsTable from "./tables/skillsTable";
import TargetsTable from "./tables/targetsTable";
import PotentialTable from "./tables/potentialTable";
import Panel from "./panel";
import Graphics from "../graphics";

export const getStatusResultBackgroundColor = (name) => {
    switch (name) {
        case "Fortaleza":
            return { backgroundColor: "green", color: "white" };
        case "Debilidad":
            return { backgroundColor: "red", color: "black" };
        case "Discrepancia":
            return { backgroundColor: "yellow", color: "black" };
        default:
            return {};
    }
}

const Body = ({ color, summaryData, process, handleOpenViewFeedback }) => {

    const generalNote = {
        note: summaryData.note,
        noteString: summaryData.noteString,
        noteColor: summaryData.noteColor,
        isPartialNote: summaryData.isPartialNote,
    }
    return (
        <Grid container alignItems="center" style={{ padding: '0px 50px 0px 0px', marginTop: 15, marginBottom: 10 }}>
            {
                summaryData.hasOwnProperty("skills") &&
                (summaryData.skills.transversalSkills.length > 0 || summaryData.skills.specificSkills.length > 0) &&
                <>
                    <Panel title="Competencias">
                        <SkillsTable summaryData={summaryData.skills} />
                    </Panel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: color, height: 2 }} />
                    </div>
                </>
            }
            {
                summaryData.hasOwnProperty("targets") &&
                (summaryData.targets.organizationalTargets.length > 0 || summaryData.targets.personalTargets.length > 0) &&
                <>
                    <Panel title="Objetivos">
                        <TargetsTable evaluations={summaryData.evaluations} summaryData={summaryData.targets} />
                    </Panel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: color, height: 2 }} />
                    </div>
                </>
            }
            {
                summaryData.hasOwnProperty("potential") && summaryData.potential?.potentialQuestions?.length > 0 &&
                <>
                    <Panel title="Potencial">
                        <PotentialTable generalNote={generalNote} summaryData={summaryData.potential} />
                    </Panel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: color, height: 2 }} />
                    </div>
                </>
            }
            {
                Boolean(summaryData.graphData && (summaryData.graphData.objectiveList.length || summaryData.graphData.skillList.length)) &&
                <>
                    <Panel title="Resumen y gráficos">
                        <Graphics
                            isPartialNote={summaryData.isPartialNote}
                            isPendingCalibration={summaryData.isPendingCalibration}
                            graphData={summaryData.graphData}
                        />
                    </Panel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: color, height: 2 }} />
                    </div>
                </>
            }
            {
                Boolean(summaryData.feedback) &&
                <>
                    <Panel title="Feedback">
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, paddingBottom: 20 }}>
                            <Chip
                                label={`${process.processName} - Fecha de la entrevista: ${summaryData.feedback.modifiedDate} - Entregado por: ${summaryData.feedback.owner.name}`}
                                onClick={handleOpenViewFeedback}
                            />
                        </div>
                    </Panel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: color, height: 2 }} />
                    </div>
                </>
            }
        </Grid>
    )
}

export default Body;