import React from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import useHandleFormDuplicate from "./useHandleFormDuplicate";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { duplicatePerformanceFormsAction } from "@icarius-pages/performanceForms/actions";
import Loader from "@icarius-common/loader";
import { PerformanceFormUpwardIcon, PerformanceFormDownwardIcon, PerformanceFormSelfEvaluationIcon, PerformanceFormPeerEvaluationIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";

const gridStyle = { height: 75, padding: "10px" };

const formTypeList = [
  { key: "ASC", value: "Ascendente", icon: <PerformanceFormUpwardIcon /> },
  { key: "DES", value: "Descendente", icon: <PerformanceFormDownwardIcon /> },
  { key: "AUT", value: "Autoevaluación", icon: <PerformanceFormSelfEvaluationIcon /> },
  { key: "PAR", value: "De pares", icon: <PerformanceFormPeerEvaluationIcon /> },
];

const CustomSelect = withStyles({
  select: {
    display: "flex",
    alignItems: "center",
  },
})(Select);

const DuplicateDialog = (props) => {

  const {
    isLoading,
    open,
    data,
    handleClose,
    evaluationGroupsList,
  } = props;

  const dispatch = useDispatch();

  const handleDuplicate = (dataToSend) => {
    dispatch(duplicatePerformanceFormsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit
  } = useHandleFormDuplicate(data, handleDuplicate, openValidationError);

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move', paddingRight: 20, width: "95%" }} id="draggable-dialog-title">
        {`Duplicar formulario de desempeño ${data.name}`}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Loader open={isLoading} />
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <TextField
              required
              fullWidth
              margin={"none"}
              label={'Código'}
              value={formData.code}
              inputProps={{ maxLength: 20 }}
              onChange={(e) => setFormValue(e.target.value, "code")}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={'label-type'}>{'Tipo de formulario'}</InputLabel>
              <CustomSelect
                value={formData.type}
                labelId={'label-type'}
                id={'select-type'}
                onChange={(e) => setFormValue(e.target.value, "type")}
                margin={"none"}
                style={{ display: "flex", alignItems: "center" }}
              >
                {
                  formTypeList.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      <div style={{ paddingTop: 2, marginRight: 5 }}>{item.icon}</div>{item.value}
                    </MenuItem>
                  ))
                }
              </CustomSelect>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink id={'label-group'}>{'Grupo de cargos a evaluar'}</InputLabel>
              <Select
                displayEmpty
                value={formData.group}
                labelId={'label-group'}
                id={'select-group'}
                onChange={(e) => setFormValue(e.target.value, "group")}
                margin={"none"}
              >
                {
                  evaluationGroupsList.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
        <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateDialog;
