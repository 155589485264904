import React from "react";
import { useDispatch } from "react-redux";
import {
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

    const {
        isLoading,
        yearList,
        performanceProcessList,
        calibrationCommitteeList,
        handleSubmit,
    } = props;

    const dispatch = useDispatch();

    const openValidationError = () => {
        dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
    }

    const {
        formData,
        setFormValue,
        submit,
    } = useHandleForm(handleSubmit, calibrationCommitteeList, openValidationError);

    const performanceProcessObject = performanceProcessList?.[formData.year]?.find((item) => item.key === formData.performanceProcess);

    return (
        <CommonPage
            title={'Calibración de la evaluación del desempeño'}
            isLoading={isLoading}
            isNotGridPage
        >
            <Grid container item xs={12} direction="row" justify="center" style={{ paddingTop: 10 }}>
                <Grid container item xs={12} justify="center" alignItems="center" style={gridStyle}>
                    <FormControl style={{ width: 350 }}>
                        <InputLabel required id={`label-required`}>{"Año de evaluaciones"}</InputLabel>
                        <Select
                            value={formData.year}
                            labelId={`label-year`}
                            id={`select-year`}
                            onChange={(e) => setFormValue(e.target.value, "year")}
                            margin={"none"}
                        >
                            {
                                yearList?.map((item, index) => (
                                    <MenuItem
                                        className={"whiteText"}
                                        key={`${index}-${item.key}`}
                                        value={item}>
                                        {item}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container item xs={12} justify="center" alignItems="center" style={gridStyle}>
                    <FormControl style={{ width: 350 }}>
                        <InputLabel required id={`label-required`}>{"Proceso de evaluación de desempeño"}</InputLabel>
                        <Select
                            value={formData.performanceProcess}
                            labelId={`label-performanceProcess`}
                            id={`select-performanceProcess`}
                            onChange={(e) => setFormValue(e.target.value, "performanceProcess")}
                            margin={"none"}
                        >
                            {
                                performanceProcessList?.[formData.year]?.map((item, index) => (
                                    <MenuItem
                                        className={"whiteText"}
                                        key={`${index}-${item.key}`}
                                        value={item.key}>
                                        {item.value}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container item xs={12} justify="center" alignItems="center" style={gridStyle}>
                    <TextField
                        disabled
                        style={{ width: 350 }}
                        fullWidth
                        label="Tipo de proceso"
                        value={performanceProcessObject?.typeString || ''}
                    />
                </Grid>
                <Grid container item xs={12} justify="center" alignItems="center" style={gridStyle}>
                    <FormControl style={{ width: 350 }}>
                        <InputLabel required id={`label-required`}>{"Comité de calibración"}</InputLabel>
                        <Select
                            value={formData.calibrationCommittee}
                            labelId={`label-calibrationCommittee`}
                            id={`select-calibrationCommittee`}
                            onChange={(e) => setFormValue(e.target.value, "calibrationCommittee")}
                            margin={"none"}
                        >
                            {
                                calibrationCommitteeList
                                    .filter((item) => item.key)
                                    .map((item, index) => (
                                        <MenuItem
                                            className={"whiteText"}
                                            key={`${index}-${item.key}`}
                                            value={item.key}>
                                            {item.value}
                                        </MenuItem>
                                    ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container item xs={12} justify={"center"} alignItems="center" style={{ paddingBottom: 20, marginTop: 20 }}>
                    <ButtonDialogAction isAccept onClick={submit} text={"Continuar"} />
                </Grid>
            </Grid>
        </CommonPage>
    );
}

export default Form;