import React from "react";
import { Card, Typography } from "@material-ui/core";

const Container = ({ title, children }) => {

  return (
    <Card style={{ width: '100%', padding: 20 }}>
      <Typography className="whiteText" align="center" style={{ fontSize: 18, fontWeight: 600 }}>
        {title}
      </Typography>
      {children}
    </Card>
  )
}

export default Container;