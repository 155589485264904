import React from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import ProgressBar from "@icarius-pages/performanceEvaluationsCalibration/components/calibrationForm/steps/stepFive/progressBar";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";

const types = {
    DES: "Evaluación descendente",
    ASC: "Evaluación ascendente",
    AUT: "Autoevaluación",
    PAR: "Evaluación De pares",
}

const Header = (props) => {

    const {
        evaluatedName,
        color,
        summaryData,
        process,
    } = props;

    return (
        <Grid container item xs={12}>
            <Card style={{ padding: 20, marginTop: 20, width: '95%' }}>
                <Grid container item xs={12} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h4" className="whiteText" style={{ fontWeight: 700, width: "100%" }}>
                            {process.processName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ fontWeight: 700, width: "100%", color: color }}>
                            {evaluatedName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12} md={6} xl={8}>
                        <Typography className="whiteText" style={{ width: "100%" }}>
                            {`Fecha de evaluación desde: ${process.periodFromDate}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <Typography className="whiteText" style={{ fontWeight: 700, width: "100%" }}>
                            {summaryData.status}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12} md={6} xl={8}>
                        <Typography className="whiteText" style={{ width: "100%" }}>
                            {`Fecha de evaluación hasta: ${process.periodEndDate}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Typography className="whiteText" style={{ fontWeight: 700, width: "100%", fontStyle: "italic" }}>
                            {summaryData.revision}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} style={{ marginTop: 10 }}>
                    <Grid container item xs={12} md={6} xl={8} justify="space-between" alignContent="space-between">
                        {
                            summaryData.evaluations.map((evaluation) => (
                                <Grid container item xs={12} key={evaluation.type} spacing={2}>
                                    <Grid item md={3} xs={3} className="whiteText">
                                        {types[evaluation.type]}
                                    </Grid>
                                    <Grid item xs={3} md={3} xl={1} style={evaluation.status === "Realizada" ? { color: "#00c700" } : { color: "red" }} >
                                        {evaluation.status}
                                    </Grid>
                                    <Grid item xs={3} md={3} xl={1} className="whiteText">
                                        {evaluation.resultType}
                                    </Grid>
                                    <Grid item xs={3} md={3} xl={1} className="whiteText" style={{ fontWeight: 700 }}>
                                        {`${evaluation.weightPercent}%`}
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Grid container item xs={12} md={6} xl={4}>
                        <Grid container item xs={12} style={{ height: 15 }}>
                            <Grid item xs={4} className="whiteText" style={{ fontWeight: 700 }}>
                                {summaryData.isPartialNote ? "NOTA PARCIAL DE DESEMPEÑO" : "NOTA FINAL DE DESEMPEÑO"}
                            </Grid>
                            <Grid
                                item xs={2} md={3} xl={1} className="whiteText"
                                style={{
                                    color: "black",
                                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                    fontWeight: 400,
                                    lineHeight: 1.5,
                                    letterSpacing: "0.00938em", fontSize: 16,
                                    textAlign: "center"
                                }}
                            >
                                {formatNumberOrReturnUndefined(summaryData.note, 1, 1)}
                            </Grid>
                            <Grid
                                item xs={5} md={4}
                                style={{
                                    paddingTop: 1,
                                    backgroundColor: summaryData.noteColor,
                                    marginLeft: 25,
                                    textAlign: "center",
                                    color: "black",
                                    height: 24
                                }}
                            >
                                {summaryData.noteString}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} style={{ height: 15 }} />
                        <Grid container item xs={12} style={{ height: 15 }}>
                            <Grid
                                item xs={4} className="whiteText"
                                style={{
                                    color: "black",
                                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                    fontWeight: 400,
                                    lineHeight: 1.5,
                                    letterSpacing: "0.00938em", fontSize: 16
                                }}
                            >
                                {`Nota máxima: ${formatNumberOrReturnUndefined(summaryData.maxNote, 1, 1)}`}
                            </Grid>
                            <Grid
                                item xs={2} md={3} xl={1} className="whiteText"
                                style={{
                                    color: "black",
                                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                    fontWeight: 400,
                                    lineHeight: 1.5,
                                    letterSpacing: "0.00938em", fontSize: 16,
                                    textAlign: "center",
                                }}
                            >
                                {"Ranking"}
                            </Grid>
                            <Grid item xs={5} md={4} className="whiteText" style={{ marginLeft: 25 }}>
                                <ProgressBar value={summaryData.ranking} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}

export default Header;