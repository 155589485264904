import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import { useHistory } from "react-router-dom";
import CreateEditForm from "./createEditForm";
import DuplicateDialog from "./dialogs/duplicateDialog";
import usePerformanceForms from "./usePerformanceForms";
import ScaleWarningDialog from "./dialogs/scaleWarningDialog";

const PerformanceForms = () => {

  const history = useHistory();

  const {
    data,
    typeList,
    scaleList,
    transversalSkillsList,
    evaluationGroupsList,
    isLoading,
    state,
    handlers,
  } = usePerformanceForms();

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.performanceDefinition)}
      type={"goBack"}
    />
  )

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const duplicateButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("duplicate")}
      onClick={() => handlers.handleOpenDuplicateDialog(gridRef)}
      type={"duplicate"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  return (
    <>
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditForm
          allRows={data}
          isLoading={isLoading}
          data={state.selectedData}
          typeList={typeList}
          scaleList={scaleList}
          transversalSkillsList={transversalSkillsList}
          evaluationGroupsList={evaluationGroupsList}
          handleClose={handlers.handleCloseCreateEditDialog}
          handleOpenScaleWarningDialog={handlers.handleOpenScaleWarningDialog}
        />
      }
      {
        state.scaleWarningDialogIsOpen &&
        <ScaleWarningDialog
          open={state.scaleWarningDialogIsOpen}
          code={state.scaleWarningCode}
          handleClose={handlers.handleCloseScaleWarningDialog}
        />
      }
      {
        !(state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CommonPage
          isLoading={isLoading}
          title={'Formularios de desempeño'}
          gridTitle={'Formularios de desempeño'}
          columnDefPage={paths.performanceForms}
          rowData={data}
          menuItems={[
            goBackButton,
            createButton,
            modifyButton,
            deleteButton,
            duplicateButton,
          ]}
          hasExpand
          hasHelp
        >
          {
            state.duplicateDialogIsOpen &&
            <DuplicateDialog
              open={state.duplicateDialogIsOpen}
              data={state.selectedData}
              evaluationGroupsList={evaluationGroupsList}
              isLoading={isLoading}
              handleClose={handlers.handleCloseDuplicateDialog}
            />
          }
        </CommonPage>
      }
    </>
  )
}

export default PerformanceForms;