import React from "react";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import Header from "./header";
import Body from "./body";

const SummaryScreen = (props) => {

    const {
        evaluatedName,
        isLoading,
        summaryData,
        process,
        handleOpenViewFeedback,
        handleClose,
    } = props;

    const color = useSelector(getAppColor);

    const goBackButton = () => (
        <CustomIconButton
            title={getLocalizedString("goBack")}
            onClick={handleClose}
            type={"goBack"}
        />
    )

    return (
        <CommonPage
            isNotGridPage
            isLoading={isLoading}
            title={"Resumen y resultados"}
            menuItems={[goBackButton]}
        >
            <Grid container item xs={12} style={{ margin: "-15px 25px 0px 25px" }}>
                <Header
                    color={color}
                    evaluatedName={evaluatedName}
                    summaryData={summaryData}
                    process={process}
                />
                <Body
                    color={color}
                    process={process}
                    summaryData={summaryData}
                    handleOpenViewFeedback={handleOpenViewFeedback}
                />
            </Grid>
        </CommonPage>
    );
}

export default SummaryScreen;