import React from "react";
import NineBoxChart from "@icarius-common/nineBoxChart";
import { Typography } from "@material-ui/core";

const Board = ({ title, data }) => {
    const {
        performance,
        potential,
        quadrantName,
        recommendation,
        description,
        number,
    } = data;

    const PieceContent = () => {
        return (
            <span style={{ fontSize: "20px", fontWeight: 700, color: "black" }}>{number}</span>
        )
    }

    const initialData = [{
        position: {
            x: parseInt(performance),
            y: parseInt(potential),
        }
    }];

    return (
        <div style={{ margin: "0 auto", paddingBottom: 20 }}>
            <Typography variant="h6" className="whiteText" style={{ textAlign: "center", marginRight: 35, marginTop: 5 }}>
                {title}
            </Typography>
            <NineBoxChart
                initialData={initialData}
                initialZoom={10}
                renderPieceContent={(props) => <PieceContent {...props} />}
                hideFreeUsers
                hideZoom
            />
            <Typography variant="h6" className="whiteText" style={{ textAlign: "center", marginRight: 35, marginTop: 5 }}>
                {`${quadrantName} - `}<p style={{ fontStyle: "italic", display: "inline" }}>{`Recomendación: ${recommendation}`}</p>
            </Typography>
            <Typography variant="h6" className="whiteText" style={{ textAlign: "center", marginRight: 35, marginTop: 10 }}>
                {"Comentarios:"}
            </Typography>
            <Typography variant="h6" className="whiteText" style={{ textAlign: "center", marginRight: 35, fontStyle: "italic" }}>
                {description}
            </Typography>
        </div>
    )
};

export default Board;