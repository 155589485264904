import React, { useState } from "react";
import { IconButton, Grid, Card, Tooltip } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon } from "@icarius-icons";
import Form from "./form";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { useDispatch, useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const DataItem = (props) => {
  const {
    data,
    closeNew,
    rowsToUse,
    setFormValue,
    items,
    disabled,
  } = props;

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);

  const isCreate = !Boolean(data);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto

  const handleDeletePublication = () => {
    if (isCreate) {
      closeNew();
    } else {
      const filteredItems = items.filter(el => el.number !== data.number);
      setFormValue(filteredItems.map((item, index) => ({ ...item, number: index + 1 })), "potentialQuestions");
    }
  }

  const handleClosePublication = () => {
    !isCreate && setIsOpen(prev => !prev);
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: '¿Esta seguro que desea eliminar la pregunta?',
      onConfirm: handleDeletePublication,
    }));
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={1}>
            {!isCreate && <DragIndicatorIcon />}
          </Grid>
          <Grid container item xs={10} style={{ cursor: !isCreate ? "pointer" : "" }} onClick={handleClosePublication}>
            {
              isCreate ?
                <DataContainer lg={12} sm={12} md={12} text={`Creación de pregunta`} />
                :
                <>
                  <DataContainer sm={12} md={6} lg={4} text={data.question} />
                  <DataContainer sm={12} md={6} lg={8} text={data.section} />
                </>
            }
          </Grid>
          <Grid container direction="row" item xs={1} justify="space-between">
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={!disabled && handleOpenDeleteDialog}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <Form
          disabled={disabled}
          setParentFormValue={setFormValue}
          items={items}
          data={data}
          rowsToUse={rowsToUse}
          handleClose={isCreate ? closeNew : handleClosePublication}
        />
      }
    </>
  );
}

export default DataItem;
