import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (submitCallback, calibrationCommitteeList, invalidDataCallback) => {

  const createInitialData = () => {
    const fieldNames = [
      "year",
      "performanceProcess",
      "calibrationCommittee",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: "",
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const schema = yup.object().shape({
      year: yup.string().required(),
      performanceProcess: yup.string().required(),
      calibrationCommittee: yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      const newCompareCommitteeValue = calibrationCommitteeList.filter((item) => item.key && item.key !== formData.calibrationCommittee).map((item) => item.key);

      submitCallback({
        ...formData,
        compareCommittee: newCompareCommitteeValue,
      });

      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === "year") {
      setFormData({
        ...formData,
        [fieldName]: value,
        performanceProcess: "",
        potentialProcess: "",
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  }

  return {
    formData,
    setFormValue,
    submit,
  };
}

export default useHandleForm;