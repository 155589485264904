import React from "react";
import paths from "@icarius-localization/paths";
import ScreenSelector from "@icarius-common/screenSelector";
import {
  PositionGroupsForEvaluationsIcon,
  TypesOfObjectivesIcon,
  PeriodsForObjectivesIcon,
  TypesOfSkillsIcon,
  RatingScalesIcon,
  FeedbackPurposeIcon,
  TypesOfScoreIcon,
  PerformanceFormsIcon,
} from "@icarius-icons/index";

const PerformanceDefinition = ({ history }) => {

  const data = [
    {
      name: "Grupos de cargos para evaluaciones",
      icon: <PositionGroupsForEvaluationsIcon />,
      path: paths.positionGroupsForEvaluations,
    },
    {
      name: "Tipos de objetivos",
      icon: <TypesOfObjectivesIcon />,
      path: paths.typesOfObjectives,
    },
    {
      name: "Períodos para los objetivos",
      icon: <PeriodsForObjectivesIcon />,
      path: paths.periodsForObjectives,
    },
    {
      name: "Competencias específicas",
      icon: <TypesOfSkillsIcon />,
      path: paths.typesOfSkillsSpecific,
    },
    {
      name: "Competencias transversales",
      icon: <TypesOfSkillsIcon />,
      path: paths.typesOfSkillsTransversal,
    },
    {
      name: "Propósitos de feedback",
      icon: <FeedbackPurposeIcon />,
      path: paths.feedbackPurpose,
      onClick: () => {
        history.push({
          pathname: paths.feedbackPurpose,
          state: {
            from: paths.performanceDefinition,
          },
        });
      }
    },
    {
      name: "Notas para evaluaciones",
      icon: <TypesOfScoreIcon />,
      path: paths.typesOfScore,
    },
    {
      name: "Escalas para calificaciones",
      icon: <RatingScalesIcon />,
      path: paths.ratingScales,
    },
    {
      name: "Formularios de desempeño",
      icon: <PerformanceFormsIcon />,
      path: paths.performanceForms,
    },
  ];

  return (
    <ScreenSelector
      title={"Definiciones de desempeño"}
      data={data}
    />
  );
}

export default PerformanceDefinition;