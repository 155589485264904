import React, { useState, useMemo, useRef } from "react";
import CommonPage from "@icarius-common/commonPage";
import AvatarRenderer from "@icarius-table/renderersAndFilters/avatarRenderer";
import {
  numberComparatorMax2,
  numberFormatterMin1Max1,
  numberFilterParams,
} from "@icarius-table/number";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import { ContainerTitle } from "./container";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const ByEvaluatedGridPage = (props) => {

  const {
    data,
    skillsScale,
    handleUpdateSkills,
    handleOpenCalibrationForm,
  } = props;

  const [showDescription, setShowDescription] = useState(false);

  const ownGridRef = useRef();
  const dispatch = useDispatch();

  const gridData = useMemo(() => {
    return data.byEvaluated.map((item) => {
      const auxRow = { ...item };

      item.skillValue.forEach((skillValue) => {
        auxRow[skillValue.keyString] = skillValue.value || null;
        auxRow[`${skillValue.keyString}Description`] = skillValue.stringValue || null;
      });

      item.userFields.forEach((userField) => {
        auxRow[userField.key] = userField.value;
      });

      return auxRow;
    })
  }, [data.byEvaluated]);

  const ownColumnDef = useMemo(() => {
    let columnDefinitions = getColumnDefByPage('performanceEvaluationsCalibrationsByEvaluated');
    const skillValueDynamicColumns = [];
    const skillsScaleNames = skillsScale.map((item) => item.value);
    const sourceData = data.byEvaluated;

    if (sourceData.length > 0) {
      sourceData[0].skillValue.forEach(({ keyString }) => {
        let itemNumeric = {
          headerName: keyString,
          field: keyString,
          filter: "agTextColumnFilter",
          comparator: numberComparatorMax2,
          filterParams: numberFilterParams,
          chartDataType: "series",
          valueFormatter: numberFormatterMin1Max1,
          enableValue: true,
          allowedAggFuncs: ["sum", "min", "max", "avg"],
        }

        let itemDescription = {
          headerName: keyString,
          field: `${keyString}Description`,
          filter: "agSetColumnFilter",
          editable: true,
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: skillsScaleNames,
          },
          valueSetter: (params) => {
            if (!params.data[`${keyString}Description`]) {
              dispatch(openSnackbarAction({
                msg: 'No se permite calibrar el indicador de la competencia porque no corresponde a su evaluación',
                severity: 'warning',
                duration: null,
              }));
              return false; // evitar setear dato
            }
            params.data[`${keyString}Description`] = params.newValue;
            return true;
          }
        }

        skillValueDynamicColumns.push(showDescription ? itemDescription : itemNumeric);
      });
    }

    columnDefinitions.splice(2, 0, ...skillValueDynamicColumns);

    return columnDefinitions;
  }, [data.byEvaluated, skillsScale, showDescription, dispatch]);

  const handleSave = (gridRef) => {
    const skillFields = data.byEvaluated[0].skillValue;
    const dataToSend = [];

    gridRef.props.rowData.forEach((rowData, index) => {
      const row = {
        evaluatedCode: rowData.evaluatedCode,
        skills: {},
      };

      skillFields.forEach((skillField) => {
        const originalValue = data.byEvaluated[index].skillValue.find((originalSkill) => originalSkill.key === skillField.key)?.value || null;
        const selectedNoteTypeKey = skillsScale.find((skillScale) => skillScale.value === rowData[`${skillField.keyString}Description`])?.key || null;

        if (originalValue && originalValue !== selectedNoteTypeKey) {
          row.skills[skillField.key] = selectedNoteTypeKey;
        }
      });

      if (Object.keys(row.skills)?.length) dataToSend.push(row);
    });

    if (!dataToSend.length) {
      dispatch(openSnackbarAction({ msg: 'No hay datos modificados para guardar', severity: 'info' }));
      return;
    }

    handleUpdateSkills(dataToSend);
  }

  const calibrateButton = () => (
    <CustomIconButton
      title={'Calibrar evaluación de desempeño'}
      onClick={() => handleOpenCalibrationForm(ownGridRef.current)}
      type={"calibratePerformance"}
    />
  )

  const saveButton = () => showDescription && (
    <CustomIconButton
      title={'Guardar'}
      onClick={() => handleSave(ownGridRef.current)}
      type={"save"}
    />
  )

  const title = 'Por evaluado y competencias';

  return (
    <div style={{ paddingTop: 10 }}>
      <ContainerTitle title={title} />
      <div style={{ padding: '8px 16px 16px' }}>
        <CommonPage
          gridTitle={title}
          ownGridRef={ownGridRef}
          ownColumnDef={ownColumnDef}
          rowData={gridData}
          menuItems={[calibrateButton, saveButton]}
          hasExpand
          hasHelp
          hasSwitch
          handleSwitchClick={() => setShowDescription(prev => !prev)}
          switchLabelOff={'Calificaciones numéricas'}
          switchLabelOn={'Calificaciones descriptivas'}
          switchValue={showDescription}
          frameworkComponents={{ AvatarRenderer }}
          ignoreProcessesbyPath
        />
      </div>
    </div>
  )
}

export default ByEvaluatedGridPage;