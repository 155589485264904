import React from "react";
import { Grid } from "@material-ui/core";
import Table from "@icarius-pages/performanceSummaryAndResults/components/graphics/table";
import Line from "@icarius-pages/performanceSummaryAndResults/components/graphics/line";
import { formatNumberExactDecimals } from "@icarius-utils/format";
import Container from "./container";
import Bar from "@icarius-pages/performanceSummaryAndResults/components/graphics/bar";

const Averages = (props) => {

    const {
        data,
        noteTypeList,
        noteColors,
        noteTypeRanges,
        evaluatorList,
    } = props;

    const byNoteTypeTableData = (() => {
        return noteTypeList.map((noteType, index) => {
            return ([
                noteType,
                `${formatNumberExactDecimals(data?.byNoteType?.[index], 2)}%`,
                formatNumberExactDecimals(noteTypeRanges[index].from, 1),
                formatNumberExactDecimals(noteTypeRanges[index].to, 1),
            ]);
        });
    })();

    const byGroupAndEvaluatorAverageData = (() => {
        return evaluatorList.map((_, index) => {
            const nonZeroValues = Object.values(data.byNoteTypeGroupAndEvaluator[index]).filter((item) => item);
            return nonZeroValues.reduce((avg, value, _, { length }) => avg + value / length, 0);
        })
    })();

    const byGroupAndEvaluatorTableData = (() => {
        return data.byNoteTypeGroupAndEvaluator.map((item, index) => {
            return ([
                evaluatorList[index],
                ...Object.values(item).map((item) => formatNumberExactDecimals(item || '', 1)),
                formatNumberExactDecimals(byGroupAndEvaluatorAverageData[index], 1),
            ])
        })
    })();

    return (
        <Container title={'Promedios'}>
            <Grid container item xs={12} spacing={1} style={{ margin: '20px auto' }}>
                <Grid container item xs={12}>
                    <Bar
                        title={'Promedios globales de evaluaciones por tipo de nota'}
                        list={noteTypeList}
                        data={[{ data: data?.byNoteType }]}
                        colors={[({ dataPointIndex }) => noteColors[dataPointIndex]]}
                    />
                </Grid>
                {
                    Boolean(byNoteTypeTableData.length) &&
                    <Grid container item xs={12}>
                        <Table
                            list={['Nota', 'Cantidad de evaluados', 'Nota desde', 'Nota hasta']}
                            data={byNoteTypeTableData}
                        />
                    </Grid>
                }
                <Grid container item xs={12} style={{ marginTop: 20 }}>
                    <Line
                        title={'Promedios de notas numéricas por evaluadores'}
                        list={evaluatorList}
                        data={byGroupAndEvaluatorAverageData}
                    />
                </Grid>
                {
                    Boolean(byGroupAndEvaluatorTableData.length) &&
                    <Grid container item xs={12}>
                        <Table
                            list={['Evaluador', ...noteTypeList, 'Promedio']}
                            data={byGroupAndEvaluatorTableData}
                        />
                    </Grid>
                }
            </Grid>
        </Container>
    )
}

export default Averages;