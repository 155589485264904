import React from "react";
import { useDispatch } from "react-redux";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { getLocalizedString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";

const styles = () => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    cssOutlinedInput: {
        color: "var(--mainText) !important",
        '&$cssFocused $notchedOutline': {
            color: "var(--mainText) !important",
        }
    },

    cssFocused: {
        color: "var(--mainText) !important",
    },

    notchedOutline: {
        borderColor: 'var(--icons) !important',
        color: "var(--mainText) !important",
    },
});

const CommentDialog = (props) => {

    const {
        open,
        data,
        title,
        classes,
        handleClose,
        commentFunctionArgs,
    } = props;

    const dispatch = useDispatch();

    const create = (dataToSend) => {
        commentFunctionArgs.function(...[...commentFunctionArgs.args, dataToSend.comment]);
        handleClose();
    }

    const openValidationError = () => {
        dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
    }

    const {
        formData,
        setFormValue,
        submit,
    } = useHandleForm(data, create, openValidationError);

    return (
        <Dialog
            open={open}
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            maxWidth={"md"}
            fullWidth={true}
        >
            <div className={"dialog-container"}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title}
                    <DialogTitleDivider />
                </DialogTitle>
                <DialogContent>
                    <Grid container item xs={12}>
                        <TextField
                            fullWidth
                            label="Comentario"
                            variant="outlined"
                            multiline
                            rows={5}
                            margin={"none"}
                            value={formData.comment}
                            InputLabelProps={{
                                classes: {
                                    root: classes.cssLabel,
                                    focused: classes.cssFocused,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                }
                            }}
                            inputProps={{ spellCheck: 'false' }}
                            onChange={(e) => setFormValue(e.target.value, "comment")}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default withStyles(styles)(CommentDialog);
