import React from "react";
import ReactApexChart from 'react-apexcharts';
import Container from "./container";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import { formatNumberExactDecimals } from "@icarius-utils/format";

const Pie = (props) => {

  const {
    title,
    list,
    data,
    colors,
  } = props;

  const { theme } = useSelector(getTheme);

  const chartData = {
    series: data,
    options: {
      chart: {
        type: 'pie',
        height: 350,
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false
        }
      },
      stroke: {
        show: false,
      },
      labels: list,
      tooltip: {
        enabled: true,
        fillSeriesColor: false,
        theme: theme,
        y: {
          formatter: (value) => formatNumberExactDecimals(value, 1),
        },
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          }
        },
        active: {
          filter: {
            type: 'none',
          }
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (percentage) => `${formatNumberExactDecimals(percentage, 1)}%`,
        style: {
          colors: ['var(--mainText)'],
        },
        dropShadow: {
          enabled: false,
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          colors: 'var(--mainText)',
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      colors: colors,
    },
  };

  return (
    <Container title={title}>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type={chartData.options.chart.type}
        height={chartData.options.chart.height}
      />
    </Container>
  )
}

export default Pie;