import paths from "@icarius-localization/paths";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Código de formulario',
            field: 'code',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Tipo de formulario',
            field: 'typeString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Grupo de cargos',
            field: 'groupString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Nombre del formulario',
            field: 'name',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Evalúa comp. específicas',
            field: 'evaluateSpecificSkillsString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Evalúa comp. transversales',
            field: 'evaluateTransversalSkillsString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Evalúa objetivos organización',
            field: 'evaluateOrganizationalTargetsString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Evalúa objetivos personales',
            field: 'evaluatePersonalTargetsString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Evalúa potencial',
            field: 'evaluatePotentialString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Ponderado competencias',
            field: 'skillsWeight',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Pond. comp. específicas',
            field: 'specificSkillsWeight',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Pond. comp. transversales',
            field: 'transversalSkillsWeight',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Ponderados objetivos',
            field: 'targetsWeight',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Pond. objetivos organización',
            field: 'organizationalTargetsWeight',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Pond. objetivos personales',
            field: 'personalTargetsWeight',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Tipo de escala competencias',
            field: 'skillsScaleString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Tipo de escala objetivos',
            field: 'targetsScaleString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Tipo de escala potencial',
            field: 'potentialScaleString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Objetivos solo de visualización',
            field: 'targetsOnlyVisualizationString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceForms],
        config: {
            headerName: 'Califica a los objetivos con %',
            field: 'targetsWithPercentageString',
            filter: "agSetColumnFilter",
        }
    },
]