import React from "react";
import { useDispatch } from "react-redux";
import {
    TextField,
    Grid,
    Select,
    MenuItem,
    FormControl,
    Typography,
    InputLabel,
} from "@material-ui/core";
import { PerformanceFormUpwardIcon, PerformanceFormDownwardIcon, PerformanceFormSelfEvaluationIcon, PerformanceFormPeerEvaluationIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import { openDialogAction } from "@icarius-common/dialog/actions";


const gridStyle = { height: 75, padding: "0px 10px" };

const typeListIcons = {
    "ASC": <PerformanceFormUpwardIcon />,
    "DES": <PerformanceFormDownwardIcon />,
    "AUT": <PerformanceFormSelfEvaluationIcon />,
    "PAR": <PerformanceFormPeerEvaluationIcon />,
};

const CustomSelect = withStyles({
    select: {
        display: "flex",
        alignItems: "center",
    },
})(Select);


const StepContainer = ({ title, children }) => {
    return (
        <Grid container item xs={12} justify="center">
            <Grid container item sm={12} md={10}>
                <Typography className="whiteText" style={{ fontSize: 20, width: '100%', padding: '0px 10px' }}>
                    {title}
                </Typography>
                <Grid container item alignItems="center" justify="space-between" xs={12} style={{ paddingTop: 10 }}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

const StepOne = (props) => {
    const {
        formData,
        isCreate,
        typeList,
        setFormValue,
        evaluationGroupsList,
    } = props;
    const dispatch = useDispatch();

    const modifyType = (value, questions) => {
        if (questions.length > 0) {
            dispatch(openDialogAction({
                title: 'Atención',
                msg: `Si modifica el tipo de formulario, todas las preguntas existentes y relacionadas con ese tipo de la evaluación serán eliminadas`,
                onConfirm: () => setFormValue(false, "evaluatePotential"),
            }));
        } else {
            setFormValue(value, "type");
        }
    }
    return (
        <StepContainer title={'Indique los parámetros del nuevo formulario de desempeño'}>
            <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}>
                <TextField
                    disabled={!isCreate}
                    required
                    fullWidth
                    margin={"none"}
                    label={'Código'}
                    value={formData.code}
                    inputProps={{ maxLength: 20 }}
                    onChange={(e) => setFormValue(e.target.value, "code")}
                />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}>
                <TextField
                    required
                    fullWidth
                    margin={"none"}
                    label={'Nombre diseño del formulario'}
                    value={formData.name}
                    inputProps={{ maxLength: 60 }}
                    onChange={(e) => setFormValue(e.target.value, "name")}
                />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                    <InputLabel id={'label-type'}>{'Tipo de formulario'}</InputLabel>
                    <CustomSelect
                        disabled={formData.inUse}
                        value={formData.type}
                        labelId={'label-type'}
                        id={'select-type'}
                        onChange={(e) => modifyType(e.target.value, formData.potentialQuestions)}
                        margin={"none"}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        {
                            typeList.map(item => (
                                <MenuItem
                                    className={"whiteText"}
                                    key={item.key}
                                    value={item.key}>
                                    {typeListIcons.hasOwnProperty(item.key) && <div style={{ paddingTop: 2, marginRight: 5 }}>{typeListIcons[item.key]}</div>}{item.value}
                                </MenuItem>
                            ))
                        }
                    </CustomSelect>
                </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                    <InputLabel shrink id={'label-group'}>{'Grupo de cargos a evaluar'}</InputLabel>
                    <Select
                        displayEmpty
                        value={formData.group}
                        labelId={'label-group'}
                        id={'select-group'}
                        onChange={(e) => setFormValue(e.target.value, "group")}
                        margin={"none"}
                    >
                        {
                            evaluationGroupsList.map(item => (
                                <MenuItem
                                    className={"whiteText"}
                                    key={item.key}
                                    value={item.key}>
                                    {item.value}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
        </StepContainer>
    )
}


export default StepOne;
