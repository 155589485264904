import React from "react";
import { useSelector } from "react-redux";
import {
    TextField,
    Grid,
    FormControl,
    Typography, Tooltip,
    Radio,
    Divider,
    FormControlLabel, RadioGroup, Slider,
    Card,
    Table, Paper, TableCell, TableContainer, TableBody, TableRow, TableHead
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { getTheme } from "@icarius-pages/login/selectors";
import ProfileImg from '@icarius-common/profileImg';

const styles = ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    cssOutlinedInput: {
        color: "var(--mainText) !important",
        '&$cssFocused $notchedOutline': {
            color: "var(--mainText) !important",
        }
    },

    cssFocused: {
        color: "var(--mainText) !important",
    },

    notchedOutline: {
        borderColor: 'var(--icons) !important',
        color: "var(--mainText) !important",
    },

});

const CustomRadioButton = withStyles({
    root: {
        color: "white",
        "&$checked": {
            color: "white"
        }
    },
    checked: {}
})(Radio);

const CustomFormControlLabel = withStyles({
    root: {
        margin: 10,
    },
    label: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
})(FormControlLabel);

const CustomFormControlLabelDisplayBlock = withStyles({
    root: {
        margin: 10,
        display: "block",
    },
    label: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
})(FormControlLabel);


const StepContainer = ({ title, children }) => {
    return (
        <Grid container item xs={12} justify="center">
            <Grid container item sm={12} md={10}>
                <Typography className="whiteText" style={{ fontSize: 20, width: '100%', padding: '0px 10px' }}>
                    {title}
                </Typography>
                <Grid container item alignItems="center" justify="space-between" xs={12} style={{ paddingTop: 10 }}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

const StepFive = (props) => {
    const {
        isReadOnly,
        headerData,
        data,
        formData,
        color,
        setFormValue,
        handleSetCommentFunction,
        classes,
    } = props;

    const theme = useSelector(getTheme);

    const getProgressBackgroundColor = (percentage) => {
        let backgroundColor = "";
        if (theme.theme === "dark") {
            if (percentage < 41) {
                backgroundColor = '#c7463c';
            } else if (percentage < 71) {
                backgroundColor = '#db9a00';
            } else {
                backgroundColor = '#67ad34';
            }
        } else {
            if (percentage < 41) {
                backgroundColor = '#FF4040';
            } else if (percentage < 71) {
                backgroundColor = '#F5A506';
            } else {
                backgroundColor = '#00993D';
            }
        }
        return backgroundColor
    }

    const getElementsForSkillTable = (name, selectedElement) => {
        if (selectedElement) {
            return selectedElement.indicators.map(el => el.value);
        }

        return [];
    }

    const createSkillTable = (name, type, selectedElement, scale) => {
        return <Table key={name} aria-label="simple table" >
            <TableHead >
                <TableRow style={{ verticalAlign: "initial" }}>
                    <Tooltip arrow title={selectedElement?.description || ""}><TableCell >{name}</TableCell></Tooltip>
                    {
                        getSkillsScaleHeader(scale)
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    getElementsForSkillTable(name, selectedElement).map((el, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row" style={{ width: "400px", textAlign: "justify" }}>
                                {el}
                            </TableCell>
                            {
                                scale?.details?.map((scaleElement, index) => {
                                    let isChecked = false;
                                    let comment = "";
                                    let arrayToUse = type === "specificSkills" ? [...data.specificSkills] : [...data.transversalSkills];

                                    // Busco el elemento correspondiente
                                    let elementIndex = arrayToUse.findIndex(el => el.value === name);

                                    if (elementIndex > -1) {
                                        // Voy a buscar la key correspondiente
                                        let indicatorIndex = arrayToUse[elementIndex].indicators.findIndex(indicator => indicator.value === el);

                                        if (indicatorIndex > -1) {
                                            // Me fijo si esta seleccionada la columna actual
                                            if (formData[type][elementIndex]["indicators"][indicatorIndex]["qualification"] === scaleElement.value) {
                                                isChecked = true;
                                            }
                                            comment = formData[type][elementIndex]["indicators"][indicatorIndex]["comments"];
                                        }
                                    }

                                    return <TableCell key={index} align="center">
                                        <Radio disabled={isReadOnly} color='primary' onClick={(e) => data.requiresJustification && (index === 0 || (index + 1) === scale.details.length) ? handleSetCommentFunction(index, setFormValue, [scaleElement.value, type, name, el, comment]) : setFormValue(scaleElement.value, type, name, el)} checked={isChecked} />
                                    </TableCell>
                                })
                            }
                        </TableRow>
                    ))}
            </TableBody>
        </Table >
    }

    const getSkillsScaleHeader = (scale) => {
        switch (scale.type) {
            case 'E': // Estrellas
                return scale.details?.map((el, index) => (<Tooltip key={index} arrow title={el.criterion || ""}><TableCell align="center">{Array(parseInt(index + 1) + 1).join("⭐")}</TableCell></Tooltip>))
            case 'C': // Criterios
                return scale.details?.map((el, index) => (<TableCell key={index} align="center" style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    backgroundColor: scale.useColor ? el.color : "transparent"
                }}>
                    <p>{el.name}</p>
                    <p style={{
                        paddingTop: 10,
                        textAlign: "center",
                        borderTop: `1px solid black`,
                    }
                    }>
                        {el.criterion}
                    </p>
                </TableCell>))
            case 'N': // Nombres
                return scale.details?.map((el, index) => (<Tooltip key={index} arrow title={el.criterion || ""}><TableCell align="center" style={scale.useColor ? { backgroundColor: el.color, color: "black" } : {}}>{el.name}</TableCell></Tooltip>))
            case 'V': // Valores
                return scale.details?.map((el, index) => (<Tooltip key={index} arrow title={el.criterion || ""}><TableCell align="center" style={scale.useColor ? { backgroundColor: el.color, color: "black" } : {}}>{el.value}</TableCell></Tooltip>))
            default:
                return null;
        }
    }


    const createPotentialQuestionsRepresentation = (scale) => {
        let child = null;

        if (scale) {
            // Filtro duplicados y ordeno alfabeticamente
            const sections = [...new Set(formData.potentialQuestions.map(el => el.section))].sort((a, b) => a.localeCompare(b));
            if (sections.includes("")) {
                sections.push(sections.shift());
            }
            child = sections.map((section, sectionIndex) => {
                const innerElements = formData.potentialQuestions.filter(el => section === el.section).sort((a, b) => a.number - b.number).map((element, index) => (
                    <Grid key={index} container item xs={12}>
                        <Tooltip arrow title={element.description || ""}>
                            <Typography className="whiteText" style={{ fontSize: 16, marginLeft: 5, marginRight: 10, fontWeight: 600 }}>
                                {`${(index + 1)}. ${element.question}`}
                            </Typography>
                        </Tooltip>
                        <Grid container item xs={12} style={{ padding: "10px 0px" }}>
                            {
                                (['E', 'N', 'V'].includes(scale.type)) ?
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    getSkillsScaleHeader(scale)
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {scale.details?.map((el, index) => {
                                                    let isChecked = false;
                                                    let arrayToUse = [...data.potentialQuestions];

                                                    // Busco el elemento correspondiente
                                                    let elementIndex = arrayToUse.findIndex(arr => arr.question === element.question);
                                                    if (elementIndex > -1) {
                                                        // Me fijo si esta seleccionada la columna actual
                                                        if (formData["potentialQuestions"][elementIndex]["qualification"] === el.value) {
                                                            isChecked = true;
                                                        }
                                                    }
                                                    return <TableCell key={index} align="center"><Radio disabled={isReadOnly} color='primary' checked={isChecked} onClick={(e) => setFormValue(el.value, "potentialQuestions", element.question, el)} /></TableCell>
                                                })}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    :
                                    <FormControl component="fieldset">
                                        <RadioGroup row aria-label="position" name="position" defaultValue="top" value={""}>
                                            {
                                                scale.details?.map((el, index) => {
                                                    let isChecked = false;
                                                    let arrayToUse = [...data.potentialQuestions];

                                                    // Busco el elemento correspondiente
                                                    let elementIndex = arrayToUse.findIndex(arr => arr.question === element.question);

                                                    if (elementIndex > -1) {
                                                        // Me fijo si esta seleccionada la columna actual
                                                        if (formData["potentialQuestions"][elementIndex]["qualification"] === el.value) {
                                                            isChecked = true;
                                                        }
                                                    }

                                                    return <CustomFormControlLabelDisplayBlock
                                                        key={index}
                                                        disabled
                                                        value="top"
                                                        control={
                                                            <div style={{
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                borderRadius: 10,
                                                                padding: 10,
                                                                whiteSpace: "normal",
                                                                wordWrap: "break-word",
                                                                backgroundColor: scale.useColor ? el.color : "transparent"
                                                            }}>

                                                                <CustomRadioButton disabled={isReadOnly} color='primary' onClick={(e) => setFormValue(el.value, "potentialQuestions", element.question, el)} checked={isChecked} />
                                                                <p>{el.name}</p>
                                                                <p style={{
                                                                    paddingTop: 10,
                                                                    marginTop: -5,
                                                                    width: 200,
                                                                    wordBreak: "break-word",
                                                                    textAlign: "center",
                                                                    borderTop: `1px solid black`,
                                                                }
                                                                }>
                                                                    {el.criterion}
                                                                </p>
                                                            </div>
                                                        }
                                                    />
                                                })
                                            }
                                        </RadioGroup>
                                    </FormControl>
                            }
                        </Grid>
                    </Grid>
                ))

                return <Grid key={sectionIndex} container item xs={12} style={{ paddingLeft: 20 }}>
                    <Typography className="whiteText" style={{ fontSize: 20, marginTop: 5, marginBottom: 10, width: '100%' }}>
                        {section}
                    </Typography>
                    <Grid container item xs={12} style={{ paddingLeft: 10 }}>
                        {innerElements}
                    </Grid>
                </Grid>
            });
        }

        return <Grid item xs={12}>
            {child}
        </Grid>;
    }

    const createTargetForm = (type, targetElement, scale) => {
        if (data.targetsOnlyVisualization) {
            return null;
        }
        if (data.targetsWithPercentage) {
            const sliderValue = targetElement.qualification;
            return <Grid container item alignItems="center" xs={12} md={5} style={{ height: '' }}>
                <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {"Porcentaje de calificación"}
                </Typography>
                <TextField
                    disabled={isReadOnly}
                    type={"number"}
                    required
                    style={{ marginLeft: 20, width: 45 }}
                    margin={"none"}
                    value={sliderValue}
                    inputProps={{ maxLength: 3 }}
                    onChange={(e) => setFormValue(e.target.value.substring(0, 3), type, targetElement.key)}
                />
                <Slider
                    disabled={isReadOnly}
                    style={{ width: "50%", marginLeft: 20 }}
                    min={0}
                    max={100}
                    step={1}
                    defaultValue={0}
                    value={sliderValue}
                    onChange={(_, value) => setFormValue(value, type, targetElement.key)}
                />
            </Grid>
        } else {
            switch (scale.type) {
                case 'E': // Estrellas
                    // Reviso el último valor
                    const detailsLength = scale?.details.length || 0;
                    if (detailsLength > 0) {
                        const max = scale.details[detailsLength - 1].value;
                        if (max > 5) {
                            return <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            scale.details?.map((el, index) => (<Tooltip key={index} arrow title={el.criterion || ""}><TableCell align="center">{Array(parseInt(index + 1) + 1).join("⭐")}</TableCell></Tooltip>))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        <TableRow >
                                            {
                                                scale?.details?.map((scaleElement, index) => {
                                                    let isChecked = false;
                                                    let comment = "";
                                                    let arrayToUse = type === "personalTargets" ? [...data.personalTargets] : [...data.organizationalTargets];

                                                    // Busco el elemento correspondiente
                                                    let elementIndex = arrayToUse.findIndex(el => el.key === targetElement.key);

                                                    if (elementIndex > -1) {
                                                        // Me fijo si esta seleccionada la columna actual
                                                        if (formData[type][elementIndex]["qualification"] === scaleElement.value) {
                                                            isChecked = true;
                                                        }
                                                        comment = formData[type][elementIndex]["comments"];
                                                    }
                                                    return <TableCell key={index} align="center">
                                                        <Radio disabled={isReadOnly} color='primary' onClick={(e) => data.requiresJustification && (index === 0 || (index + 1) === scale.details.length) ? handleSetCommentFunction(index, setFormValue, [scaleElement.value, type, targetElement.key, null, comment]) : setFormValue(scaleElement.value, type, targetElement.key)} checked={isChecked} />
                                                    </TableCell>
                                                })
                                            }

                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>

                        }

                        let selectedValue = 0;

                        let arrayToUse = type === "personalTargets" ? [...data.personalTargets] : [...data.organizationalTargets];

                        // Busco el elemento correspondiente
                        let elementIndex = arrayToUse.findIndex(el => el.key === targetElement.key);

                        if (elementIndex > -1) {
                            // Me fijo si esta seleccionada la columna actual
                            selectedValue = Number(formData[type][elementIndex]["qualification"]);
                        }

                        return <Grid item xs={12}>
                            {
                                [...Array(max)].map((number, index) => {
                                    const element = scale.details.find(el => el.value === (index + 1));
                                    const isSelected = selectedValue && (index + 1) <= selectedValue;

                                    if (element) {
                                        return (
                                            <Tooltip key={index} arrow title={element.criterion || ""}>
                                                <div
                                                    style={{ marginRight: 10, display: "inline-block", cursor: "pointer" }}
                                                    onClick={() => !isReadOnly && setFormValue(element.value, type, targetElement.key)}
                                                >
                                                    {
                                                        isSelected ? <StarIcon style={{ fill: "var(--starColor)" }} /> : <StarBorderIcon />
                                                    }
                                                </div>
                                            </Tooltip>
                                        )
                                    }

                                    return (
                                        <div
                                            key={index}
                                            style={{ marginRight: 10, display: "inline-block", cursor: "pointer" }}
                                            onClick={() => !isReadOnly && setFormValue(index + 1, type, targetElement.key)}
                                        >
                                            {
                                                isSelected ? <StarIcon style={{ fill: "var(--starColor)" }} /> : <StarBorderIcon />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                    }
                    return null;
                case 'C': // Criterios
                    return <FormControl component="fieldset">
                        <RadioGroup row aria-label="position" name="position" defaultValue="top" value={""}>
                            {
                                scale.details?.map((el, index) => {
                                    let isChecked = false;
                                    let comment = "";
                                    let arrayToUse = type === "personalTargets" ? [...data.personalTargets] : [...data.organizationalTargets];

                                    // Busco el elemento correspondiente
                                    let elementIndex = arrayToUse.findIndex(el => el.key === targetElement.key);

                                    if (elementIndex > -1) {
                                        // Me fijo si esta seleccionada la columna actual
                                        if (formData[type][elementIndex]["qualification"] === el.value) {
                                            isChecked = true;
                                        }
                                        comment = formData[type][elementIndex]["comments"];
                                    }

                                    return <CustomFormControlLabelDisplayBlock
                                        key={index}
                                        value="top"
                                        control={
                                            <div style={{
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                borderRadius: 10,
                                                padding: 10,
                                                whiteSpace: "normal",
                                                wordWrap: "break-word",
                                                backgroundColor: scale.useColor ? el.color : "transparent"
                                            }}>

                                                <CustomRadioButton disabled={isReadOnly} color='primary' onClick={(e) => data.requiresJustification && ((index === 0 || (index + 1) === scale.details.length)) ? handleSetCommentFunction(index, setFormValue, [el.value, type, targetElement.key, null, comment]) : setFormValue(el.value, type, targetElement.key)} checked={isChecked} />
                                                <p>{el.name}</p>
                                                <p style={{
                                                    paddingTop: 10,
                                                    marginTop: -5,
                                                    width: 200,
                                                    wordBreak: "break-word",
                                                    textAlign: "center",
                                                    borderTop: `1px solid black`,
                                                }
                                                }>
                                                    {el.criterion}
                                                </p>
                                            </div>
                                        }
                                    />
                                }
                                )
                            }
                        </RadioGroup>
                    </FormControl>
                case 'N': // Nombres
                case 'V': // Valores
                    return <FormControl component="fieldset">
                        <RadioGroup row aria-label="position" name="position" defaultValue="top" value={""}>
                            {
                                scale.details?.map((el, index) => {
                                    let isChecked = false;
                                    let comment = "";
                                    let arrayToUse = type === "personalTargets" ? [...data.personalTargets] : [...data.organizationalTargets];

                                    // Busco el elemento correspondiente
                                    let elementIndex = arrayToUse.findIndex(el => el.key === targetElement.key);

                                    if (elementIndex > -1) {
                                        // Me fijo si esta seleccionada la columna actual
                                        if (formData[type][elementIndex]["qualification"] === el.value) {
                                            isChecked = true;
                                        }
                                        comment = formData[type][elementIndex]["comments"];
                                    }

                                    return <CustomFormControlLabel
                                        key={index}
                                        value="top"
                                        control={<Radio disabled={isReadOnly} onClick={(e) => data.requiresJustification && ((index === 0 || (index + 1) === scale.details.length)) ? handleSetCommentFunction(index, setFormValue, [el.value, type, targetElement.key, null, comment]) : setFormValue(el.value, type, targetElement.key)} checked={isChecked} />}
                                        label={scale.type === "N" ? el.name : el.value}
                                        labelPlacement="top"
                                    />
                                }
                                )
                            }
                        </RadioGroup>
                    </FormControl>
                default:
                    return null;
            }
        }
    }

    return (
        <StepContainer>
            <Card style={{ padding: 20, marginLeft: 10, paddingTop: 10, width: "100%" }}>
                <Grid container item xs={12} style={{ justifyContent: "space-between" }}>
                    <Grid container item xs={11}>
                        <Typography className="whiteText" variant="h6" style={{ width: "100%" }}>
                            {headerData?.processName}
                        </Typography>
                        <Typography className="whiteText" style={{ width: "100%" }}>
                            {"Evaluación "}
                            {<b style={{ color: "red", display: "inline" }}>{headerData?.processType}</b>}
                        </Typography>
                        <Typography className="whiteText" style={{ width: "100%" }}>
                            {"Evaluado:"}
                            {<b style={{ color: "red", marginLeft: 5, display: "inline" }}>{headerData?.evaluated}</b>}
                        </Typography>
                        <Typography className="whiteText" style={{ width: "100%" }}>
                            {headerData?.evaluator}
                        </Typography>
                    </Grid>
                    <Grid container item xs={1} style={{ alignItems: "center" }}>
                        <ProfileImg
                            image={headerData.evaluatedPhoto}
                            size={64}
                        />
                    </Grid>
                </Grid>
                {
                    (data.specificSkills.length > 0 || formData.transversalSkills.length > 0) &&
                    <>
                        <Divider style={{ margin: "10px 0px", background: color, height: 2 }} />
                        <Grid container item xs={12}>
                            <Typography className="whiteText" style={{ fontSize: 20, width: '100%' }}>
                                {"Competencias"}
                            </Typography>
                            {
                                data.specificSkills.length > 0 &&
                                <Grid container item xs={12} style={{ paddingLeft: 20 }}>
                                    <Typography className="whiteText" style={{ fontSize: 20, marginTop: 5, marginBottom: 10, width: '100%' }}>
                                        {"Competencias específicas del cargo"}
                                    </Typography>
                                    {
                                        data.specificSkills.map((el, index) => {
                                            return <Grid key={index} container item xs={12} style={{ padding: 10 }}>
                                                <Grid container item xs={12} style={{ marginBottom: 20 }}>
                                                    <TableContainer component={Paper}>
                                                        {createSkillTable(el.value, "specificSkills", el, data.skillsScale)}
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            }
                            {
                                formData.transversalSkills.length > 0 &&
                                <>
                                    <Grid container item xs={12} style={{ paddingLeft: 20 }}>
                                        <Typography className="whiteText" style={{ fontSize: 20, marginTop: 30, marginBottom: 10, width: '100%' }}>
                                            {"Competencias transversales"}
                                        </Typography>
                                        <Grid container item xs={12} style={{ padding: 10 }}>
                                            {
                                                data.transversalSkills.map((el, index) => (
                                                    <Grid container item xs={12} style={{ marginBottom: 20 }} key={index}>
                                                        <TableContainer component={Paper}>
                                                            {createSkillTable(el.value, "transversalSkills", el, data.skillsScale)}
                                                        </TableContainer>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </>
                }
                {
                    (formData.personalTargets.length > 0 || formData.organizationalTargets.length > 0) &&
                    <>
                        <Divider style={{ margin: "10px 0px", background: color, height: 2 }} />
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography className="whiteText" style={{ fontSize: 20, width: '100%' }}>
                                    {"Metas y objetivos"}
                                </Typography>
                            </Grid>
                            {formData.personalTargets.length > 0 &&
                                <Grid container item xs={12} style={{ padding: 10 }}>
                                    <Typography className="whiteText" style={{ fontSize: 18, width: '100%' }}>
                                        {"Objetivos personales"}
                                    </Typography>
                                    {
                                        formData.personalTargets.map((el, index) => {
                                            return <Grid key={index} container item xs={12} style={{ padding: '20px 10px' }}>
                                                <Grid item xs={12}>
                                                    <Typography className="whiteText" style={{ fontSize: 16, fontWeight: 600 }}>
                                                        {el.value}
                                                    </Typography>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                    {createTargetForm("personalTargets", el, data.targetsScale)}
                                                </Grid>
                                                <Grid item xs={12} style={{ marginTop: 5 }}>
                                                    {
                                                        !data.targetsOnlyVisualization ?
                                                            <>
                                                                <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                                    {`Estado: ${el.status}`}
                                                                </Typography>
                                                                <div style={{ display: "inline-block", height: 14, width: 107, marginRight: 10 }}>
                                                                    <div style={{ display: "inline-block", height: "100%", width: `${Number(el.progress)}%`, backgroundColor: `${getProgressBackgroundColor(Number(el.progress))}` }} />
                                                                    <div style={{ display: "inline-block", height: "100%", width: `${(100 - Number(el.progress))}%`, backgroundColor: "#747474" }} />
                                                                </div>
                                                                <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                                    {`${el.progress}% - Fecha de término ${el.endDate} - Fecha de progreso: ${el.progressDate} - Ponderado ${el.weight}%`}
                                                                </Typography>
                                                            </> :
                                                            <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                                {`Ponderado ${el.weight}%`}
                                                            </Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            }
                            {
                                formData.organizationalTargets.length > 0 &&
                                <Grid container item xs={12} style={{ padding: 10 }}>
                                    <Typography className="whiteText" style={{ fontSize: 18, width: '100%' }}>
                                        {"Objetivos organizacionales"}
                                    </Typography>
                                    {
                                        formData.organizationalTargets.map((el, index) => {
                                            return <Grid key={index} container item xs={12} style={{ padding: '20px 10px' }}>
                                                <Grid item xs={12}>
                                                    <Typography className="whiteText" style={{ fontSize: 16, fontWeight: 600 }}>
                                                        {el.value}
                                                    </Typography>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                    {createTargetForm("organizationalTargets", el, data.targetsScale)}
                                                </Grid>
                                                <Grid item xs={12} style={{ marginTop: 5 }}>
                                                    {
                                                        !data.targetsOnlyVisualization ?
                                                            <>
                                                                <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                                    {`Estado: ${el.status}`}
                                                                </Typography>
                                                                <div style={{ display: "inline-block", height: 14, width: 107, marginRight: 10 }}>
                                                                    <div style={{ display: "inline-block", height: "100%", width: `${Number(el.progress)}%`, backgroundColor: `${getProgressBackgroundColor(Number(el.progress))}` }} />
                                                                    <div style={{ display: "inline-block", height: "100%", width: `${(100 - Number(el.progress))}%`, backgroundColor: "#747474" }} />
                                                                </div>
                                                                <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                                    {`${el.progress}% - Fecha de término ${el.endDate} - Fecha de progreso: ${el.progressDate} - Ponderado ${el.weight}%`}
                                                                </Typography>
                                                            </> :
                                                            <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                                {`Ponderado ${el.weight}%`}
                                                            </Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            }
                        </Grid>
                    </>
                }
                {
                    data.potentialQuestions.length > 0 &&
                    <>
                        <Divider style={{ margin: "10px 0px", background: color, height: 2 }} />
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography className="whiteText" style={{ fontSize: 20, width: '100%' }}>
                                    {`Preguntas para evaluar el potencial (${data.potentialQuestions.length})`}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} style={{ padding: 10 }}>
                                {createPotentialQuestionsRepresentation(data.potentialScale)}
                            </Grid>
                        </Grid>
                    </>
                }
                <>
                    <Divider style={{ margin: "10px 0px", background: color, height: 2 }} />
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography className="whiteText" style={{ fontSize: 20, width: '100%' }}>
                                {data.typeString === "Autoevaluación" ? "Comentarios de mi autoevaluación" : "Comentarios generales del evaluador"}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} style={{ padding: 10 }}>
                            <TextField
                                disabled={isReadOnly}
                                fullWidth
                                variant="outlined"
                                multiline
                                rows={5}
                                margin={"none"}
                                value={formData.comments}
                                onChange={(e) => setFormValue(e.target.value, "comments")}
                                inputProps={{ spellCheck: 'false' }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.cssOutlinedInput,
                                        focused: classes.cssFocused,
                                        notchedOutline: classes.notchedOutline,
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </>
            </Card>
        </StepContainer >
    )
}


export default withStyles(styles)(StepFive);
