import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const GET_INITIAL = NAME + "/GET_INITIAL";
export const GET_INITIAL_REJECTED = NAME + "/GET_INITIAL_REJECTED";
export const GET_INITIAL_FULFILLED = NAME + "/GET_INITIAL_FULFILLED";

export const GET_RESUME = NAME + "/GET_RESUME";
export const GET_RESUME_REJECTED = NAME + "/GET_RESUME_REJECTED";
export const GET_RESUME_FULFILLED = NAME + "/GET_RESUME_FULFILLED";

export const CREATE = NAME + "/CREATE";
export const CREATE_REJECTED = NAME + "/CREATE_REJECTED";
export const CREATE_FULFILLED = NAME + "/CREATE_FULFILLED";

export const SEND_MAIL = NAME + "/SEND_MAIL";
export const SEND_MAIL_REJECTED = NAME + "/SEND_MAIL_REJECTED";
export const SEND_MAIL_FULFILLED = NAME + "/SEND_MAIL_FULFILLED";
