import React from "react";
import CommonPage from "@icarius-common/commonPage";
import Global from "./global";
import Averages from "./averages";
import Ranking from "./ranking";
import BySkillsGridPage from "./bySkillsGridPage";
import ByEvaluatedGridPage from "./byEvaluatedGridPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";

const GraphicsPage = (props) => {

  const {
    data,
    handleOpenCalibrationForm,
    handleUpdateSkills,
    handleClose,
  } = props;

  const goBackButton = () => (
    <CustomIconButton
      title={'Volver'}
      onClick={handleClose}
      type={"goBack"}
    />
  )

  return (
    <CommonPage
      menuItems={[goBackButton]}
      isNotGridPage
    >
      <div style={{ margin: '0px 25px' }}>
        <Global
          data={data.global}
          noteTypeList={data.noteTypeList}
          noteTypeRanges={data.noteTypeRanges}
          noteColors={data.noteColors}
          evaluatorList={data.evaluatorList}
        />
        <Averages
          data={data.averages}
          noteTypeList={data.noteTypeList}
          noteTypeRanges={data.noteTypeRanges}
          noteColors={data.noteColors}
          evaluatorList={data.evaluatorList}
          />
        <Ranking
          data={data.ranking}
          evaluatorList={data.evaluatorList}
          collaboratorList={data.collaboratorList}
          />
        <BySkillsGridPage
          data={data.evaluatedAndSkills}
          />
        <ByEvaluatedGridPage
          data={data.evaluatedAndSkills}
          skillsScale={data.skillsScale}
          handleUpdateSkills={handleUpdateSkills}
          handleOpenCalibrationForm={handleOpenCalibrationForm}
        />
      </div>
    </CommonPage>
  )
}

export default GraphicsPage;