import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const titleStyle = { marginTop: 5, fontWeight: 900, fontSize: 24, marginBottom: 5 };

const CustomExpansionPanelDetails = withStyles({
    root: {
        paddingTop: 0,
    },
})(ExpansionPanelDetails);

const CustomExpansionPanel = withStyles({
    root: {
        backgroundColor: "transparent",
        boxShadow: "none",
        width: '100%'
    },
})(ExpansionPanel);

const CustomExpansionPanelSummary = withStyles({
    root: {
        padding: 0
    },
})(ExpansionPanelSummary);

const Panel = ({ title, children }) => {
    return (
        <CustomExpansionPanel>
            <CustomExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
            >
                <Typography className="whiteText" style={titleStyle}>
                    {title}
                </Typography>
            </CustomExpansionPanelSummary>
            <CustomExpansionPanelDetails>
                <Grid item xs={12}>
                    {children}
                </Grid>
            </CustomExpansionPanelDetails>
        </CustomExpansionPanel>
    )
}

export default Panel;