import React, { useState } from "react";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";

const gridStyle = { height: 75, padding: "10px 0px" };

const FILTER_NAMES = [
  'Evaluador descendiente',
  'Calibración realizada',
  "Evaluación aceptada por el evaluado",
  "Nota final desempeño estándar",
  "Nota final desempeño calibrada",
  "Ranking",
  "Clasificación cuadrante calibrado",
  "Lugar de trabajo",
  "Obra o faena",
  "Centro de costo de gestión",
  "Gerencia",
  "Departamento",
  "Cargo",
  "Función",
  "Categoría",
]

const FiltersDialog = (props) => {

  const {
    open,
    initialValues,
    filterOptions,
    handleSubmit,
    handleClose,
  } = props;

  const [formData, setFormData] = useState(initialValues);

  const handleChange = (value, fieldName) => {
    // si deselecciono todo, setear el valor ""
    if (value.length === 0) {
      setFormData(prev => ({
        ...prev,
        [fieldName]: [""],
      }));
      return;
    }

    const hasOnlyNullOption = formData[fieldName].length === 1 && formData[fieldName].includes("");

    // si tenia solo "", y ahora tiene "" y otros, setear los otros.
    if (hasOnlyNullOption && value.length > 1 && value.includes("")) {
      setFormData(prev => ({
        ...prev,
        [fieldName]: value.filter((item) => item),
      }));
      return;
    }

    // si no tenia "" pero ahora si, setear solo el "".    
    if (!formData[fieldName].includes("") && value.includes("")) {
      setFormData(prev => ({
        ...prev,
        [fieldName]: [""],
      }));
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {"Filtrar"}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container direction="column" style={{ maxHeight: 500, flexWrap: "nowrap" }}>
          {
            filterOptions && Object.keys(filterOptions).map((filter, index) => {
              const name = FILTER_NAMES[index];

              return (
                <Grid key={index} container direction="row" justify="center" item style={gridStyle}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel shrink id={`label-${name}`}>{name}</InputLabel>
                    <Select
                      displayEmpty
                      multiple
                      value={formData[filter]}
                      labelId={`label-${filter}`}
                      id={`select-${filter}`}
                      onChange={(e) => handleChange(e.target.value, filter)}
                      margin={"none"}
                    >
                      <MenuItem
                        className={"whiteText"}
                        key={''}
                        value={''}
                      >
                        {'Todos'}
                      </MenuItem>
                      {
                        filterOptions[filter]?.map((item) => (
                          <MenuItem
                            className={"whiteText"}
                            key={item}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              )
            })
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={'Cancelar'} />
        <ButtonDialogAction onClick={() => handleSubmit(null)} isAccept text={'Resetear'} />
        <ButtonDialogAction onClick={() => handleSubmit(formData)} isAccept text={getLocalizedString("agree")} />
      </DialogActions>
    </Dialog>
  )
}

export default FiltersDialog;