import React from "react";
import {
    TextField,
    Grid,
    FormControl,
    Typography,
    Tooltip,
    Radio,
    Divider,
    FormControlLabel,
    RadioGroup,
    Slider,
    Card,
    Table,
    Paper,
    TableCell,
    TableContainer,
    TableBody,
    TableRow,
    TableHead,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ProfileImg from '@icarius-common/profileImg';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { generalColors, getProgressColor } from "@icarius-utils/colors";
import ProgressBar from "./progressBar";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";

const styles = ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    cssOutlinedInput: {
        color: "var(--mainText) !important",
        '&$cssFocused $notchedOutline': {
            color: "var(--mainText) !important",
        }
    },

    cssFocused: {
        color: "var(--mainText) !important",
    },

    notchedOutline: {
        borderColor: 'var(--icons) !important',
        color: "var(--mainText) !important",
    },

});

const CustomRadioButton = withStyles({
    root: {
        color: "white",
        "&$checked": {
            color: "white"
        }
    },
    checked: {}
})(Radio);

const CustomRadioButtonStandard = withStyles({
    root: {
        color: "white",
        "&$checked": {
            color: "#6f7375"
        }
    },
    checked: {}
})(Radio);

const CustomFormControlLabel = withStyles({
    root: {
        margin: 10,
    },
    label: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
})(FormControlLabel);

const CustomFormControlLabelDisplayBlock = withStyles({
    root: {
        margin: 10,
        display: "block",
    },
    label: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
})(FormControlLabel);


const StepContainer = ({ title, children }) => {
    return (
        <Grid container item xs={12} justify="center">
            <Grid container item sm={12} md={10}>
                <Typography className="whiteText" style={{ fontSize: 20, width: '100%', padding: '0px 10px' }}>
                    {title}
                </Typography>
                <Grid container item alignItems="center" justify="space-between" xs={12} style={{ paddingTop: 10 }}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

const StepFive = (props) => {
    const {
        isReadOnly,
        headerData,
        data,
        formData,
        color,
        setFormValue,
        handleSetCommentFunction,
        classes,
        newOptionRef,
    } = props;

    const calibrationIcon = (type, formData, name, targetElement) => {
        let calibrationNumber = 0;
        let standardNumber = 0;

        if (type === "specificSkills" || type === "transversalSkills") {
            let arrayToUse = type === "specificSkills" ? [...data.specificSkills] : [...data.transversalSkills];

            // Busco el elemento correspondiente
            let elementIndex = arrayToUse.findIndex(el => el.value === name);

            if (elementIndex > -1) {
                // Voy a buscar la key correspondiente
                let indicatorIndex = arrayToUse[elementIndex].indicators.findIndex(indicator => indicator.value === targetElement);

                if (indicatorIndex > -1) {
                    standardNumber = formData[type][elementIndex]["indicators"][indicatorIndex]["qualification"];
                    calibrationNumber = formData[type][elementIndex]["indicators"][indicatorIndex]["calibration"];
                }
            }
        } else if (type === "organizationalTargets" || type === "personalTargets") {
            let arrayToUse = type === "personalTargets" ? [...data.personalTargets] : [...data.organizationalTargets];

            // Busco el elemento correspondiente
            let elementIndex = arrayToUse.findIndex(el => el.key === targetElement.key);

            if (elementIndex > -1) {
                standardNumber = formData[type][elementIndex]["qualification"];
                calibrationNumber = formData[type][elementIndex]["calibration"];
            }
        } else if (type === "potentialQuestions") {
            let arrayToUse = [...data.potentialQuestions];

            // Busco el elemento correspondiente
            let elementIndex = arrayToUse.findIndex(arr => arr.question === targetElement.question);
            if (elementIndex > -1) {
                // Me fijo si esta seleccionada la columna actual
                standardNumber = formData["potentialQuestions"][elementIndex]["qualification"];
                calibrationNumber = formData["potentialQuestions"][elementIndex]["calibration"];
            }
        }

        let diff = calibrationNumber - standardNumber;

        if (diff === 0) return <DragHandleIcon style={{ fill: generalColors.blue }} />
        if (diff > 0) return <ArrowUpwardIcon style={{ fill: generalColors.green }} />
        return <ArrowDownwardIcon style={{ fill: generalColors.red }} />
    }

    const getElementsForSkillTable = (name, selectedElement) => {
        if (selectedElement) {
            return selectedElement.indicators.map(el => el.value);
        }

        return [];
    }

    const createSkillTable = (name, type, selectedElement, scale) => {
        return <Table key={name} aria-label="simple table" >
            <TableHead >
                <TableRow style={{ verticalAlign: "initial" }}>
                    <Tooltip arrow title={selectedElement?.description || ""}><TableCell >{name}</TableCell></Tooltip>
                    {
                        getSkillsScaleHeader(scale)
                    }
                    <TableCell width="90" />
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    getElementsForSkillTable(name, selectedElement).map((el, index) => (
                        <>
                            <TableRow key={index}>
                                <TableCell component="th" scope="row" style={{ width: "400px" }}>
                                    {`${el}`}<p style={{ fontWeight: 800, display: "inline-block", marginLeft: 3, lineHeight: 0 }}>{` (Estándar)`}</p>
                                </TableCell>
                                {
                                    scale?.details?.map((scaleElement, index) => {
                                        let isChecked = false;
                                        let comment = "";
                                        let arrayToUse = type === "specificSkills" ? [...data.specificSkills] : [...data.transversalSkills];

                                        // Busco el elemento correspondiente
                                        let elementIndex = arrayToUse.findIndex(el => el.value === name);

                                        if (elementIndex > -1) {
                                            // Voy a buscar la key correspondiente
                                            let indicatorIndex = arrayToUse[elementIndex].indicators.findIndex(indicator => indicator.value === el);

                                            if (indicatorIndex > -1) {
                                                // Me fijo si esta seleccionada la columna actual
                                                if (formData[type][elementIndex]["indicators"][indicatorIndex]["qualification"] === scaleElement.value) {
                                                    isChecked = true;
                                                    comment = formData[type][elementIndex]["indicators"][indicatorIndex]["comments"];
                                                }
                                            }
                                        }

                                        return <Tooltip arrow title={comment || ""}>
                                            <TableCell key={index} align="center">
                                                <Radio disabled color='primary' onClick={(e) => data.requiresJustification && (index === 0 || (index + 1) === scale.details.length) ? handleSetCommentFunction(index, setFormValue, [scaleElement.value, type, name, el, comment]) : setFormValue(scaleElement.value, type, name, el)} checked={isChecked} />
                                            </TableCell>
                                        </Tooltip>
                                    })
                                }
                                <TableCell width="90" />
                            </TableRow>
                            <TableRow key={`${index}Calibrada`}>
                                <TableCell component="th" scope="row" style={{ width: "400px" }}>
                                    {`${el}`}<p style={{ fontWeight: 800, display: "inline-block", marginLeft: 3, lineHeight: 0 }}>{` (Calibrada)`}</p>
                                </TableCell>
                                {
                                    scale?.details?.map((scaleElement, index) => {
                                        let isChecked = false;
                                        let comment = "";
                                        let arrayToUse = type === "specificSkills" ? [...data.specificSkills] : [...data.transversalSkills];

                                        // Busco el elemento correspondiente
                                        let elementIndex = arrayToUse.findIndex(el => el.value === name);

                                        if (elementIndex > -1) {
                                            // Voy a buscar la key correspondiente
                                            let indicatorIndex = arrayToUse[elementIndex].indicators.findIndex(indicator => indicator.value === el);

                                            if (indicatorIndex > -1) {
                                                // Me fijo si esta seleccionada la columna actual
                                                if (formData[type][elementIndex]["indicators"][indicatorIndex]["calibration"] === scaleElement.value) {
                                                    isChecked = true;
                                                    comment = formData[type][elementIndex]["indicators"][indicatorIndex]["commentsCalibration"];
                                                }
                                            }
                                        }

                                        return <TableCell key={index} align="center">
                                            <Radio disabled={isReadOnly} color='primary' onClick={(e) => data.requiresJustification && (index === 0 || (index + 1) === scale.details.length) ? handleSetCommentFunction(index, setFormValue, [scaleElement.value, type, name, el, comment]) : setFormValue(scaleElement.value, type, name, el)} checked={isChecked} />
                                        </TableCell>
                                    })
                                }
                                <TableCell align="center">
                                    {calibrationIcon(type, formData, name, el)}
                                    {commentsCalibrationIcon(type, name, el, scale)}
                                </TableCell>
                            </TableRow>
                        </>
                    ))}
            </TableBody>
        </Table >
    }

    const getSkillsScaleHeader = (scale) => {
        switch (scale.type) {
            case 'E': // Estrellas
                return scale.details?.map((el, index) => (<Tooltip key={index} arrow title={el.criterion || ""}><TableCell align="center">{Array(parseInt(index + 1) + 1).join("⭐")}</TableCell></Tooltip>))
            case 'C': // Criterios
                return scale.details?.map((el, index) => (<TableCell key={index} align="center" style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    backgroundColor: scale.useColor ? el.color : "transparent",
                }}>
                    <p>{el.name}</p>
                    <p style={{
                        paddingTop: 10,
                        textAlign: "center",
                        borderTop: `1px solid black`,
                    }
                    }>
                        {el.criterion}
                    </p>
                </TableCell>))
            case 'N': // Nombres
                return scale.details?.map((el, index) => (<Tooltip key={index} arrow title={el.criterion || ""}><TableCell align="center" style={scale.useColor ? { backgroundColor: el.color, color: "black" } : {}}>{el.name}</TableCell></Tooltip>))
            case 'V': // Valores
                return scale.details?.map((el, index) => (<Tooltip key={index} arrow title={el.criterion || ""}><TableCell align="center" style={scale.useColor ? { backgroundColor: el.color, color: "black" } : {}}>{el.value}</TableCell></Tooltip>))
            default:
                return null;
        }
    }


    const createPotentialQuestionsRepresentation = (scale) => {
        let child = null;

        if (scale) {
            // Filtro duplicados y ordeno alfabeticamente
            const sections = [...new Set(formData.potentialQuestions.map(el => el.section))].sort((a, b) => a.localeCompare(b));
            if (sections.includes("")) {
                sections.push(sections.shift());
            }
            child = sections.map((section, sectionIndex) => {
                const innerElements = formData.potentialQuestions.filter(el => section === el.section).sort((a, b) => a.number - b.number).map((element, index) => (
                    <>
                        <Grid container item xs={12}>
                            <Tooltip arrow title={element.description || ""}>
                                <Typography className="whiteText" style={{ fontSize: 16, marginLeft: 5, marginRight: 10, fontWeight: 600 }}>
                                    {`${(index + 1)}. ${element.question}`}<p style={{ fontWeight: 800, display: "inline-block", marginLeft: 3 }}>{` (Estándar)`}</p>
                                </Typography>
                            </Tooltip>
                            <Grid container item xs={12} style={{ padding: "10px 0px" }}>
                                {
                                    (['E', 'N', 'V'].includes(scale.type)) ?
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    {
                                                        getSkillsScaleHeader(scale)
                                                    }
                                                    <TableCell width="90" />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    {scale.details?.map((el, index) => {
                                                        let isChecked = false;
                                                        let arrayToUse = [...data.potentialQuestions];
                                                        let comment = "";

                                                        // Busco el elemento correspondiente
                                                        let elementIndex = arrayToUse.findIndex(arr => arr.question === element.question);
                                                        if (elementIndex > -1) {
                                                            // Me fijo si esta seleccionada la columna actual
                                                            if (formData["potentialQuestions"][elementIndex]["qualification"] === el.value) {
                                                                isChecked = true;
                                                                comment = formData["potentialQuestions"][elementIndex]["comments"];
                                                            }
                                                        }
                                                        return <Tooltip arrow title={comment || ""}>
                                                            <TableCell key={index} align="center"><Radio disabled color='primary' checked={isChecked} onClick={(e) => setFormValue(el.value, "potentialQuestions", element.question, el)} /></TableCell>
                                                        </Tooltip>
                                                    })}
                                                    <TableCell width="90" />
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        :
                                        <FormControl component="fieldset" style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "row"
                                        }}>
                                            <RadioGroup row aria-label="position" name="position" defaultValue="top" value={""}>
                                                {
                                                    scale.details?.map((el, index) => {
                                                        let isChecked = false;
                                                        let arrayToUse = [...data.potentialQuestions];

                                                        // Busco el elemento correspondiente
                                                        let elementIndex = arrayToUse.findIndex(arr => arr.question === element.question);

                                                        if (elementIndex > -1) {
                                                            // Me fijo si esta seleccionada la columna actual
                                                            if (formData["potentialQuestions"][elementIndex]["qualification"] === el.value) {
                                                                isChecked = true;
                                                            }
                                                        }

                                                        return <CustomFormControlLabelDisplayBlock
                                                            key={index}
                                                            disabled
                                                            value="top"
                                                            control={
                                                                <div style={{
                                                                    height: "100%",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                    borderRadius: 10,
                                                                    padding: 10,
                                                                    whiteSpace: "normal",
                                                                    wordWrap: "break-word",
                                                                    backgroundColor: scale.useColor ? el.color : "transparent"
                                                                }}>

                                                                    <CustomRadioButtonStandard disabled color='primary' onClick={(e) => setFormValue(el.value, "potentialQuestions", element.question, el)} checked={isChecked} />
                                                                    <p>{el.name}</p>
                                                                    <p style={{
                                                                        paddingTop: 10,
                                                                        marginTop: -5,
                                                                        width: 200,
                                                                        wordBreak: "break-word",
                                                                        textAlign: "center",
                                                                        borderTop: `1px solid black`,
                                                                    }
                                                                    }>
                                                                        {el.criterion}
                                                                    </p>
                                                                </div>
                                                            }
                                                        />
                                                    })
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                }
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Tooltip arrow title={element.description || ""}>
                                <Typography className="whiteText" style={{ fontSize: 16, marginLeft: 5, marginRight: 10, fontWeight: 600 }}>
                                    {`${(index + 1)}. ${element.question}`}<p style={{ fontWeight: 800, display: "inline-block", marginLeft: 3, lineHeight: 0 }}>{` (Calibrada)`}</p>
                                </Typography>
                            </Tooltip>
                            <Grid container item xs={12} style={{ padding: "10px 0px" }}>
                                {
                                    (['E', 'N', 'V'].includes(scale.type)) ?
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    {
                                                        getSkillsScaleHeader(scale)
                                                    }
                                                    <TableCell width="90" />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    {scale.details?.map((el, index) => {
                                                        let isChecked = false;
                                                        let comment = "";
                                                        let arrayToUse = [...data.potentialQuestions];

                                                        // Busco el elemento correspondiente
                                                        let elementIndex = arrayToUse.findIndex(arr => arr.question === element.question);
                                                        if (elementIndex > -1) {
                                                            // Me fijo si esta seleccionada la columna actual
                                                            if (formData["potentialQuestions"][elementIndex]["calibration"] === el.value) {
                                                                isChecked = true;
                                                                comment = formData["potentialQuestions"][elementIndex]["commentsCalibration"];
                                                            }
                                                        }
                                                        return <Tooltip arrow title={comment || ""}>
                                                            <TableCell key={index} align="center"><Radio disabled={isReadOnly} color='primary' checked={isChecked} onClick={(e) => setFormValue(el.value, "potentialQuestions", element.question, el)} /></TableCell>
                                                        </Tooltip>
                                                    })}
                                                    <TableCell align="center" width="90">
                                                        {calibrationIcon("potentialQuestions", formData, "", element)}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        :
                                        <FormControl component="fieldset" style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "row"
                                        }}>
                                            <RadioGroup row aria-label="position" name="position" defaultValue="top" value={""}>
                                                {
                                                    scale.details?.map((el, index) => {
                                                        let isChecked = false;
                                                        let arrayToUse = [...data.potentialQuestions];

                                                        // Busco el elemento correspondiente
                                                        let elementIndex = arrayToUse.findIndex(arr => arr.question === element.question);

                                                        if (elementIndex > -1) {
                                                            // Me fijo si esta seleccionada la columna actual
                                                            if (formData["potentialQuestions"][elementIndex]["calibration"] === el.value) {
                                                                isChecked = true;
                                                            }
                                                        }

                                                        return <CustomFormControlLabelDisplayBlock
                                                            key={index}
                                                            disabled={isReadOnly}
                                                            value="top"
                                                            control={
                                                                <div style={{
                                                                    height: "100%",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                    borderRadius: 10,
                                                                    padding: 10,
                                                                    whiteSpace: "normal",
                                                                    wordWrap: "break-word",
                                                                    backgroundColor: scale.useColor ? el.color : "transparent"
                                                                }}>

                                                                    <CustomRadioButton disabled={isReadOnly} color='primary' onClick={(e) => setFormValue(el.value, "potentialQuestions", element.question, el)} checked={isChecked} />
                                                                    <p>{el.name}</p>
                                                                    <p style={{
                                                                        paddingTop: 10,
                                                                        marginTop: -5,
                                                                        width: 200,
                                                                        wordBreak: "break-word",
                                                                        textAlign: "center",
                                                                        borderTop: `1px solid black`,
                                                                    }
                                                                    }>
                                                                        {el.criterion}
                                                                    </p>
                                                                </div>
                                                            }
                                                        />
                                                    })
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                }
                            </Grid>
                        </Grid>
                    </>
                ))

                return <Grid key={sectionIndex} container item xs={12} style={{ paddingLeft: 20 }}>
                    <Typography className="whiteText" style={{ fontSize: 20, marginTop: 5, marginBottom: 10, width: '100%' }}>
                        {section}
                    </Typography>
                    <Grid container item xs={12} style={{ paddingLeft: 10 }}>
                        {innerElements}
                    </Grid>
                </Grid>
            });
        }

        return <Grid item xs={12}>
            {child}
        </Grid>;
    }

    const commentsCalibrationIcon = (type, targetElement, subElement, scale) => {
        let arrayToUse = [];
        let hasCalibrationComment = false;
        let isMinimum = false;
        let scaleElement = -1;
        let comment = "";
        let elementIndex = -1;

        switch (type) {
            case "personalTargets":
            case "organizationalTargets":
                arrayToUse = type === "organizationalTargets" ? [...data.organizationalTargets] : [...data.personalTargets];
                elementIndex = arrayToUse.findIndex(el => el.key === targetElement.key);
                hasCalibrationComment = Boolean(formData[type][elementIndex]["commentsCalibration"]);
                comment = formData[type][elementIndex]["commentsCalibration"];
                scaleElement = scale.details.findIndex(el => el.value === formData[type][elementIndex]["calibration"]);
                isMinimum = scaleElement === 0;
                break;
            case "transversalSkills":
            case "specificSkills":
                arrayToUse = type === "transversalSkills" ? [...data.transversalSkills] : [...data.specificSkills];
                elementIndex = arrayToUse.findIndex(el => el.value === targetElement);
                if (elementIndex > -1) {
                    let indicatorIndex = arrayToUse[elementIndex].indicators.findIndex(indicator => indicator.value === subElement);
                    scaleElement = scale.details.findIndex(el => el.value === formData[type][elementIndex]["indicators"][indicatorIndex]["calibration"]);
                    isMinimum = scaleElement === 0;
                    hasCalibrationComment = Boolean(formData[type][elementIndex]["indicators"][indicatorIndex]["commentsCalibration"]);
                    comment = formData[type][elementIndex]["indicators"][indicatorIndex]["commentsCalibration"];
                }
                break;
            default:
                break;
        }

        if (hasCalibrationComment) {
            const formattedComment = `Calificación ${isMinimum ? 'mínima' : 'máxima'}:\n ${comment}`;
            return <Tooltip arrow title={<div style={{ whiteSpace: 'pre-line' }}>{formattedComment}</div>}><FiberManualRecordIcon htmlColor={color} /></Tooltip>
        }

        return null;
    }

    const createTargetForm = (type, targetElement, scale, calibration) => {
        if (data.targetsOnlyVisualization) {
            return null;
        }
        if (data.targetsWithPercentage) {
            const sliderValue = calibration ? targetElement.calibration : targetElement.qualification;
            return <Grid container item alignItems="center" xs={12} md={6} style={{ height: '' }}>
                <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {"Porcentaje de calificación"}
                </Typography>
                <TextField
                    type={"number"}
                    required
                    style={{ marginLeft: 20, width: 45 }}
                    margin={"none"}
                    value={sliderValue}
                    inputProps={{ maxLength: 3 }}
                    onChange={(e) => setFormValue(e.target.value.substring(0, 3), type, targetElement.key)}
                />
                <Slider
                    disabled={!calibration}
                    style={{ width: "50%", marginLeft: 20 }}
                    min={0}
                    max={100}
                    step={1}
                    defaultValue={0}
                    value={sliderValue}
                    onChange={(_, value) => setFormValue(value, type, targetElement.key)}
                />
                <div style={{ marginLeft: 20 }}>
                    {calibration && calibrationIcon(type, formData, "", targetElement)}
                </div>
            </Grid>
        } else {
            switch (scale.type) {
                case 'E': // Estrellas
                    // Reviso el último valor
                    const detailsLength = scale?.details.length || 0;
                    if (detailsLength > 0) {
                        const max = scale.details[detailsLength - 1].value;
                        if (max > 5) {
                            return <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            scale.details?.map((el, index) => (<Tooltip key={index} arrow title={el.criterion || ""}><TableCell align="center">{Array(parseInt(index + 1) + 1).join("⭐")}</TableCell></Tooltip>))
                                        }
                                        <TableCell width="90" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        <TableRow >
                                            {
                                                scale?.details?.map((scaleElement, index) => {
                                                    let isChecked = false;
                                                    let comment = "";
                                                    let arrayToUse = type === "personalTargets" ? [...data.personalTargets] : [...data.organizationalTargets];

                                                    // Busco el elemento correspondiente
                                                    let elementIndex = arrayToUse.findIndex(el => el.key === targetElement.key);

                                                    if (elementIndex > -1) {
                                                        // Me fijo si esta seleccionada la columna actual
                                                        if (formData[type][elementIndex][calibration ? "calibration" : "qualification"] === scaleElement.value) {
                                                            isChecked = true;
                                                            comment = formData[type][elementIndex][calibration ? "commentsCalibration" : "comments"];
                                                        }
                                                    }
                                                    return <Tooltip arrow title={comment || ""}>
                                                        <TableCell key={index} align="center">
                                                            <Radio disabled={!calibration || isReadOnly} color='primary' onClick={(e) => data.requiresJustification && (index === 0 || (index + 1) === scale.details.length) ? handleSetCommentFunction(index, setFormValue, [scaleElement.value, type, targetElement.key, null, comment]) : setFormValue(scaleElement.value, type, targetElement.key)} checked={isChecked} />
                                                        </TableCell>
                                                    </Tooltip>
                                                })
                                            }
                                            <TableCell align="center">
                                                {calibration && calibrationIcon(type, formData, "", targetElement)}
                                                {calibration && commentsCalibrationIcon(type, targetElement, null, scale)}
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>

                        }

                        let selectedValue = 0;

                        let arrayToUse = type === "personalTargets" ? [...data.personalTargets] : [...data.organizationalTargets];

                        // Busco el elemento correspondiente
                        let elementIndex = arrayToUse.findIndex(el => el.key === targetElement.key);

                        if (elementIndex > -1) {
                            // Me fijo si esta seleccionada la columna actual
                            selectedValue = Number(formData[type][elementIndex][calibration ? "calibration" : "qualification"]);
                        }

                        return <Grid item xs={12}>
                            {
                                [...Array(max)].map((number, index) => {
                                    const element = scale.details.find(el => el.value === (index + 1));
                                    const isSelected = selectedValue && (index + 1) <= selectedValue;

                                    if (element) {
                                        return (
                                            <Tooltip key={index} arrow title={element.criterion || ""}>
                                                <div
                                                    style={{ marginRight: 10, display: "inline-block", cursor: calibration ? "pointer" : "initial" }}
                                                    onClick={() => calibration && !isReadOnly && setFormValue(element.value, type, targetElement.key)}
                                                >
                                                    {
                                                        isSelected ? <StarIcon style={{ fill: calibration ? "var(--starColor)" : "#747574" }} /> : <StarBorderIcon />
                                                    }
                                                </div>
                                            </Tooltip>
                                        )
                                    }

                                    return (
                                        <div
                                            key={index}
                                            style={{ marginRight: 10, display: "inline-block", cursor: calibration ? "pointer" : "initial" }}
                                            onClick={() => calibration && !isReadOnly && setFormValue(index + 1, type, targetElement.key)}
                                        >
                                            {
                                                isSelected ? <StarIcon style={{ fill: calibration ? "var(--starColor)" : "#747574" }} /> : <StarBorderIcon />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Grid>
                    }
                    return null;
                case 'C': // Criterios
                    return <FormControl component="fieldset" style={{
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row"
                    }}>
                        <RadioGroup row aria-label="position" name="position" defaultValue="top" value={""}>
                            {
                                scale.details?.map((el, index) => {
                                    let isChecked = false;
                                    let comment = "";
                                    let arrayToUse = type === "personalTargets" ? [...data.personalTargets] : [...data.organizationalTargets];

                                    // Busco el elemento correspondiente
                                    let elementIndex = arrayToUse.findIndex(el => el.key === targetElement.key);

                                    if (elementIndex > -1) {
                                        // Me fijo si esta seleccionada la columna actual
                                        if (formData[type][elementIndex][calibration ? "calibration" : "qualification"] === el.value) {
                                            isChecked = true;
                                            comment = formData[type][elementIndex][calibration ? "commentsCalibration" : "comments"];
                                        }
                                    }

                                    return <CustomFormControlLabelDisplayBlock
                                        key={index}
                                        value="top"
                                        control={
                                            <div style={{
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                borderRadius: 10,
                                                padding: 10,
                                                whiteSpace: "normal",
                                                wordWrap: "break-word",
                                                backgroundColor: scale.useColor ? el.color : "transparent"
                                            }}>

                                                {calibration && <CustomRadioButton disabled={!calibration || isReadOnly} color='primary' onClick={(e) => data.requiresJustification && ((index === 0 || (index + 1) === scale.details.length)) ? handleSetCommentFunction(index, setFormValue, [el.value, type, targetElement.key, null, comment]) : setFormValue(el.value, type, targetElement.key)} checked={isChecked} />}
                                                {!calibration && <CustomRadioButtonStandard disabled={!calibration || isReadOnly} color='primary' onClick={(e) => data.requiresJustification && ((index === 0 || (index + 1) === scale.details.length)) ? handleSetCommentFunction(index, setFormValue, [el.value, type, targetElement.key, null, comment]) : setFormValue(el.value, type, targetElement.key)} checked={isChecked} />}
                                                <p>{el.name}</p>
                                                <p style={{
                                                    paddingTop: 10,
                                                    marginTop: -5,
                                                    width: 200,
                                                    wordBreak: "break-word",
                                                    textAlign: "center",
                                                    borderTop: `1px solid black`,
                                                }
                                                }>
                                                    {el.criterion}
                                                </p>
                                            </div>
                                        }
                                    />
                                }
                                )
                            }
                        </RadioGroup>
                        {calibration && calibrationIcon(type, formData, "", targetElement)}
                        {calibration && commentsCalibrationIcon(type, targetElement, null, scale)}
                    </FormControl>
                case 'N': // Nombres
                case 'V': // Valores
                    return <FormControl component="fieldset" style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row"
                    }}>
                        <RadioGroup row aria-label="position" name="position" defaultValue="top" value={""}>
                            {
                                scale.details?.map((el, index) => {
                                    let isChecked = false;
                                    let comment = "";
                                    let arrayToUse = type === "personalTargets" ? [...data.personalTargets] : [...data.organizationalTargets];

                                    // Busco el elemento correspondiente
                                    let elementIndex = arrayToUse.findIndex(el => el.key === targetElement.key);

                                    if (elementIndex > -1) {
                                        // Me fijo si esta seleccionada la columna actual
                                        if (formData[type][elementIndex][calibration ? "calibration" : "qualification"] === el.value) {
                                            isChecked = true;
                                            comment = formData[type][elementIndex][calibration ? "commentsCalibration" : "comments"];
                                        }
                                    }

                                    return <CustomFormControlLabel
                                        key={index}
                                        value="top"
                                        control={<Radio disabled={!calibration || isReadOnly} onClick={(e) => data.requiresJustification && ((index === 0 || (index + 1) === scale.details.length)) ? handleSetCommentFunction(index, setFormValue, [el.value, type, targetElement.key, null, comment]) : setFormValue(el.value, type, targetElement.key)} checked={isChecked} />}
                                        label={scale.type === "N" ? el.name : el.value}
                                        labelPlacement="top"
                                    />
                                }
                                )
                            }
                        </RadioGroup>
                        {calibration && calibrationIcon(type, formData, "", targetElement)}
                        {calibration && commentsCalibrationIcon(type, targetElement, null, scale)}
                    </FormControl>
                default:
                    return null;
            }
        }
    }

    return (
        <StepContainer>
            <Card style={{ padding: 20, marginLeft: 10, paddingTop: 10, width: "100%" }}>
                <Grid container item xs={12} style={{ justifyContent: "space-between" }}>
                    <Grid container item xs={12} md={6} lg={6}>
                        <Typography className="whiteText" variant="h6" style={{ width: "100%" }}>
                            {`${headerData?.processName} - ${headerData.commitee}`}
                        </Typography>
                        <Typography className="whiteText" style={{ width: "100%" }}>
                            {"Evaluación "}
                            {<b style={{ color: "red", display: "inline" }}>{headerData?.processType}</b>}
                        </Typography>
                        <Typography className="whiteText" style={{ width: "100%" }}>
                            {"Evaluado:"}
                            {<b style={{ color: "red", marginLeft: 5, display: "inline" }}>{headerData?.evaluated}</b>}
                        </Typography>
                        <Typography className="whiteText" style={{ width: "100%" }}>
                            {headerData?.evaluator}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} md={5} xl={4} style={{ alignContent: "center" }}>
                        <Grid container item xs={12} style={{ height: 35 }}>
                            <Grid item xs={2} md={3} xl={2} />
                            <Grid item xs={8} className="whiteText" style={{ fontWeight: 700, paddingLeft: 25, textWrap: "nowrap" }}>
                                {data.isPartialNote ? "NOTA PARCIAL DE DESEMPEÑO" : "NOTA FINAL DE DESEMPEÑO"}
                            </Grid>
                            <Grid item xs={1} />
                            <Grid
                                item xs={2} md={3} xl={2} className="whiteText"
                                style={{
                                    color: "black",
                                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                    fontWeight: 400,
                                    lineHeight: 1.5,
                                    letterSpacing: "0.00938em", fontSize: 16,
                                    textAlign: "center"
                                }}
                            >
                                {formatNumberOrReturnUndefined(data.note, 1, 1)}
                            </Grid>
                            <Grid item xs={6} md={6} style={{ paddingTop: 1, backgroundColor: data.noteColor, marginLeft: 25, textAlign: "center", color: "black" }}>
                                {data.noteString}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} style={{ height: 15 }} />
                        <Grid container item xs={12} style={{ height: 15 }}>
                            <Grid
                                item xs={2} md={3} xl={2} className="whiteText"
                                style={{
                                    color: "black",
                                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                    fontWeight: 400,
                                    lineHeight: 1.5,
                                    letterSpacing: "0.00938em", fontSize: 16,
                                    textAlign: "center",
                                }}
                            >
                                {"Ranking"}
                            </Grid>
                            <Grid item xs={6} md={6} className="whiteText" style={{ marginLeft: 25 }}>
                                <ProgressBar value={data.ranking} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={1} style={{ alignItems: "center", marginTop: 10 }}>
                        <ProfileImg
                            image={headerData.evaluatedPhoto}
                            size={64}
                        />
                    </Grid>
                </Grid>
                {
                    (data.specificSkills.length > 0 || formData.transversalSkills.length > 0) &&
                    <>
                        <Divider style={{ margin: "10px 0px", background: color, height: 2 }} />
                        <Grid container item xs={12}>
                            <Typography className="whiteText" style={{ fontSize: 20, width: '100%' }}>
                                {"Competencias"}
                            </Typography>
                            {
                                data.specificSkills.length > 0 &&
                                <Grid container item xs={12} style={{ paddingLeft: 20 }}>
                                    <Typography className="whiteText" style={{ fontSize: 20, marginTop: 5, marginBottom: 10, width: '100%' }}>
                                        {"Competencias específicas del cargo"}
                                    </Typography>
                                    {
                                        data.specificSkills.map((el, index) => {
                                            return <Grid key={index} container item xs={12} style={{ padding: 10 }}>
                                                <Grid container item xs={12} style={{ marginBottom: 20 }}>
                                                    <TableContainer component={Paper}>
                                                        {createSkillTable(el.value, "specificSkills", el, data.skillsScale)}
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            }
                            {
                                formData.transversalSkills.length > 0 &&
                                <>
                                    <Grid container item xs={12} style={{ paddingLeft: 20 }}>
                                        <Typography className="whiteText" style={{ fontSize: 20, marginTop: 30, marginBottom: 10, width: '100%' }}>
                                            {"Competencias transversales"}
                                        </Typography>
                                        <Grid container item xs={12} style={{ padding: 10 }}>
                                            {
                                                data.transversalSkills.map((el, index) => (
                                                    <Grid container item xs={12} style={{ marginBottom: 20 }} key={index}>
                                                        <TableContainer component={Paper}>
                                                            {createSkillTable(el.value, "transversalSkills", el, data.skillsScale)}
                                                        </TableContainer>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </>
                }
                {
                    (formData.personalTargets.length > 0 || formData.organizationalTargets.length > 0) &&
                    <>
                        <Divider style={{ margin: "10px 0px", background: color, height: 2 }} />
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography className="whiteText" style={{ fontSize: 20, width: '100%' }}>
                                    {"Metas y objetivos"}
                                </Typography>
                            </Grid>
                            {formData.personalTargets.length > 0 &&
                                <Grid container item xs={12} style={{ padding: 10 }}>
                                    <Typography className="whiteText" style={{ fontSize: 18, width: '100%' }}>
                                        {"Objetivos personales"}
                                    </Typography>
                                    {
                                        formData.personalTargets.map((el, index) => {
                                            return <Grid key={index} container item xs={12} style={{ padding: '20px 10px' }}>
                                                <Grid item xs={12}>
                                                    <Typography className="whiteText" style={{ fontSize: 16, fontWeight: 600 }}>
                                                        {`${el.value}`}<p style={{ fontWeight: 800, display: "inline-block", marginLeft: 3, lineHeight: 0 }}>{data.targetsOnlyVisualization ? "" : ` (Estándar)`}</p>
                                                    </Typography>
                                                </Grid>
                                                {
                                                    !data.targetsOnlyVisualization ?
                                                        <>
                                                            <Grid container item xs={12}>
                                                                {createTargetForm("personalTargets", el, data.targetsScale, false)}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography className="whiteText" style={{ fontSize: 16, fontWeight: 600 }}>
                                                                    {`${el.value}`}<p style={{ fontWeight: 800, display: "inline-block", marginLeft: 3, lineHeight: 0 }}>{` (Calibrada)`}</p>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid container item xs={12} style={{ marginTop: 5, display: "inline-block" }}>
                                                                {createTargetForm("personalTargets", el, data.targetsScale, true)}
                                                            </Grid>
                                                            <Grid item xs={12} style={{ marginTop: 5 }}>
                                                                <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                                    {`Estado: ${el.status}`}
                                                                </Typography>
                                                                <div style={{ display: "inline-block", height: 14, width: 107, marginRight: 10 }}>
                                                                    <div style={{ display: "inline-block", height: "100%", width: `${Number(el.progress)}%`, backgroundColor: `${getProgressColor(Number(el.progress))}` }} />
                                                                    <div style={{ display: "inline-block", height: "100%", width: `${(100 - Number(el.progress))}%`, backgroundColor: "#747474" }} />
                                                                </div>
                                                                <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                                    {`${el.progress}% - Fecha de término ${el.endDate} - Fecha de progreso: ${el.progressDate} - Ponderado ${el.weight}%`}
                                                                </Typography>
                                                            </Grid>
                                                        </> :
                                                        <>
                                                            <Grid item xs={12} style={{ marginTop: 5 }}>
                                                                <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                                    {`Ponderado ${el.weight}%`}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                }
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            }
                            {
                                formData.organizationalTargets.length > 0 &&
                                <Grid container item xs={12} style={{ padding: 10 }}>
                                    <Typography className="whiteText" style={{ fontSize: 18, width: '100%' }}>
                                        {"Objetivos organizacionales"}
                                    </Typography>
                                    {
                                        formData.organizationalTargets.map((el, index) => {
                                            return <Grid key={index} container item xs={12} style={{ padding: '20px 10px' }}>
                                                <Grid item xs={12}>
                                                    <Typography className="whiteText" style={{ fontSize: 16, fontWeight: 600 }}>
                                                        {`${el.value}`}<p style={{ fontWeight: 800, display: "inline-block", marginLeft: 3, lineHeight: 0 }}>{data.targetsOnlyVisualization ? "" : ` (Estándar)`}</p>
                                                    </Typography>
                                                </Grid>
                                                {
                                                    !data.targetsOnlyVisualization ?
                                                        <>
                                                            <Grid container item xs={12}>
                                                                {createTargetForm("organizationalTargets", el, data.targetsScale, false)}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography className="whiteText" style={{ fontSize: 16, fontWeight: 600 }}>
                                                                    {`${el.value}`}<p style={{ fontWeight: 800, display: "inline-block", marginLeft: 3, lineHeight: 0 }}>{` (Calibrada)`}</p>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid container item xs={12} style={{ marginTop: 5, display: "inline-block" }}>
                                                                {createTargetForm("organizationalTargets", el, data.targetsScale, true)}
                                                            </Grid>
                                                            <Grid item xs={12} style={{ marginTop: 5 }}>
                                                                <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                                    {`Estado: ${el.status}`}
                                                                </Typography>
                                                                <div style={{ display: "inline-block", height: 14, width: 107, marginRight: 10 }}>
                                                                    <div style={{ display: "inline-block", height: "100%", width: `${Number(el.progress)}%`, backgroundColor: `${getProgressColor(Number(el.progress))}` }} />
                                                                    <div style={{ display: "inline-block", height: "100%", width: `${(100 - Number(el.progress))}%`, backgroundColor: "#747474" }} />
                                                                </div>
                                                                <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                                    {`${el.progress}% - Fecha de término ${el.endDate} - Fecha de progreso: ${el.progressDate} - Ponderado ${el.weight}%`}
                                                                </Typography>

                                                            </Grid>
                                                        </> :
                                                        <>
                                                            <Grid item xs={12} style={{ marginTop: 5 }}>
                                                                <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                                    {`Ponderado ${el.weight}%`}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                }
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            }
                        </Grid>
                    </>
                }
                {
                    data.potentialQuestions.length > 0 &&
                    <>
                        <Divider style={{ margin: "10px 0px", background: color, height: 2 }} />
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography className="whiteText" style={{ fontSize: 20, width: '100%' }}>
                                    {`Preguntas para evaluar el potencial (${data.potentialQuestions.length})`}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} style={{ padding: 10 }}>
                                {createPotentialQuestionsRepresentation(data.potentialScale)}
                            </Grid>
                        </Grid>
                    </>
                }
                <>
                    <Divider style={{ margin: "10px 0px", background: color, height: 2 }} />
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography className="whiteText" style={{ fontSize: 20, width: '100%' }}>
                                {"Comentarios generales del evaluador o del Comité de calibración"}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} style={{ padding: 10 }}>
                            <TextField
                                inputRef={newOptionRef}
                                defaultValue={data?.comments || ''}
                                fullWidth
                                variant="outlined"
                                multiline
                                rows={5}
                                margin={"none"}
                                inputProps={{ spellCheck: 'false' }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.cssOutlinedInput,
                                        focused: classes.cssFocused,
                                        notchedOutline: classes.notchedOutline,
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </>
                <>
                    <Divider style={{ margin: "10px 0px", background: color, height: 2 }} />
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography className="whiteText" style={{ fontSize: 20, width: '100%' }}>
                                {"Comentarios del evaluado"}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} style={{ padding: 10 }}>
                            <TextField
                                disabled
                                defaultValue={data?.evaluatedComments || ''}
                                fullWidth
                                variant="outlined"
                                multiline
                                rows={5}
                                margin={"none"}
                                inputProps={{ spellCheck: 'false' }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.cssOutlinedInput,
                                        focused: classes.cssFocused,
                                        notchedOutline: classes.notchedOutline,
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </>
            </Card>
        </StepContainer>
    )
}


export default withStyles(styles)(StepFive);