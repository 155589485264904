import React from 'react';
import {
    Paper,
    Grid,
    TableCell,
    TableRow,
    TableHead,
    Table,
    TableContainer,
    TableBody,
    Tooltip,
} from "@material-ui/core";
import { getProgressColor } from "@icarius-utils/colors";
import { getStatusResultBackgroundColor } from '../body';
import Score from './score';

const ResultsTable = ({ evaluations, summaryData }) => {
    const evaluationAUT = evaluations.find(el => el.type === "AUT");
    const evaluationPAR = evaluations.find(el => el.type === "PAR");
    const evaluationASC = evaluations.find(el => el.type === "ASC");
    const evaluationDES = evaluations.find(el => el.type === "DES");

    return (
        <TableContainer component={Paper} style={{ marginTop: 10 }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={4}></TableCell>
                        <TableCell align="center">Evaluación Descendente</TableCell>
                        <TableCell align="center">Autoevaluación</TableCell>
                        <TableCell align="center">Evaluación Ascendente</TableCell>
                        <TableCell align="center">Evaluación De Pares</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        summaryData.hasOwnProperty("personalTargets") && summaryData.personalTargets.length > 0 &&
                        <>
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={8} style={{ fontWeight: 700, fontStyle: "italic", fontSize: 16 }}>
                                    {"Objetivos personales"}
                                </TableCell>
                            </TableRow>
                            {
                                summaryData.personalTargets.map((row, rowIndex) => {
                                    return (
                                        <React.Fragment key={rowIndex}>
                                            <TableRow>
                                                <TableCell />
                                                <Tooltip placement="bottom-start" title={row?.description || ""}>

                                                    <TableCell component="th" scope="row" colSpan={7} style={{ fontWeight: 700, textAlign: "justify" }}>
                                                        {row.value}
                                                    </TableCell>
                                                </Tooltip>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell />
                                                <TableCell component="th" scope="row">
                                                    {`${row.status} - Progreso: `}<span style={{ fontWeight: 600, color: getProgressColor(row.progress) }}>{row.progress}%</span>{` al ${row.progressDate} - Fecha de término ${row.endDate} - Ponderado ${row.weight}%`}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row" style={getStatusResultBackgroundColor(row.statusResult)}>
                                                    {row.statusResult}
                                                </TableCell>
                                                <Score targetsWithPercentage={evaluationDES?.targetsWithPercentage} targetsOnlyVisualization={evaluationDES?.targetsOnlyVisualization} data={row.qualifications["DES"]} />
                                                <Score targetsWithPercentage={evaluationAUT?.targetsWithPercentage} targetsOnlyVisualization={evaluationAUT?.targetsOnlyVisualization} data={row.qualifications["AUT"]} />
                                                <Score targetsWithPercentage={evaluationASC?.targetsWithPercentage} targetsOnlyVisualization={evaluationASC?.targetsOnlyVisualization} data={row.qualifications["ASC"]} />
                                                <Score targetsWithPercentage={evaluationPAR?.targetsWithPercentage} targetsOnlyVisualization={evaluationPAR?.targetsOnlyVisualization} data={row.qualifications["PAR"]} />
                                            </TableRow>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </>
                    }
                    {
                        summaryData.hasOwnProperty("organizationalTargets") && summaryData.organizationalTargets.length > 0 &&
                        <>
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={8} style={{ fontWeight: 700, fontStyle: "italic", fontSize: 16 }}>
                                    {"Objetivos de la organización"}
                                </TableCell>
                            </TableRow>
                            {
                                summaryData.organizationalTargets.map((row, rowIndex) => {
                                    return (
                                        <React.Fragment key={rowIndex}>
                                            <TableRow>
                                                <TableCell />
                                                <Tooltip placement="bottom-start" title={row?.description || ""}>
                                                    <TableCell component="th" scope="row" colSpan={7} style={{ fontWeight: 700 }}>
                                                        {row.value}
                                                    </TableCell>
                                                </Tooltip>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell />
                                                <TableCell component="th" scope="row">
                                                    {`${row.status} - Progreso: `}<span style={{ fontWeight: 600, color: getProgressColor(row.progress) }}>{row.progress}%</span>{` al ${row.progressDate} - Fecha de término ${row.endDate} - Ponderado ${row.weight}%`}
                                                </TableCell>
                                                <TableCell align="center" component="th" scope="row" style={getStatusResultBackgroundColor(row.statusResult)}>
                                                    {row.statusResult}
                                                </TableCell>
                                                <Score targetsWithPercentage={evaluationDES?.targetsWithPercentage} targetsOnlyVisualization={evaluationDES?.targetsOnlyVisualization} data={row.qualifications["DES"]} />
                                                <Score targetsWithPercentage={evaluationAUT?.targetsWithPercentage} targetsOnlyVisualization={evaluationAUT?.targetsOnlyVisualization} data={row.qualifications["AUT"]} />
                                                <Score targetsWithPercentage={evaluationASC?.targetsWithPercentage} targetsOnlyVisualization={evaluationASC?.targetsOnlyVisualization} data={row.qualifications["ASC"]} />
                                                <Score targetsWithPercentage={evaluationPAR?.targetsWithPercentage} targetsOnlyVisualization={evaluationPAR?.targetsOnlyVisualization} data={row.qualifications["PAR"]} />
                                            </TableRow>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const TargetsTable = ({ evaluations, summaryData }) => {

    return (
        <Grid container alignItems="center" style={{ padding: '0px 50px 0px 0px', marginTop: -5, marginBottom: 10 }}>
            <ResultsTable evaluations={evaluations} summaryData={summaryData} />
        </Grid>
    )
}

export default TargetsTable;