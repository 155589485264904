import React, { useState, useMemo } from "react";
import CommonPage from "@icarius-common/commonPage";
import {
	numberComparatorMax2,
	numberFormatterMin1Max1,
	numberFilterParams,
} from "@icarius-table/number";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import Container from "./container";

const BySkillsGridPage = ({ data }) => {

  const [showDescription, setShowDescription] = useState(false);

  const gridData = useMemo(() => {
    return data.bySkills.map((item) => {
      const auxRow = { ...item };

      item.collaboratorsValue.forEach((collaboratorValue) => {
        auxRow[collaboratorValue.key] = collaboratorValue.value || null;
        auxRow[`${collaboratorValue.key}Description`] = collaboratorValue.stringValue || null;
      })

      return auxRow;
    })
  }, [data.bySkills]);

  const ownColumnDef = useMemo(() => {
    let columnDefinitions = getColumnDefByPage('performanceEvaluationsCalibrationsBySkills');
    const dynamicColumns = [];

    const sourceData = data.bySkills;

    if (sourceData.length > 0) {
      sourceData[0].collaboratorsValue.forEach(({ key }) => {
        let itemNumeric = {
          headerName: key,
          field: key,
          filter: "agTextColumnFilter",
          comparator: numberComparatorMax2,
          filterParams: numberFilterParams,
          chartDataType: "series",
          valueFormatter: numberFormatterMin1Max1,
          enableValue: true,
          allowedAggFuncs: ["sum", "min", "max", "avg"],
        }

        let itemDescription = {
          headerName: key,
          field: `${key}Description`,
          filter: "agSetColumnFilter",
        }


        dynamicColumns.push(showDescription ? itemDescription : itemNumeric);
      });
    }

    return [...columnDefinitions, ...dynamicColumns];
  }, [data.bySkills, showDescription]);

  const title = 'Por competencias y evaluados';

  return (
    <Container title={title}>
      <CommonPage
        gridTitle={title}
        ownColumnDef={ownColumnDef}
        rowData={gridData}
        hasExpand
        hasHelp
        hasSwitch
        handleSwitchClick={() => setShowDescription(prev => !prev)}
        switchLabelOff={'Calificaciones numéricas'}
        switchLabelOn={'Calificaciones descriptivas'}
        switchValue={showDescription}
        ignoreProcessesbyPath
      />
    </Container>
  )
}

export default BySkillsGridPage;