import * as actionTypes from "./actionTypes";

const initialState = {
  resumeData: [],
  initialData: [],
  data: {},
  isLoading: false,
  sendingMail: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_RESUME:
      return { ...state, isLoading: true };
    case actionTypes.GET_RESUME_FULFILLED:
      return {
        ...state,
        isLoading: false,
        resumeData: action.payload.data,
        initialData: [],
      };
    case actionTypes.GET_RESUME_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_INITIAL:
      return { ...state, isLoading: true };
    case actionTypes.GET_INITIAL_FULFILLED:
      return {
        ...state,
        isLoading: false,
        initialData: action.payload.data,
      };
    case actionTypes.GET_INITIAL_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        initialData: action.payload.data,
      };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.SEND_MAIL: {
      return { ...state, sendingMail: true };
    }
    case actionTypes.SEND_MAIL_FULFILLED: {
      return { ...state, sendingMail: false };
    }
    case actionTypes.SEND_MAIL_REJECTED: {
      return { ...state, sendingMail: false };
    }


    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
