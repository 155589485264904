import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import CommonPage from "@icarius-common/commonPage";
import Row from "./row";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const CURRENT_STATUS = ["E", "F", "B", "K", "T"];
const HISTORIC_STATUS = ["R"];

const ProcessListContainer = ({ title, data, handleClick }) => {

    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography className="whiteText" style={{ fontSize: 24, fontWeight: 600 }}>
                    {title}
                </Typography>
            </Grid>
            {
                data?.map((item, index) => {
                    return (
                        <Row
                            key={index}
                            data={item}
                            handleClick={handleClick}
                        />
                    )
                })}
        </Grid>
    )
}

const InitialScreen = (props) => {

    const {
        isLoading,
        data,
        handleSelectProcess,
    } = props;

    const color = useSelector(getAppColor);

    const currentProcesses = data?.filter((item) => CURRENT_STATUS.includes(item.processStatus));
    const hasCurrentProcesses = currentProcesses.length > 0;

    const historicProcesses = data?.filter((item) => HISTORIC_STATUS.includes(item.processStatus));
    const hasHistoricProcesses = historicProcesses.length > 0;

    return (
        <CommonPage
            isNotGridPage
            isLoading={isLoading}
            title={"Resumen y resultados"}
        >
            <div style={{ maxWidth: 1280, margin: '0 auto', padding: 24, paddingTop: 0 }}>
                {
                    !isLoading && !data?.length &&
                    <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
                        <Typography className="whiteText" variant="h2" style={{ fontSize: 28, marginBottom: 16, maxWidth: 1280, width: '85%' }}>
                            En este momento no hay evaluaciones del desempeño disponibles para ver el resumen y sus resultados (Si hay un proceso activo, debes esperar a que las evaluaciones sean publicadas).
                        </Typography>
                    </Grid>
                }
                {
                    hasCurrentProcesses &&
                    <ProcessListContainer
                        title={"Actuales"}
                        data={currentProcesses}
                        handleClick={handleSelectProcess}
                    />
                }
                {
                    hasCurrentProcesses && hasHistoricProcesses &&
                    <div style={{ width: "100%", marginBottom: 12 }}>
                        <Divider style={{ margin: "5px 0px", background: color, height: 2 }} />
                    </div>
                }
                {
                    hasHistoricProcesses &&
                    <ProcessListContainer
                        title={"Históricas"}
                        data={historicProcesses}
                        handleClick={handleSelectProcess}
                    />
                }
            </div>
        </CommonPage>
    );
}

export default InitialScreen;