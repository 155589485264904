import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Stepper, Step, StepButton, } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createPerformanceFormsAction, updatePerformanceFormsAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import useHandleStepper from "@icarius-utils/hooks/useHandleStepper";
import { getAppColor } from "src/app/selectors";
import StepOne from "./steps/stepOne";
import StepTwo from "./steps/stepTwo";
import StepThree from "./steps/stepThree";
import StepFour from "./steps/stepFour";
import StepFive from "./steps/stepFive/index";

const Form = (props) => {
  const {
    allRows,
    data,
    typeList,
    scaleList,
    transversalSkillsList,
    evaluationGroupsList,
    handleClose,
    handleOpenScaleWarningDialog,
  } = props;
  const dispatch = useDispatch();
  const color = useSelector(getAppColor);

  const create = (dataToSend) => {
    const modifiedDataToSend = {
      ...dataToSend,
      evaluateSkills: dataToSend.evaluateSkills === "" ? false : dataToSend.evaluateSkills,
      evaluateTargets: dataToSend.evaluateTargets === "" ? false : dataToSend.evaluateTargets,
    }

    dispatch(createPerformanceFormsAction(modifiedDataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    const modifiedDataToSend = {
      ...dataToSend,
      evaluateSkills: dataToSend.evaluateSkills === "" ? false : dataToSend.evaluateSkills,
      evaluateTargets: dataToSend.evaluateTargets === "" ? false : dataToSend.evaluateTargets,
    }

    dispatch(updatePerformanceFormsAction(modifiedDataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
    stepOneIsValid,
    stepTwoIsValid,
    stepThreeIsValid,
    stepFourIsValid,
  } = useHandleForm(allRows, data, scaleList, transversalSkillsList, create, modify, openValidationError, handleOpenScaleWarningDialog);

  const steps = [
    {
      name: 'Identificación del formulario',
      render: () => {
        return <StepOne
          isCreate={isCreate}
          formData={formData}
          typeList={typeList}
          setFormValue={setFormValue}
          evaluationGroupsList={evaluationGroupsList}
        />
      }
    },
    {
      name: 'Tipo de evaluaciones y sus ponderados',
      render: () => {
        return <StepTwo
          formData={formData}
          setFormValue={setFormValue}
          scaleList={scaleList}
          handleOpenScaleWarningDialog={handleOpenScaleWarningDialog}
        />
      }
    },
    {
      name: 'Detalle de las competencias transversales y sus ponderados',
      render: () => {
        return <StepThree
          formData={formData}
          setFormValue={setFormValue}
        />
      },
    },
    {
      name: 'Potencial',
      render: () => {
        return <StepFour
          formData={formData}
          setFormValue={setFormValue}
          scaleList={scaleList}
          color={color}
        />
      },
    },
    {
      name: 'Resumen y visualización',
      render: () => {
        return <StepFive
          formData={formData}
          evaluationGroupsList={evaluationGroupsList}
          typeList={typeList}
          scaleList={scaleList}
          color={color}
        />
      }
    },
  ];

  const {
    currentStep,
    setCurrentStep,
    setNextStep,
    setPreviousStep,
    renderStep,
  } = useHandleStepper(steps);

  const handleSetNextStep = async () => {
    let isValid = await [stepOneIsValid, stepTwoIsValid, stepThreeIsValid, stepFourIsValid][currentStep]();
    if (isValid) {
      setNextStep();
    }
  }

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12} justify="center">
          <Stepper activeStep={currentStep} alternativeLabel style={{ width: '100%' }}>
            {
              steps.map((step, index) => {
                return (
                  <Step key={index}>
                    <StepButton onClick={() => index < currentStep && setCurrentStep(index)}>{step.name}</StepButton>
                  </Step>
                );
              })
            }
          </Stepper>
        </Grid>
        <Grid container item xs={12}>
          {renderStep()}
        </Grid>
      </Grid>
      <Grid container item sm={12} justify="center">
        <Grid container item sm={12} md={10} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20, paddingTop: 10, paddingRight: 10 }}>
          {
            (currentStep !== 0) &&
            <div style={{ marginRight: 10 }}>
              <ButtonDialogAction isAccept onClick={setPreviousStep} text={'Anterior'} />
            </div>
          }
          {
            (currentStep === steps.length - 1)
              ? <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
              : <ButtonDialogAction isAccept onClick={handleSetNextStep} text={'Siguiente'} />
          }
        </Grid>
      </Grid>
    </>
  );
}



export default Form;
