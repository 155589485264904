import React from "react";
import { useDispatch } from "react-redux";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
} from "@material-ui/core";
import { CloseIcon } from "@icarius-icons/index";
import { withStyles } from "@material-ui/core/styles";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { getLocalizedString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { acceptRejectPerformanceSummaryAndResultsAction } from "@icarius-pages/performanceSummaryAndResults/actions";
import { formatNumberExactDecimals } from "@icarius-utils/format";
import useHandleForm from "./useHandleForm";

const styles = () => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    cssOutlinedInput: {
        color: "var(--mainText) !important",
        '&$cssFocused $notchedOutline': {
            color: "var(--mainText) !important",
        }
    },

    cssFocused: {
        color: "var(--mainText) !important",
    },

    notchedOutline: {
        borderColor: 'var(--icons) !important',
        color: "var(--mainText) !important",
    },
});

const AcceptRejectDialog = (props) => {

    const {
        open,
        data,
        processData,
        classes,
        handleClose,
    } = props;

    const dispatch = useDispatch();

    const create = (dataToSend) => {
        dispatch(acceptRejectPerformanceSummaryAndResultsAction({
            ...dataToSend,
            process: processData.process,
            evaluatedCode: data.evaluatedCode,
        }));
        handleClose();
    }

    const openValidationError = () => {
        dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
    }

    const {
        formData,
        setFormValue,
        submit,
    } = useHandleForm(data, create, openValidationError);

    return (
        <Dialog
            open={open}
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <div className={"dialog-container"}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {'Aceptar u objetar la evaluación del desempeño'}
                    <DialogTitleDivider />
                </DialogTitle>
                <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
                <DialogContent style={{ paddingTop: 0 }}>
                    <Typography className="whiteText" align="center" style={{ fontSize: 20, fontWeight: 600, paddingBottom: 10 }}>
                        {processData.processName}
                    </Typography>
                    <Grid container item xs={12} spacing={1}>
                        <Grid container item xs={12}>
                            <Typography className="whiteText">
                                Nota: <b>{data.note.toFixed(1)}</b>
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Typography className="whiteText">
                                Nombre de la nota: <b>{data.noteName}</b>
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Typography className="whiteText">
                                Ranking: <b>{formatNumberExactDecimals(data.ranking * 100, 2)}%</b>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item alignItems="center" xs={12} style={{ padding: '20px 0px' }}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel required id={'label-approves'}>{'¿Acepta la evaluación de desempeño?'}</InputLabel>
                            <Select
                                value={formData.approves}
                                labelId={'label-approves'}
                                id={'select-approves'}
                                onChange={(e) => setFormValue(e.target.value, "approves")}
                                margin={"none"}
                            >
                                {
                                    [
                                        { key: "Y", value: 'Si' },
                                        { key: "N", value: 'No' },
                                    ].map(item => (
                                        <MenuItem
                                            className={"whiteText"}
                                            key={item.key}
                                            value={item.key}
                                        >
                                            {item.value}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        formData.approves === 'N' &&
                        <Grid container item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label="Ingrese aquí sus comentarios"
                                variant="outlined"
                                multiline
                                rows={5}
                                margin={"none"}
                                value={formData.comment}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.cssOutlinedInput,
                                        focused: classes.cssFocused,
                                        notchedOutline: classes.notchedOutline,
                                    }
                                }}
                                inputProps={{ spellCheck: 'false' }}
                                onChange={(e) => setFormValue(e.target.value, "comment")}
                            />
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
                    <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default withStyles(styles)(AcceptRejectDialog);