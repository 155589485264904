import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin0Max1,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.performanceSummaryAndResults],
        config: {
            headerName: 'Código del evaluado',
            field: 'evaluatedCode',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.performanceSummaryAndResults],
        config: {
            headerName: 'Apellido y nombre del evaluado',
            field: 'evaluatedName',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.performanceSummaryAndResults],
        config: {
            headerName: 'Estado de la evaluación',
            field: 'status',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceSummaryAndResults],
        config: {
            headerName: 'Año',
            field: 'year',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceSummaryAndResults],
        config: {
            headerName: 'Período',
            field: 'period',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceSummaryAndResults],
        config: {
            headerName: 'Estado de la nota',
            field: 'noteStatus',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceSummaryAndResults],
        config: {
            headerName: 'Nota',
            field: 'note',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max1,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            cellRenderer: "ProgressByRankingRendererDynamicColor",
        }
    },
    {
        pages: [paths.performanceSummaryAndResults],
        config: {
            headerName: 'Nombre de la nota',
            field: 'noteName',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceSummaryAndResults],
        config: {
            headerName: 'Ranking',
            field: 'ranking',
            cellRenderer: "ProgressRenderer",
            filter: "ProgressFilter",
            cellClass: "percentageValue",
        }
    },
    {
        pages: [paths.performanceSummaryAndResults],
        config: {
            headerName: 'Feedback entregado',
            field: 'feedback',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceSummaryAndResults],
        config: {
            headerName: 'Fecha del feedback',
            field: 'feedbackDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.performanceSummaryAndResults],
        config: {
            headerName: 'Responsable del feedback',
            field: 'feedbackResponsible',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.performanceSummaryAndResults],
        config: {
            headerName: 'Aceptada por el evaluado',
            field: 'evaluatedAcceptation',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceSummaryAndResults],
        config: {
            headerName: 'Comentarios del evaluado',
            field: 'evaluatedComments',
            filter: "agTextColumnFilter",
        }
    },
]