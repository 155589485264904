import * as actionTypes from "./actionTypes";
import {
  getResumePerformanceEvaluationsAPI,
  getInitialPerformanceEvaluationsAPI,
  getPerformanceEvaluationsAPI,
  createPerformanceEvaluationsAPI,
  sendMessageAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'Ya existe un formulario de desempeño con el mismo código, tipo y grupo de cargos a evaluar';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}


export const getPerformanceEvaluationsResumeAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_RESUME });
  try {
    let response = await getResumePerformanceEvaluationsAPI();

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_RESUME_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_RESUME_FULFILLED,
      payload: {
        data,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_RESUME_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getPerformanceEvaluationsInitialAction = (process, employeerView) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_INITIAL });
  try {
    let params = new URLSearchParams();
    params.set("process", process);
    params.set("employeerView", employeerView);

    let response = await getInitialPerformanceEvaluationsAPI(`?${params.toString()}`);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_INITIAL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_INITIAL_FULFILLED,
      payload: {
        data,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getPerformanceEvaluationsAction = ({ code, type, group, evaluatedCode, evaluatedName, process, photo }) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });

  try {
    let params = new URLSearchParams();
    params.set("code", code);
    params.set("type", type);
    params.set("group", group);
    params.set("evaluatedCode", evaluatedCode);
    params.set("process", process);

    let response = await getPerformanceEvaluationsAPI(`?${params.toString()}`);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data: {
          ...data, process, evaluatedName, photo
        },
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createPerformanceEvaluationsAction = ({ dataToSend, process, evaluatedName, photo }) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createPerformanceEvaluationsAPI(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: {
        data
      },
    });

    dispatch(openSnackbarAction({ msg: 'Formulario de desempeño creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const sendMailAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.SEND_MAIL });
  try {
    let response = await sendMessageAPI(data);

    dispatch({ type: actionTypes.SEND_MAIL_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("mailMyPeopleSent"), severity: "success" }));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.SEND_MAIL_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};