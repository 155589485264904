import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET_INITIAL = NAME + "/GET_INITIAL";
export const GET_INITIAL_REJECTED = NAME + "/GET_INITIAL_REJECTED";
export const GET_INITIAL_FULFILLED = NAME + "/GET_INITIAL_FULFILLED";

export const GET_GRAPHICS = NAME + "/GET_GRAPHICS";
export const GET_GRAPHICS_REJECTED = NAME + "/GET_GRAPHICS_REJECTED";
export const GET_GRAPHICS_FULFILLED = NAME + "/GET_GRAPHICS_FULFILLED";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const GET_FORM = NAME + "/GET_FORM";
export const GET_FORM_REJECTED = NAME + "/GET_FORM_REJECTED";
export const GET_FORM_FULFILLED = NAME + "/GET_FORM_FULFILLED";

export const CREATE = NAME + "/CREATE";
export const CREATE_REJECTED = NAME + "/CREATE_REJECTED";
export const CREATE_FULFILLED = NAME + "/CREATE_FULFILLED";

export const SEND_MAIL = NAME + "/SEND_MAIL";
export const SEND_MAIL_REJECTED = NAME + "/SEND_MAIL_REJECTED";
export const SEND_MAIL_FULFILLED = NAME + "/SEND_MAIL_FULFILLED";

export const UPDATE_SKILLS = NAME + "/UPDATE_SKILLS";
export const UPDATE_SKILLS_REJECTED = NAME + "/UPDATE_SKILLS_REJECTED";
export const UPDATE_SKILLS_FULFILLED = NAME + "/UPDATE_SKILLS_FULFILLED";
