import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import Form from "./form";

const CreateEditForm = (props) => {

  const {
    allRows,
    handleOpenScaleWarningDialog,
    isLoading,
    data,
    typeList,
    scaleList,
    transversalSkillsList,
    evaluationGroupsList,
    handleClose,
  } = props;

  const isEdit = Boolean(data);

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => handleClose(false)}
      type={"goBack"}
    />

  return (
    <CommonPage
      title={`${isEdit ? 'Editar' : 'Crear'} formulario de desempeño`}
      menuItems={[goBackItem]}
      isLoading={isLoading}
      isNotGridPage
    >
      <Form
        allRows={allRows}
        handleOpenScaleWarningDialog={handleOpenScaleWarningDialog}
        data={data}
        typeList={typeList}
        scaleList={scaleList}
        transversalSkillsList={transversalSkillsList}
        evaluationGroupsList={evaluationGroupsList}
        handleClose={handleClose}
      />
    </CommonPage>
  )
}
export default CreateEditForm;
