import React from "react";
import { useSelector } from "react-redux";
import {
    Grid,
    FormControl,
    Typography,
    Tooltip,
    Radio,
    Divider,
    FormControlLabel,
    RadioGroup,
    Card,
    Table,
    Paper,
    TableCell,
    TableContainer,
    TableBody,
    Slider,
    TableRow,
    TableHead,
} from "@material-ui/core";
import StarIcon from '@material-ui/icons/Star';
import { withStyles } from "@material-ui/core/styles";
import { getTheme } from "@icarius-pages/login/selectors";

const CustomFormControlLabel = withStyles({
    root: {
        margin: 10,
    },
    label: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
})(FormControlLabel);

const CustomFormControlLabelDisplayBlock = withStyles({
    root: {
        margin: 10,
        display: "block",
    },
    label: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
})(FormControlLabel);


const StepContainer = ({ title, children }) => {
    return (
        <Grid container item xs={12} justify="center">
            <Grid container item sm={12} md={10}>
                <Typography className="whiteText" style={{ fontSize: 20, width: '100%', padding: '0px 10px' }}>
                    {title}
                </Typography>
                <Grid container item alignItems="center" justify="space-between" xs={12} style={{ paddingTop: 10 }}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

const StepFive = (props) => {
    const {
        formData,
        typeList,
        color,
        evaluationGroupsList,
        scaleList,
    } = props;

    const theme = useSelector(getTheme);


    const getElementsForSkillTable = (name, selectedElement) => {
        if (name === "Competencia específica 1 del cargo") {
            return ["Indicador 1.1", "Indicador 1.2", "Indicador 1.3"];
        }
        if (name === "Competencia específica 2 del cargo") {
            return ["Indicador 2.1", "Indicador 2.2", "Indicador 2.3"];
        }
        if (selectedElement) {
            return selectedElement.indicators.map(el => el.value);
        }

        return [];
    }

    const createSkillTable = (name, data, selectedElement) => {
        return <Table key={name} aria-label="simple table" >
            <TableHead>
                <TableRow style={{ verticalAlign: "initial" }}>
                    <Tooltip arrow title={selectedElement?.description || ""}><TableCell>{name}</TableCell></Tooltip>
                    {
                        getSkillsScaleHeader(formData.skillsScale)
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    getElementsForSkillTable(name, selectedElement).map((el, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row" style={{ width: "400px", textAlign: "justify" }}>
                                {el}
                            </TableCell>
                            {
                                scaleList.find(el => el.code === data.skillsScale)?.details?.map((el, index) => (<TableCell key={index} align="center"><Radio color='primary' checked={false} /></TableCell>))
                            }
                        </TableRow>
                    ))}
            </TableBody>
        </Table >
    }

    const getSkillsScaleHeader = (scaleCode) => {
        const scale = scaleList.find(el => el.code === scaleCode);
        if (scale) {
            switch (scale.type) {
                case 'E': // Estrellas
                    return scale.details?.map((el, index) => (<Tooltip arrow title={el.criterion || ""}><TableCell key={index} align="center">{Array(parseInt(index + 1) + 1).join("⭐")}</TableCell></Tooltip>))
                case 'C': // Criterios
                    return scale.details?.map((el, index) => (<TableCell key={index} align="center" style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        backgroundColor: scale.useColor ? el.color : "transparent"
                    }}>
                        <p>{el.name}</p>
                        <p style={{
                            paddingTop: 10,
                            textAlign: "center",
                            borderTop: `1px solid black`,
                        }
                        }>
                            {el.criterion}
                        </p>
                    </TableCell>))
                case 'N': // Nombres
                    return scale.details?.map((el, index) => (<Tooltip arrow title={el.criterion || ""}><TableCell key={index} align="center" style={scale.useColor ? { backgroundColor: el.color, color: "black" } : {}}>{el.name}</TableCell></Tooltip>))
                case 'V': // Valores
                    return scale.details?.map((el, index) => (<Tooltip arrow title={el.criterion || ""}><TableCell key={index} align="center" style={scale.useColor ? { backgroundColor: el.color, color: "black" } : {}}>{el.value}</TableCell></Tooltip>))
                default:
                    return null;
            }
        }
        return null;
    }

    const createPotentialQuestionsRepresentation = () => {
        let child;
        const scale = scaleList.find(el => el.code === formData.potentialScale);

        // Filtro duplicados y ordeno alfabeticamente
        const sections = [...new Set(formData.potentialQuestions.map(el => el.section))].sort((a, b) => a.localeCompare(b));

        if (sections.includes("")) {
            sections.push(sections.shift());
        }

        child = sections.map(section => {
            const innerElements = formData.potentialQuestions.filter(el => section === el.section).sort((a, b) => a.number - b.number).map((el, index) => (
                <Grid container item xs={12}>
                    <Typography className="whiteText" style={{ fontSize: 16, marginLeft: 5, marginRight: 10, fontWeight: 600 }}>
                        {`${(index + 1)}. ${el.question}`}
                    </Typography>
                    <Grid container item xs={12} style={{ padding: "10px 0px" }}>
                        {
                            (['E', 'N', 'V'].includes(scale.type)) ?
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {
                                                getSkillsScaleHeader(formData.potentialScale)
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            {scale.details?.map((el, index) => (<TableCell key={index} align="center"><Radio color='primary' checked={false} /></TableCell>))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                :
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="position" name="position" defaultValue="top" value={""}>
                                        {
                                            scale.details?.map((el, index) => (
                                                <CustomFormControlLabelDisplayBlock
                                                    key={index}
                                                    disabled
                                                    value="top"
                                                    control={
                                                        <div style={{
                                                            height: "100%",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            borderRadius: 10,
                                                            padding: 10,
                                                            whiteSpace: "normal",
                                                            wordWrap: "break-word",
                                                            backgroundColor: scale.useColor ? el.color : "transparent"
                                                        }}>

                                                            <Radio />
                                                            <p>{el.name}</p>
                                                            <p style={{
                                                                paddingTop: 10,
                                                                marginTop: -5,
                                                                width: 200,
                                                                wordBreak: "break-word",
                                                                textAlign: "center",
                                                                borderTop: `1px solid black`,
                                                            }
                                                            }>
                                                                {el.criterion}
                                                            </p>
                                                        </div>
                                                    }
                                                />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                        }
                    </Grid>
                </Grid>
            ))

            return <Grid container item xs={12} style={{ paddingLeft: 20 }}>
                <Typography className="whiteText" style={{ fontSize: 20, marginTop: 5, marginBottom: 10, width: '100%' }}>
                    {section}
                </Typography>
                <Grid container item xs={12} style={{ paddingLeft: 10 }}>
                    {innerElements}
                </Grid>
            </Grid>
        });


        return <Grid item xs={12}>
            {child}
        </Grid>;
    }

    const createTargetForm = () => {
        if (formData.targetsWithPercentage) {
            return <Grid container item alignItems="center" xs={12} md={5} style={{ height: '' }}>
                <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {"Porcentaje de calificación"}
                </Typography>
                <Typography className="whiteText" variant="subtitle1" align="center" style={{ marginLeft: 20 }}>
                    {`${70}%`}
                </Typography>
                <Slider
                    style={{ width: "50%", marginLeft: 20 }}
                    disabled={true}
                    min={0}
                    max={100}
                    step={1}
                    defaultValue={0}
                    value={70}
                />
            </Grid>
        } else {
            const scale = scaleList.find(el => el.code === formData.targetsScale);
            if (scale) {
                switch (scale.type) {
                    case 'E': // Estrellas
                        // Reviso el último valor
                        const detailsLength = scale?.details.length || 0;
                        if (detailsLength > 0) {
                            const max = scale.details[detailsLength - 1].value;
                            if (max > 5) {
                                return <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {
                                                scale.details?.map((el, index) => (<Tooltip arrow title={el.criterion || ""}><TableCell key={index} align="center">{Array(parseInt(index + 1) + 1).join("⭐")}</TableCell></Tooltip>))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            <TableRow >
                                                {
                                                    scale.details?.map((el, index) => (<TableCell key={index} align="center"><Radio color='primary' checked={false} /></TableCell>))
                                                }
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>

                            }

                            return <Grid item xs={12}>
                                {
                                    [...Array(max)].map((number, index) => {
                                        const element = scale.details.find(el => el.value === (index + 1));
                                        if (element) {
                                            return (
                                                <Tooltip arrow title={element.criterion || ""}>
                                                    <div style={{ marginRight: 10, display: "inline-block" }}>
                                                        <StarIcon style={{ fill: "var(--starColor)" }} />
                                                    </div>
                                                </Tooltip>
                                            )
                                        }

                                        return (
                                            <div style={{ marginRight: 10, display: "inline-block" }}>
                                                <StarIcon style={{ fill: "var(--starColor)" }} />
                                            </div>
                                        )
                                    })
                                }
                            </Grid >
                        }
                        return null;
                    case 'C': // Criterios
                        return <FormControl component="fieldset">
                            <RadioGroup row aria-label="position" name="position" defaultValue="top" value={""}>
                                {
                                    scale.details?.map((el, index) => (
                                        <CustomFormControlLabelDisplayBlock
                                            key={index}
                                            disabled
                                            value="top"
                                            control={
                                                <div style={{
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    borderRadius: 10,
                                                    padding: 10,
                                                    whiteSpace: "normal",
                                                    wordWrap: "break-word",
                                                    backgroundColor: scale.useColor ? el.color : "transparent"
                                                }}>

                                                    <Radio />
                                                    <p>{el.name}</p>
                                                    <p style={{
                                                        paddingTop: 10,
                                                        marginTop: -5,
                                                        width: 200,
                                                        wordBreak: "break-word",
                                                        textAlign: "center",
                                                        borderTop: `1px solid black`,
                                                    }
                                                    }>
                                                        {el.criterion}
                                                    </p>
                                                </div>
                                            }
                                        />
                                    ))
                                }
                            </RadioGroup>
                        </FormControl>
                    case 'N': // Nombres
                    case 'V': // Valores
                        return <FormControl component="fieldset">
                            <RadioGroup row aria-label="position" name="position" defaultValue="top" value={""}>
                                {
                                    scale.details?.map((el, index) => (
                                        <CustomFormControlLabel
                                            key={index}
                                            disabled
                                            value="top"
                                            control={<Radio />}
                                            label={scale.type === "N" ? el.name : el.value}
                                            labelPlacement="top"
                                        />
                                    ))
                                }
                            </RadioGroup>
                        </FormControl>
                    default:
                        return null;
                }
            }
        }
        return null;
    }

    return (
        <StepContainer title={'Revise el siguiente resumen del formulario'}>
            <Grid container item xs={12} style={{ padding: "0px 10px" }}>
                <Grid item xs={12}>
                    <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                        {"Para realizar cualquier cambio, vuelva al paso adecuado"}
                    </Typography>
                </Grid>
            </Grid>
            <Card style={{ padding: 20, marginLeft: 10, paddingTop: 10, width: "100%" }}>
                <Grid item xs={12} style={{ backgroundColor: "var(--medalTableHeader)", marginBottom: 10 }}>
                    <Typography style={{ display: "inline-block", color: "white", backgroundColor: "#2f2f2f", width: "100%", padding: 10 }}>
                        {`${formData.code} - ${formData.name} - ${typeList.find(el => el.key === formData.type)?.value} - ${evaluationGroupsList.find(el => el.key === formData.group)?.value}`}
                    </Typography>
                </Grid>
                {(formData.skillsWeight > 0 || formData.targetsWeight > 0) &&
                    <>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography className="whiteText" style={{ fontSize: 20, width: '100%' }}>
                                    {"Ponderados"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    formData.skillsWeight > 0 &&
                                    <div style={{ display: "inline-block", padding: 10, textAlign: "center", color: "white", backgroundColor: "#022975", width: `${formData.skillsWeight}%` }}>
                                        {`${formData.skillsWeight}%`}
                                    </div>
                                }
                                {
                                    formData.targetsWeight > 0 &&
                                    <div style={{ display: "inline-block", padding: 10, textAlign: "center", color: "white", backgroundColor: "#003740", width: `${formData.targetsWeight}%` }}>
                                        {`${formData.targetsWeight}%`}
                                    </div>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex" }}>
                            {
                                formData.skillsWeight > 0 &&
                                <div style={{ display: "inline-block", width: `${formData.skillsWeight}%` }}>
                                    {
                                        formData.specificSkillsWeight > 0 &&
                                        <div style={{ display: "inline-block", padding: 10, textAlign: "center", color: "white", backgroundColor: "#3B0275", width: `${formData.specificSkillsWeight}%` }}>
                                            {`${formData.specificSkillsWeight}%`}
                                        </div>
                                    }
                                    {
                                        formData.transversalSkillsWeight > 0 &&
                                        <div style={{ display: "inline-block", padding: 10, textAlign: "center", color: "white", backgroundColor: "#570275", width: `${formData.transversalSkillsWeight}%` }}>
                                            {`${formData.transversalSkillsWeight}%`}
                                        </div>
                                    }
                                </div>
                            }
                            {
                                formData.targetsWeight > 0 &&
                                <div style={{ display: "inline-block", width: `${formData.targetsWeight}%` }}>

                                    {
                                        formData.personalTargetsWeight > 0 &&
                                        <div style={{ display: "inline-block", padding: 10, textAlign: "center", color: "white", backgroundColor: "#00505D", width: `${formData.personalTargetsWeight}%` }}>
                                            {`${formData.personalTargetsWeight}%`}
                                        </div>
                                    }
                                    {
                                        formData.organizationalTargetsWeight > 0 &&
                                        <div style={{ display: "inline-block", padding: 10, textAlign: "center", color: "white", backgroundColor: "#006273", width: `${formData.organizationalTargetsWeight}%` }}>
                                            {`${formData.organizationalTargetsWeight}%`}
                                        </div>
                                    }
                                </div>
                            }
                        </Grid>
                        <Grid container item xs={12} style={{ display: "flex", marginTop: 10 }}>
                            {
                                formData.skillsWeight > 0 &&
                                <Grid container item xs={6} style={{ display: "flex" }}>
                                    <div style={{ width: 70, height: 30, display: "inline-block", backgroundColor: "#022975" }} />
                                    <Typography className="whiteText" variant="subtitle1" style={{ marginLeft: 5, marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                        {"Ponderado competencias"}
                                    </Typography>
                                </Grid>
                            }
                            {
                                formData.targetsWeight > 0 &&
                                <Grid container item xs={6} style={{ display: "flex" }}>
                                    <div style={{ width: 70, height: 30, backgroundColor: "#003740" }} />
                                    <Typography className="whiteText" variant="subtitle1" style={{ marginLeft: 5, marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                        {"Ponderado de objetivos"}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                        <Grid container item xs={12} style={{ display: "flex", margin: "10px 0px 20px 0px" }}>
                            {
                                (formData.specificSkillsWeight > 0 || formData.transversalSkillsWeight > 0) &&
                                <Grid container item xs={12} md={6}>
                                    {
                                        formData.specificSkillsWeight > 0 &&
                                        <Grid item xs={6} style={{ display: "flex" }}>
                                            <div style={{ width: 70, height: 30, backgroundColor: "#3B0275" }} />
                                            <Typography className="whiteText" variant="subtitle1" style={{ marginLeft: 5, marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                {"Específicas"}
                                            </Typography>
                                        </Grid>
                                    }
                                    {
                                        formData.transversalSkillsWeight > 0 &&
                                        <Grid container item xs={6} style={{ display: "flex" }}>
                                            <div style={{ width: 70, height: 30, backgroundColor: "#570275" }} />
                                            <Typography className="whiteText" variant="subtitle1" style={{ marginLeft: 5, marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                {"Transversales"}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            }
                            {
                                (formData.organizationalTargetsWeight > 0 || formData.personalTargetsWeight > 0) &&
                                <Grid container item xs={12} md={6}>
                                    {
                                        formData.personalTargetsWeight > 0 &&
                                        <Grid container item xs={6} style={{ display: "flex" }}>
                                            <div style={{ width: 70, height: 30, backgroundColor: "#00505D" }} />
                                            <Typography className="whiteText" variant="subtitle1" style={{ marginLeft: 5, marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                {"Personales"}
                                            </Typography>
                                        </Grid>
                                    }
                                    {
                                        formData.organizationalTargetsWeight > 0 &&
                                        <Grid container item xs={6} style={{ display: "flex" }}>
                                            <div style={{ width: 70, height: 30, backgroundColor: "#006273" }} />
                                            <Typography className="whiteText" variant="subtitle1" style={{ marginLeft: 5, marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                {"De la organización"}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            }
                        </Grid>
                    </>
                }
                {
                    (formData.evaluateSpecificSkills || (formData.evaluateTransversalSkills && formData.transversalSkills.filter(el => el.weight > 0).some(el => el.indicators.length > 0)))
                    && <>
                        <Divider style={{ margin: "10px 0px", background: color, height: 2 }} />
                        <Grid container item xs={12}>
                            <Typography className="whiteText" style={{ fontSize: 20, width: '100%' }}>
                                {"Competencias"}
                            </Typography>
                            {
                                formData.evaluateSpecificSkills &&
                                <Grid container item xs={12} style={{ paddingLeft: 20 }}>
                                    <Typography className="whiteText" style={{ fontSize: 20, marginTop: 5, marginBottom: 10, width: '100%' }}>
                                        {"Competencias específicas del cargo"}
                                    </Typography>
                                    <Grid container item xs={12} style={{ padding: 10 }}>
                                        <Grid container item xs={12} style={{ marginBottom: 20 }}>
                                            <TableContainer component={Paper}>
                                                {createSkillTable("Competencia específica 1 del cargo", formData)}
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ padding: 10 }}>
                                        <Grid container item xs={12} style={{ marginBottom: 20 }}>
                                            <TableContainer component={Paper}>
                                                {createSkillTable("Competencia específica 2 del cargo", formData)}
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                formData.evaluateTransversalSkills && formData.transversalSkills.filter(el => el.weight > 0).some(el => el.indicators.length > 0) &&
                                <>
                                    <Grid container item xs={12} style={{ paddingLeft: 20 }}>
                                        <Typography className="whiteText" style={{ fontSize: 20, marginTop: 30, marginBottom: 10, width: '100%' }}>
                                            {"Competencias transversales"}
                                        </Typography>
                                        <Grid container item xs={12} style={{ padding: 10 }}>
                                            {formData.transversalSkills.filter(el => el.weight > 0 && el.indicators.length > 0).map((el, index) => (
                                                <Grid container item xs={12} style={{ marginBottom: 20 }} key={index}>
                                                    <TableContainer component={Paper}>
                                                        {createSkillTable(el.value, formData, el)}
                                                    </TableContainer>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </>
                }
                {
                    formData.evaluateTargets && (formData.evaluatePersonalTargets || formData.evaluateOrganizationalTargets) &&
                    <>
                        <Divider style={{ margin: "10px 0px", background: color, height: 2 }} />
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography className="whiteText" style={{ fontSize: 20, width: '100%' }}>
                                    {"Metas y objetivos"}
                                </Typography>
                            </Grid>
                            {formData.evaluatePersonalTargets &&
                                <Grid container item xs={12} style={{ padding: 10 }}>
                                    <Typography className="whiteText" style={{ fontSize: 18, width: '100%' }}>
                                        {"Objetivos personales"}
                                    </Typography>
                                    <Grid container item xs={12} style={{ padding: '20px 10px' }}>
                                        <Grid item xs={12}>
                                            <Typography className="whiteText" style={{ fontSize: 16, fontWeight: 600 }}>
                                                {"Objetivo personal 1"}
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={12}>
                                            {createTargetForm()}
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: 5 }}>
                                            {
                                                !formData.targetsOnlyVisualization ?
                                                    <>
                                                        <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                            {"Estado: Activo"}
                                                        </Typography>
                                                        <div style={{ display: "inline-block", width: 43, height: 14, backgroundColor: `${theme.theme === "light" ? "#FF4040" : "#c7463c"}` }} />
                                                        <div style={{ display: "inline-block", marginRight: 10, width: 64, height: 14, backgroundColor: "#747474" }} />
                                                        <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                            {"40% - Fecha de término 05/01/2023 - Fecha de progreso: 12/12/2023 - Ponderado 20%"}
                                                        </Typography>
                                                    </> :
                                                    <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                        {"Ponderado 20%"}
                                                    </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ padding: '20px 10px' }}>
                                        <Grid item xs={12}>
                                            <Typography className="whiteText" style={{ fontSize: 16, marginRight: 10, fontWeight: 600 }}>
                                                {"Objetivo personal 2"}
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={12}>
                                            {createTargetForm()}
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: 5 }}>
                                            {
                                                !formData.targetsOnlyVisualization ?
                                                    <> <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                        {"Estado: Activo"}
                                                    </Typography>
                                                        <div style={{ display: "inline-block", marginRight: 10, width: 107, height: 14, backgroundColor: `${theme.theme === "light" ? "#00993D" : "#67ad34"}` }} />
                                                        <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                            {"100% - Fecha de término 10/07/2023 - Fecha de progreso: 15/12/2023 - Ponderado 40%"}
                                                        </Typography>
                                                    </>
                                                    :
                                                    <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                        {"Ponderado 20%"}
                                                    </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                formData.evaluateOrganizationalTargets &&
                                <Grid container item xs={12} style={{ padding: 10 }}>
                                    <Typography className="whiteText" style={{ fontSize: 18, width: '100%' }}>
                                        {"Objetivos organizacionales"}
                                    </Typography>
                                    <Grid container item xs={12} style={{ padding: '20px 10px' }}>
                                        <Grid item xs={12}>
                                            <Typography className="whiteText" style={{ fontSize: 16, fontWeight: 600 }}>
                                                {"Objetivo de la organización 1"}
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={12}>
                                            {createTargetForm()}
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: 5 }}>
                                            {
                                                !formData.targetsOnlyVisualization ?
                                                    <>
                                                        <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                            {"Estado: Activo"}
                                                        </Typography>
                                                        <div style={{ display: "inline-block", width: 43, height: 14, backgroundColor: `${theme.theme === "light" ? "#FF4040" : "#c7463c"}` }} />
                                                        <div style={{ display: "inline-block", marginRight: 10, width: 64, height: 14, backgroundColor: "#747474" }} />
                                                        <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                            {"40% - Fecha de término 05/01/2023 - Fecha de progreso: 12/12/2023 - Ponderado 20%"}
                                                        </Typography>
                                                    </>
                                                    :
                                                    <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                        {"Ponderado 20%"}
                                                    </Typography>
                                            }

                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ padding: '20px 10px' }}>
                                        <Grid item xs={12}>
                                            <Typography className="whiteText" style={{ fontSize: 16, marginRight: 10, fontWeight: 600 }}>
                                                {"Objetivo de la organización 2"}
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={12}>
                                            {createTargetForm()}
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: 5 }}>
                                            {
                                                !formData.targetsOnlyVisualization ?
                                                    <>
                                                        <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                            {"Estado: Activo"}
                                                        </Typography>
                                                        <div style={{ display: "inline-block", marginRight: 10, width: 107, height: 14, backgroundColor: `${theme.theme === "light" ? "#00993D" : "#67ad34"}` }} />
                                                        <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                            {"100% - Fecha de término 10/07/2023 - Fecha de progreso: 15/12/2023 - Ponderado 40%"}
                                                        </Typography>
                                                    </>
                                                    :
                                                    <Typography className="whiteText" variant="subtitle1" style={{ marginRight: 10, fontWeight: 400, display: "inline-block" }}>
                                                        {"Ponderado 20%"}
                                                    </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </>
                }
                {
                    formData.evaluatePotential &&
                    <>
                        <Divider style={{ margin: "10px 0px", background: color, height: 2 }} />
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography className="whiteText" style={{ fontSize: 20, width: '100%' }}>
                                    {`Preguntas para evaluar el potencial (${formData.potentialQuestions.length})`}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} style={{ padding: 10 }}>
                                {createPotentialQuestionsRepresentation()}
                            </Grid>
                        </Grid>
                    </>
                }
            </Card>
        </StepContainer >
    )
}


export default StepFive;
