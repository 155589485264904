import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSocietySelected } from "@icarius-pages/login/selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getAnnotationsAction } from "@icarius-pages/annotationsPage/actions";
import {
  getInitialPerformanceEvaluationsCalibrationAction,
  getListPerformanceEvaluationsCalibrationAction,
  getFormPerformanceEvaluationsCalibrationAction,
  getGraphicsPerformanceEvaluationsCalibrationAction,
  updateSkillsPerformanceEvaluationsCalibrationAction,
} from "../actions";
import {
  getIsLoading,
  getData,
  getFormData,
  getYearList,
  getPerformanceProcessList,
  getCalibrationCommitteeList,
  getGraphicsData,
  getFilterOptions,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";

const INITIAL_FILTER_DATA = {
  evaluator: [''],
  isCalibrated: [''],
  isAcceptedByEvaluated: [''],
  finalNoteStandardPerformance: [''],
  finalNoteCalibratedPerformance: [''],
  calibratedRanking: [''],
  calibratedQuadrant: [''],
  workPlace: [''],
  workOrTask: [''],
  costCenter: [''],
  management: [''],
  departament: [''],
  position: [''],
  funtion: [''],
  category: [''],
}

const usePerformanceEvaluationsCalibration = () => {

  const [isInitialScreenActive, setIsInitialScreenActive] = useState(true);
  const [initialScreenSelectedData, setInitialScreenSelectedData] = useState(null);
  const [selectedProcess, setSelectedProcess] = useState(null);

  const [filterData, setFilterData] = useState(INITIAL_FILTER_DATA);
  const [filtersDialogIsOpen, setFiltersDialogIsOpen] = useState(false);

  const [graphicsIsOpen, setGraphicsisOpen] = useState(false);

  const [calibrationFormIsOpen, setCalibrationFormisOpen] = useState(false);
  const [commentFunctionDialogIsOpen, setCommentFunctionDialogIsOpen] = useState(false);
  const [commentFunctionArgs, setCommentFunctionArgs] = useState(null);
  const [commentFunction, setCommentFunction] = useState(null);
  const [commentData, setCommentData] = useState(null);
  const [commentFunctionTitle, setCommentFunctionTitle] = useState("");

  const [annotationsData, setAnnotationsData] = useState(null);

  const [mailDialogIsOpen, setMailDialogIsOpen] = useState(false);
  const [mailDialogData, setMailDialogData] = useState(null);

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const yearList = useSelector(getYearList);
  const performanceProcessList = useSelector(getPerformanceProcessList);
  const calibrationCommitteeList = useSelector(getCalibrationCommitteeList);
  const data = useSelector(getData);
  const filterOptions = useSelector(getFilterOptions);
  const formData = useSelector(getFormData);
  const graphicsData = useSelector(getGraphicsData);
  const societySelected = useSelector(getSocietySelected);

  useEffect(() => {
    dispatch(getInitialPerformanceEvaluationsCalibrationAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleOpenInitialScreen = () => {
    dispatch(getInitialPerformanceEvaluationsCalibrationAction())
      .then(() => {
        setIsInitialScreenActive(true);
      });
  }

  const handleGetCalibrationListAndGrahics = (dataToSend) => {
    dispatch(getListPerformanceEvaluationsCalibrationAction({ ...dataToSend, filters: filterData }))
      .then(() => {
        dispatch(getGraphicsPerformanceEvaluationsCalibrationAction({ ...dataToSend, filters: filterData }))
          .then(() => {
            const performanceProcess = performanceProcessList[dataToSend.year].find((item) => item.key === dataToSend.performanceProcess);
            const commitee = calibrationCommitteeList.find((item) => item.key === dataToSend.calibrationCommittee);

            setSelectedProcess({
              ...performanceProcess,
              commitee: commitee?.value || "",
              commiteeCode: commitee?.key,
            });
            setInitialScreenSelectedData(dataToSend);
            setIsInitialScreenActive(false);
          });
      })
  }

  const handleOpenGraphics = () => {
    setGraphicsisOpen(true);
  }

  const handleCloseGraphics = () => {
    setGraphicsisOpen(false);
  }

  const handleOpenFiltersDialog = () => {
    setFiltersDialogIsOpen(true);
  }

  const handleCloseFiltersDialog = () => {
    setFiltersDialogIsOpen(false);
  }

  const handleApplyFilters = (newFilterData) => {
    let filterDataToUse = newFilterData || INITIAL_FILTER_DATA; // si no recibe newFilterData, se resetea con los iniciales

    setFilterData(filterDataToUse);

    const dataToSend = {
      ...initialScreenSelectedData,
      filters: filterDataToUse,
    };

    dispatch(getListPerformanceEvaluationsCalibrationAction(dataToSend))
      .then(() => {
        dispatch(getGraphicsPerformanceEvaluationsCalibrationAction(dataToSend))
          .then(() => {
            dispatch(openSnackbarAction({ msg: 'Filtros aplicados con éxito', severity: 'success' }));
            handleCloseFiltersDialog();
          })
      })
  }

  const handleOpenCalibrationForm = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una única evaluación del desempeño", severity: "warning" }));
      return;
    }

    // los OR son porque desde el aggrid de graficos vienen con otro nombre los campos
    dispatch(getFormPerformanceEvaluationsCalibrationAction({
      process: selectedProcess.key,
      evaluatedCode: selectedRows[0]["Código de empleado del evaluado"] || selectedRows[0].evaluatedCode,
      evaluatedName: selectedRows[0]["Evaluado"] || selectedRows[0].evaluated,
      photo: selectedRows[0]["Fotografía del evaluado"] || selectedRows[0].image,
    }))
      .then(() => {
        setCalibrationFormisOpen(true);
      });
  }

  const handleCloseCalibrationForm = () => {
    dispatch(getListPerformanceEvaluationsCalibrationAction({ ...initialScreenSelectedData, filters: filterData }))
      .then(() => {
        dispatch(getGraphicsPerformanceEvaluationsCalibrationAction({ ...initialScreenSelectedData, filters: filterData }))
          .then(() => {
            setCalibrationFormisOpen(false);
          })
      })
  }

  const handleUpdateSkills = (dataToSend) => {
    dispatch(updateSkillsPerformanceEvaluationsCalibrationAction({
      ...initialScreenSelectedData,
      filters: filterData,
      calibrationData: dataToSend,
    }))
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(getListPerformanceEvaluationsCalibrationAction({ ...initialScreenSelectedData, filters: filterData }))
            .then(() => {
              dispatch(getGraphicsPerformanceEvaluationsCalibrationAction({ ...initialScreenSelectedData, filters: filterData }));
            })
        }
      })
  }

  const handleSetCommentFunction = (index, commentF, args) => {
    if (index === 0) {
      setCommentFunctionTitle("Ha seleccionado la menor calificación, justifique con sus comentarios por favor");
    } else {
      setCommentFunctionTitle("Ha seleccionado la mayor calificación, justifique con sus comentarios por favor");
    }

    setCommentFunctionArgs({ function: commentF, args: args.slice(0, 4) });
    setCommentData({ comment: args[4] || "" });
    setCommentFunctionDialogIsOpen(true);
  }

  const handleCloseCommentFunctionDialog = () => {
    setCommentFunctionDialogIsOpen(false);
    setCommentFunction(null);
    setCommentFunctionArgs(null);
    setCommentFunctionTitle("");
  }

  const handleUnselectProcess = () => {
    handleOpenInitialScreen();
    setSelectedProcess(null);
    setInitialScreenSelectedData(null);
  }

  const handleOpenMailDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una única evaluación del desempeño", severity: "warning" }));
      return;
    }

    setMailDialogData({
      evaluatedCode: selectedRows[0]["Código de empleado del evaluado"],
      message: `${selectedRows[0]["Evaluado"]}, tu evaluación del desempeño ha sido calibrada, revísala nuevamente desde Gestión del desempeño/Resumen y resultados para aceptarla (${selectedProcess.value})`
    })
    setMailDialogIsOpen(true);
  }

  const handleCloseMailDialog = () => {
    setMailDialogIsOpen(false);
    setMailDialogData(null);
  }

  const handleOpenAnnotations = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(getAnnotationsAction(selectedRows[0]["Código de empleado del evaluado"]))
      .then(() => setAnnotationsData({
        employee: {
          "CODIGO DE EMPLEADO": selectedRows[0]["Código de empleado del evaluado"],
          "APELLIDO Y NOMBRE": selectedRows[0]["Evaluado"],
          "MANAGER": selectedRows[0]["manager"],
        },
        userDates: selectedRows[0].userDates,
      }));
  }

  const handleCloseAnnotations = () => {
    setAnnotationsData(null);
  }

  const state = {
    mailDialogIsOpen,
    mailDialogData,
    commentData,
    calibrationFormIsOpen,
    societySelected,
    selectedProcess,
    commentFunctionDialogIsOpen,
    commentFunction,
    commentFunctionTitle,
    annotationsData,
    commentFunctionArgs,
    isInitialScreenActive,
    graphicsIsOpen,
    filtersDialogIsOpen,
    filterData,
  }

  const handlers = {
    handleOpenInitialScreen,
    handleOpenCalibrationForm,
    handleCloseCalibrationForm,
    handleOpenAnnotations,
    handleCloseAnnotations,
    handleUnselectProcess,
    handleSetCommentFunction,
    handleCloseCommentFunctionDialog,
    handleGetCalibrationListAndGrahics,
    handleOpenMailDialog,
    handleCloseMailDialog,
    handleOpenGraphics,
    handleCloseGraphics,
    handleOpenFiltersDialog,
    handleCloseFiltersDialog,
    handleApplyFilters,
    handleUpdateSkills,
  }

  return {
    isLoading,
    yearList,
    performanceProcessList,
    calibrationCommitteeList,
    data,
    filterOptions,
    formData,
    graphicsData,
    state,
    handlers,
  }
}

export default usePerformanceEvaluationsCalibration;