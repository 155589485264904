import React from "react";
import {
    Grid,
    Typography,
    Checkbox,
    TextField,
    Tooltip,
} from "@material-ui/core";

const gridStyle = { height: 75, padding: "0px 10px" };

const StepContainer = ({ title, children }) => {
    return (
        <Grid container item xs={12} justify="center">
            <Grid container item sm={12} md={10}>
                <Typography className="whiteText" style={{ fontSize: 20, width: '100%', padding: '0px 10px' }}>
                    {title}
                </Typography>
                <Grid container item alignItems="center" justify="space-between" xs={12} style={{ paddingTop: 10 }}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

const StepThree = (props) => {
    const {
        formData,
        setFormValue,
    } = props;

    return (
        <StepContainer title={'Seleccione las competencias transversales y los ponderados que incluirá el formulario'}>
            <Grid container item xs={12} style={{ padding: "0px 10px" }}>
                <Grid item xs={12}>
                    <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                        {formData.evaluateTransversalSkills ?
                            "Al menos una competencia debe ser seleccionada. La sumatoria de las competencias debe ser 100%" :
                            "La sumatoria de las competencias debe ser 100%"}
                    </Typography>
                </Grid>
                {
                    formData["transversalSkills"].map((item, index) => {
                        return (
                            <Grid container item alignItems="center" xs={12} md={6}
                                key={index}>
                                <Grid container item xs={11} style={{ ...gridStyle, marginTop: 10, marginBottom: 10, background: 'var(--secondaryBackgroundColor)', borderRadius: 4 }}
                                    className='myDesktopShadow' alignItems="center">
                                    <Grid container item xs={8} alignItems="center">
                                        <Tooltip title={item.description}>
                                            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                                                {item.value}
                                            </Typography>
                                        </Tooltip>
                                        <Checkbox
                                            disabled={!formData.evaluateTransversalSkills || formData.inUse}
                                            style={{ cursor: "pointer" }}
                                            checked={parseInt(item.weight) > 0}
                                            onChange={() => setFormValue(parseInt(item.weight) > 0 ? 0 : 1, "transversalSkills", item.key)}
                                        />
                                    </Grid>
                                    <Grid container item justify="flex-end" xs={4} >
                                        <TextField
                                            required
                                            disabled={!formData.evaluateTransversalSkills || formData.inUse}
                                            margin={"none"}
                                            label={'Ponderado'}
                                            value={item.weight || 0}
                                            inputProps={{ maxLength: 100 }}
                                            onChange={(e) => setFormValue(e.target.value, "transversalSkills", item.key)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </StepContainer>
    )
}


export default StepThree;
