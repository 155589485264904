import React from 'react';
import {
    Paper,
    Grid,
    TableCell,
    TableRow,
    TableHead,
    Table,
    TableContainer,
    TableBody,
    Tooltip,
} from "@material-ui/core";
import Board from "./board";
import { getStatusResultBackgroundColor } from '../body';
import Score from './score';
import { formatNumberOrReturnUndefined } from '@icarius-utils/format';

const ResultsTable = ({ generalNote, data, sections }) => {
    return (
        <TableContainer component={Paper} style={{ marginTop: 10 }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3} style={{ fontWeight: 700, fontStyle: "italic", fontSize: 16 }}>Preguntas de potencial</TableCell>
                        <TableCell align="center">Evaluación Descendente</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        sections.map((section, sectionIndex) => {
                            return (
                                <React.Fragment key={sectionIndex}>
                                    <TableRow>
                                        <TableCell colSpan={3} style={{ fontWeight: 700, fontStyle: "italic" }}>{section}</TableCell>
                                        <TableCell />
                                    </TableRow>
                                    {
                                        data.potentialQuestions.filter(el => el.section === section).map((row, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <TableRow>
                                                        <TableCell />
                                                        <Tooltip placement="bottom-start" title={row?.description || ""}>
                                                            <TableCell component="th" scope="row">
                                                                {`${index + 1}. ${row.question}`}
                                                            </TableCell>
                                                        </Tooltip>
                                                        <TableCell align="center" component="th" scope="row" style={getStatusResultBackgroundColor(row.statusResult)}>
                                                            {row.statusResult}
                                                        </TableCell>
                                                        <Score data={row.qualification} />
                                                    </TableRow>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                    <TableRow>
                        <TableCell style={{ fontWeight: 700 }} colSpan={2} align="right">{"NOTA FINAL DE POTENCIAL"}</TableCell>
                        <TableCell style={{ fontWeight: 700 }} align="center">{formatNumberOrReturnUndefined(data.note, 1, 1)}</TableCell>
                        <TableCell align="center" style={{ fontWeight: 700, backgroundColor: data.noteColor, color: "black" }}>{data.noteString}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ fontWeight: 700 }} colSpan={2} align="right">{generalNote.isPartialNote ? "NOTA PARCIAL DE DESEMPEÑO" : "NOTA FINAL DE DESEMPEÑO"}</TableCell>
                        <TableCell style={{ fontWeight: 700 }} align="center">{formatNumberOrReturnUndefined(generalNote.note, 1, 1)}</TableCell>
                        <TableCell align="center" style={{ fontWeight: 700, backgroundColor: generalNote.noteColor, color: "black" }}>{generalNote.noteString}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const PotentialTable = ({ generalNote, summaryData }) => {

    // Obtengo un array de sections
    const sections = [...new Set(summaryData.potentialQuestions?.map(el => el.section))];

    return (
        <>
            <Grid container alignItems="center" style={{ padding: '0px 50px 0px 0px', marginTop: -5, marginBottom: 10 }}>
                <ResultsTable
                    generalNote={generalNote}
                    data={summaryData}
                    sections={sections}
                />
            </Grid>
            <Grid container xs={12}>
                <Grid item xs={12} lg={summaryData?.nineBoxClassification?.quadranClassification && summaryData?.calibratedNineBoxClassification?.quadranClassification ? 6 : 12}>
                    {
                        summaryData?.nineBoxClassification?.quadranClassification &&
                        <Board
                            title={"Clasificación del talento estándar"}
                            data={summaryData.nineBoxClassification}
                        />
                    }
                </Grid>
                <Grid item xs={12} lg={summaryData?.nineBoxClassification?.quadranClassification && summaryData?.calibratedNineBoxClassification?.quadranClassification ? 6 : 12}>
                    {
                        summaryData?.calibratedNineBoxClassification?.quadranClassification &&
                        <Board
                            title={"Clasificación del talento calibrada"}
                            data={summaryData.calibratedNineBoxClassification}
                        />
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default PotentialTable;