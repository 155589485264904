import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import IndicatorRenderer from "@icarius-table/renderersAndFilters/indicatorRenderer";
import ProgressRenderer from "@icarius-table/renderersAndFilters/progressRenderer";
import ProgressByRankingRendererDynamicColor from "@icarius-table/renderersAndFilters/progressByRankingRendererDynamicColor";
import { AnnotationsPage } from "@icarius-pages/annotationsPage";
import useTemplates from "@icarius-common/templates/components/useTemplates";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";
import usePerformanceEvaluationsCalibration from "./usePerformanceEvaluationsCalibration";
import CalibrationForm from "./calibrationForm";
import CommentDialog from "./commentDialog";
import InitialForm from "./initialForm";
import MailDialog from "./mailDialog";
import GraphicsPage from "./graphicsPage";
import FiltersDialog from "./filtersDialog";

const PerformanceEvaluationsCalibration = () => {

  const {
    isLoading,
    yearList,
    performanceProcessList,
    calibrationCommitteeList,
    data,
    filterOptions,
    formData,
    graphicsData,
    state,
    handlers,
  } = usePerformanceEvaluationsCalibration();

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates('PERFORMANCE_EVALUATION_CALIBRATION');

  const hasFilterApplied = Object.values(state.filterData).some((item) => item.some((filter) => Boolean(filter)));

  const selectInput = (gridRef) => (
    <TemplatesHeader
      gridRef={gridRef}
      user={user}
      templates={templates}
      selectedTemplate={selectedTemplate}
      getRole={getRole}
      handleImportTemplates={handleImportTemplates}
      handleExportTemplates={handleExportTemplates}
      handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
      handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
      handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
      handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
    />
  )

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handlers.handleUnselectProcess}
      type={"goBack"}
    />
  )

  const calibrateButton = (gridRef) => (
    <CustomIconButton
      title={'Calibrar evaluación de desempeño'}
      onClick={() => handlers.handleOpenCalibrationForm(gridRef)}
      type={"calibratePerformance"}
    />
  )

  const graphicsButton = () => (
    <CustomIconButton
      title={'Gráficos informativos'}
      onClick={handlers.handleOpenGraphics}
      type={"graphics"}
    />
  )

  const filterButton = () =>
    <CustomIconButton
      title={'Filtrar'}
      onClick={handlers.handleOpenFiltersDialog}
      type={"filter"}
      isSelected={hasFilterApplied}
    />

  const mailButton = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("sendMessage")}
      onClick={() => handlers.handleOpenMailDialog(gridRef)}
      type={"mail"}
    />

  const annotationsButton = (gridRef) => (
    <CustomIconButton
      title={'Anotaciones del colaborador'}
      onClick={() => handlers.handleOpenAnnotations(gridRef)}
      type={"annotations"}
    />
  )

  const child = (gridRef) =>
    <>
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(gridRef, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
    </>

  return (
    <>
      {
        state.isInitialScreenActive &&
        <InitialForm
          isLoading={isLoading}
          yearList={yearList}
          performanceProcessList={performanceProcessList}
          calibrationCommitteeList={calibrationCommitteeList}
          handleSubmit={handlers.handleGetCalibrationListAndGrahics}
        />
      }
      {
        state.calibrationFormIsOpen &&
        <CalibrationForm
          isLoading={isLoading}
          data={formData}
          handleClose={handlers.handleCloseCalibrationForm}
          handleSetCommentFunction={handlers.handleSetCommentFunction}
          selectedProcess={state.selectedProcess}
          isReadOnly={false}
        />
      }
      {
        state.commentFunctionDialogIsOpen &&
        <CommentDialog
          open={state.commentFunctionDialogIsOpen}
          isLoading={isLoading}
          title={state.commentFunctionTitle}
          handleClose={handlers.handleCloseCommentFunctionDialog}
          commentFunctionArgs={state.commentFunctionArgs}
          data={state.commentData}
        />
      }
      {
        state.mailDialogIsOpen &&
        <MailDialog
          open={state.mailDialogIsOpen}
          isLoading={isLoading}
          data={state.mailDialogData}
          handleClose={handlers.handleCloseMailDialog}
        />
      }
      {
        state.filtersDialogIsOpen &&
        <FiltersDialog
          open={state.filtersDialogIsOpen}
          initialValues={state.filterData}
          filterOptions={filterOptions}
          handleSubmit={handlers.handleApplyFilters}
          handleClose={handlers.handleCloseFiltersDialog}
        />
      }
      {
        Boolean(state.annotationsData) &&
        <AnnotationsPage
          employee={state.annotationsData.employee}
          userDates={state.annotationsData.userDates}
          handleClose={handlers.handleCloseAnnotations}
        />
      }
      {
        !Boolean(state.annotationsData) && !state.calibrationFormIsOpen && !state.isInitialScreenActive &&
        <CommonPage
          isLoading={isLoading || isLoadingTemplates}
          title={`Calibración: ${state.selectedProcess?.value} - Fecha desde ${state.selectedProcess?.periodFromDate} - Fecha hasta ${state.selectedProcess?.periodEndDate} - ${state.selectedProcess?.commitee}`}
          subtitle={<b style={{ color: "#d50000" }}>{'Solo evaluaciones descendentes y con nota final'}</b>}
          isNotGridPage
        >
          {
            !state.graphicsIsOpen &&
            <CommonPage
              gridTitle={state.processName}
              columnDefPage={paths.performanceEvaluationsCalibration}
              rowData={data}
              customHeader={selectInput}
              menuItems={[goBackButton, filterButton, graphicsButton, calibrateButton, annotationsButton, mailButton]}
              hasExpand
              hasHelp
              frameworkComponents={{ ProgressByRankingRendererDynamicColor, ProgressRenderer, IndicatorRenderer }}
              gridHeight={'calc(100vh - 220px)'}
            >
              {child}
            </CommonPage>
          }
          {
            state.graphicsIsOpen &&
            <GraphicsPage
              data={graphicsData}
              handleOpenCalibrationForm={handlers.handleOpenCalibrationForm}
              handleUpdateSkills={handlers.handleUpdateSkills}
              handleClose={handlers.handleCloseGraphics}
            />
          }
        </CommonPage>
      }
    </>
  )
}

export default PerformanceEvaluationsCalibration;