import React from "react";
import { Grid } from "@material-ui/core";
import Bar from "@icarius-pages/performanceSummaryAndResults/components/graphics/bar";
import Table from "@icarius-pages/performanceSummaryAndResults/components/graphics/table";
import { formatNumberExactDecimals } from "@icarius-utils/format";
import Container from "./container";

const Global = (props) => {

    const {
        data,
        noteTypeList,
        noteTypeRanges,
        noteColors,
        evaluatorList,
    } = props;

    const byNoteTypeTableData = (() => {
        return noteTypeList.map((noteType, index) => {
            return ([
                noteType,
                formatNumberExactDecimals(data.byNoteType[index], 1),
                formatNumberExactDecimals(noteTypeRanges[index].from, 1),
                formatNumberExactDecimals(noteTypeRanges[index].to, 1),
            ]);
        });
    })();

    const byNoteTypeGroupAndEvaluatorGraphData = (() => {
        return noteTypeList.map((noteType) => {
            return ({
                name: noteType,
                data: data.byNoteTypeGroupAndEvaluator.map((item) => item[noteType]),
            })
        })
    })();

    const byNoteTypeGroupAndEvaluatorTableData = (() => {
        return data.byNoteTypeGroupAndEvaluator.map((item, index) => {
            return ([
                evaluatorList[index],
                ...Object.values(item).map((item) => formatNumberExactDecimals(item || '', 1)),
            ]);
        })
            .sort((a, b) => {
                if (a[0] < b[0]) return -1;
                if (a[0] > b[0]) return 1;
                return 0;
            });
    })();

    return (
        <Container title={'Cantidades globales'}>
            <Grid container item xs={12} spacing={1} style={{ margin: '20px auto' }}>
                <Grid container item xs={12}>
                    <Bar
                        title={'Cantidad global de evaluaciones por tipo de nota'}
                        list={noteTypeList}
                        data={[{ data: data.byNoteType }]}
                        colors={[({ dataPointIndex }) => noteColors[dataPointIndex]]}
                    />
                </Grid>
                {
                    Boolean(byNoteTypeTableData.length) &&
                    <Grid container item xs={12}>
                        <Table
                            list={['Nota', 'Cantidad de evaluados', 'Nota desde', 'Nota hasta']}
                            data={byNoteTypeTableData}
                        />
                    </Grid>
                }
                <Grid container item xs={12} style={{ marginTop: 20 }}>
                    <Bar
                        title={'Cantidad global de evaluaciones por tipo de nota y evaluador'}
                        list={evaluatorList}
                        data={byNoteTypeGroupAndEvaluatorGraphData}
                        colors={noteColors}
                        minWidth={noteTypeList.length * evaluatorList.length * 50}
                    />
                </Grid>
                {
                    Boolean(byNoteTypeGroupAndEvaluatorTableData.length) &&
                    <Grid container item xs={12}>
                        <Table
                            list={['Evaluador', ...noteTypeList]}
                            data={byNoteTypeGroupAndEvaluatorTableData}
                        />
                    </Grid>
                }
            </Grid>
        </Container>
    )
}

export default Global;