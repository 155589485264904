import React from "react";
import { useDispatch } from "react-redux";
import {
    Grid,
    Select,
    MenuItem,
    FormControl,
    Typography,
    Radio,
    InputLabel,
} from "@material-ui/core";
import { openDialogAction } from "@icarius-common/dialog/actions";
import PublicationTypeList from "../../questions/publicationTypeList";

const gridStyle = { height: 75, padding: "0px 10px" };

const StepContainer = ({ title, children }) => {
    return (
        <Grid container item xs={12} justify="center">
            <Grid container item sm={12} md={10}>
                <Typography className="whiteText" style={{ fontSize: 20, width: '100%', padding: '0px 10px' }}>
                    {title}
                </Typography>
                <Grid container item alignItems="center" justify="space-between" xs={12} style={{ paddingTop: 10 }}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

const StepFour = (props) => {
    const {
        formData,
        setFormValue,
        scaleList,
        color,
    } = props;
    const dispatch = useDispatch();


    const disablePotential = (questions) => {
        if (questions.length > 0) {
            dispatch(openDialogAction({
                title: 'Atención',
                msg: `Si deshabilita la evaluación del potencial todas las preguntas existentes y relacionadas con ese tipo de la evaluación serán eliminadas`,
                onConfirm: () => setFormValue(false, "evaluatePotential"),
            }));
        } else {
            setFormValue(false, "evaluatePotential");
        }
    }

    return (
        <StepContainer title={'Potencial'}>
            <Grid container item xs={12} style={{ padding: "0px 10px" }}>
                <Grid container alignItems="center" item xs={6} sm={2}>
                    <Typography className="whiteText" variant="subtitle1" style={{ width: '100%' }}>
                        {"Evalúa potencial?"}
                    </Typography>
                </Grid>
                <Grid container alignItems="center" item xs={6} sm={2}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }} onClick={() => formData.inUse ? null : formData.type === "DES" && setFormValue(true, "evaluatePotential")}>
                        <Radio disabled={formData.inUse ? true : false} color='primary' checked={formData["evaluatePotential"] === true} />
                        <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                            {'Si'}
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => formData.inUse ? null : disablePotential(formData.potentialQuestions)}>
                        <Radio disabled={formData.inUse ? true : false} color='primary' checked={formData["evaluatePotential"] !== true} />
                        <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                            {'No'}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{ padding: "0px 10px" }}>
                <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {"Preguntas para evaluación del potencial"}
                </Typography>
            </Grid>
            <Grid container item xs={12} style={{ padding: "0px 10px" }}>
                <Typography className="whiteText" variant="subtitle2" style={{ display: "inline-block" }}>
                    {"Si define preguntas de potencial solo se verán en el formulario de evaluación Descendente"}
                </Typography>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                    <InputLabel id={'label-potentialScale'}>{'Escala para la clasificación de las preguntas de potencial'}</InputLabel>
                    <Select
                        disabled={formData.inUse ? true : formData["evaluatePotential"] === false}
                        value={formData.potentialScale}
                        labelId={'label-potentialScale'}
                        id={'select-potentialScale'}
                        onChange={(e) => setFormValue(e.target.value, "potentialScale")}
                        margin={"none"}
                    >
                        {
                            scaleList.map(item => (
                                <MenuItem
                                    className={"whiteText"}
                                    key={item.code}
                                    value={item.code}>
                                    {item.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid container item xs={12}>
                <PublicationTypeList
                    disabled={formData.inUse ? true : formData["evaluatePotential"] === false}
                    publicationArr={formData.potentialQuestions?.sort(function (a, b) { return a.number - b.number })}
                    color={color}
                    setFormValue={setFormValue}
                />
            </Grid>
        </StepContainer>
    )
}


export default StepFour;
