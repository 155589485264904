import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import Form from "./form";

const CreateEditForm = (props) => {

  const {
    name,
    isReadOnly,
    isLoading,
    processName,
    handleSetCommentFunction,
    data,
    handleClose,
  } = props;

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => handleClose(false)}
      type={"goBack"}
    />

  return (
    <CommonPage
      title={`Evaluación de desempeño`}
      menuItems={[goBackItem]}
      isLoading={isLoading}
      isNotGridPage
    >
      <Form
        name={name}
        isReadOnly={isReadOnly || data.isCalibrated}
        processName={processName}
        data={data}
        handleSetCommentFunction={handleSetCommentFunction}
        handleClose={handleClose}
      />
    </CommonPage>
  )
}
export default CreateEditForm;
