import React from "react";
import { useDispatch } from "react-redux";
import {
    Grid,
    Select,
    MenuItem,
    FormControl,
    Typography,
    Slider,
    Radio,
    InputLabel,
} from "@material-ui/core";
import { openDialogAction } from "@icarius-common/dialog/actions";

const fields = [
    ["Escala calificación competencias", "Ponderado competencias", "skillsScale", "skillsWeight"],
    ["Escala calificación objetivos", "Ponderado objetivos", "targetsScale", "targetsWeight"],
];

const evaluateFields = [
    ["Evalúa competencias específicas del cargo?", "evaluateSpecificSkills", "specificSkillsWeight"],
    ["Evalúa competencias transversales?", "evaluateTransversalSkills", "transversalSkillsWeight"],
    ["Evalúa objetivos personales?", "evaluatePersonalTargets", "personalTargetsWeight"],
    ["Evalúa objetivos de la organización?", "evaluateOrganizationalTargets", "organizationalTargetsWeight"],
];

const gridStyle = { height: 75, padding: "0px 10px" };

const StepContainer = ({ title, children }) => {
    return (
        <Grid container item xs={12} justify="center">
            <Grid container item sm={12} md={10}>
                <Typography className="whiteText" style={{ fontSize: 20, width: '100%', padding: '0px 10px' }}>
                    {title}
                </Typography>
                <Grid container item alignItems="center" justify="space-between" xs={12} style={{ paddingTop: 10 }}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

const StepTwo = (props) => {
    const {
        formData,
        setFormValue,
        scaleList,
        handleOpenScaleWarningDialog,
    } = props;
    const dispatch = useDispatch();


    const disableTransversalSkills = (transversalSkills) => {
        if (transversalSkills.length > 0 && transversalSkills.reduce((a, b) => parseFloat(a) + parseFloat(b.weight), 0) > 0) {
            dispatch(openDialogAction({
                title: 'Atención',
                msg: `Si deshabilita la evaluación de competencias transversales todas las competencias que fueron seleccionadas y relacionadas con ese tipo de a la evaluación serán eliminadas`,
                onConfirm: () => setFormValue(false, "evaluateTransversalSkills"),
            }));
        } else {
            setFormValue(false, "evaluateTransversalSkills");
        }
    }

    const setScaleValue = (value, field) => {
        const skillsScaleListElement = scaleList.find(el => el.code === formData.skillsScale);
        const targetsScaleListElement = scaleList.find(el => el.code === formData.targetsScale);
        const actualScaleListElement = scaleList.find(el => el.code === value);

        const maxValueSkillsScale = skillsScaleListElement?.details[skillsScaleListElement?.details.length - 1]["value"];
        const minValueSkillsScale = skillsScaleListElement?.details[0]["value"];
        const minValueTargetsScale = targetsScaleListElement?.details[0]["value"];
        const maxValueTargetsScale = targetsScaleListElement?.details[targetsScaleListElement?.details.length - 1]["value"];

        const maxValueActualScale = actualScaleListElement?.details[actualScaleListElement?.details.length - 1]["value"];
        const minValueActualScale = actualScaleListElement?.details[0]["value"];

        if (
            (
                field === "skillsScale" &&
                actualScaleListElement && targetsScaleListElement && (
                    minValueTargetsScale !== minValueActualScale ||
                    maxValueTargetsScale !== maxValueActualScale
                )
            )
            ||
            (
                field === "targetsScale" && (
                    minValueSkillsScale !== minValueActualScale ||
                    maxValueSkillsScale !== maxValueActualScale

                )
            )
        ) {
            handleOpenScaleWarningDialog();
        }

        setFormValue(value, field);
    }

    const evaluateSlots = evaluateFields.map(([fieldName, field1, field2], index) => {
        return (
            <Grid container alignItems="center" item xs={12} key={index}>
                <Grid container alignItems="center" item xs={4}>
                    <Typography className="whiteText" variant="subtitle1" style={{ width: '100%' }}>
                        {fieldName}
                    </Typography>
                </Grid>
                <Grid container alignItems="center" item xs={12} sm={4}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }} onClick={() => formData.inUse || (field1.includes("Targets") && formData.targetsOnlyVisualization) ? null : setFormValue(true, field1)}>
                        <Radio disabled={formData.inUse || (field1.includes("Targets") && formData.targetsOnlyVisualization) ? true : false} color='primary' checked={formData[field1] === true} />
                        <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                            {'Si'}
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => formData.inUse || (field1.includes("Targets") && formData.targetsOnlyVisualization) ? null : field1 === "evaluateTransversalSkills" ? disableTransversalSkills(formData.transversalSkills) : setFormValue(false, field1)}>
                        <Radio disabled={formData.inUse || (field1.includes("Targets") && formData.targetsOnlyVisualization) ? true : false} color='primary' checked={formData[field1] === false} />
                        <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                            {'No'}
                        </Typography>
                    </div>
                </Grid>
                <Grid container item alignItems="center" xs={12} sm={4} style={{ ...gridStyle, height: '' }}>
                    <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                        {'Ponderado'}
                    </Typography>
                    <Slider
                        disabled={formData.inUse || (field1.includes("Targets") && formData.targetsOnlyVisualization) ? true : formData[field1] !== true}
                        onChange={(_, value) => setFormValue(value, field2)}
                        min={0}
                        max={100}
                        step={1}
                        defaultValue={0}
                        value={parseInt(formData[field2])}
                    />
                    <Typography className="whiteText" variant="subtitle1" align="center" style={{ width: '100%' }}>
                        {parseInt(formData[field2])}%
                    </Typography>
                </Grid>
            </Grid>
        )
    });


    const slots = fields.map(([fieldName, fieldName2, field, field2], index) => {
        return (
            <Grid container item xs={12} key={index}>
                <Grid container item xs={12} md={7} style={{ height: 75, paddingRight: 10 }}>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel id={`label-${field}`}>{fieldName}</InputLabel>
                        <Select
                            disabled={formData.inUse || (formData.targetsOnlyVisualization && field === "targetsScale") ? true : false}
                            value={formData[field]}
                            labelId={`label-${field}`}
                            id={`select-${field}`}
                            onChange={(e) => setScaleValue(e.target.value, field, formData)}
                            margin={"none"}
                        >
                            <MenuItem
                                className={"whiteText"}
                                value={""}>
                                {""}
                            </MenuItem>
                            {
                                scaleList.map(item => (
                                    <MenuItem
                                        className={"whiteText"}
                                        key={item.code}
                                        value={item.code}>
                                        {item.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container item alignItems="center" xs={12} md={5} style={{ ...gridStyle, height: '' }}>
                    <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                        {fieldName2}
                    </Typography>
                    <Slider
                        disabled={formData.inUse || ((field.includes("Target") || field.includes("target")) && formData.targetsOnlyVisualization) ? true : false}
                        onChange={(_, value) => setFormValue(value, field2)}
                        min={0}
                        max={100}
                        step={1}
                        defaultValue={0}
                        value={parseInt(formData[field2])}
                    />
                    <Typography className="whiteText" variant="subtitle1" align="center" style={{ width: '100%' }}>
                        {parseInt(formData[field2])}%
                    </Typography>
                </Grid>
            </Grid >
        )
    });

    return (
        <StepContainer title={'Seleccione los tipos de evaluaciones que incluirá el formulario y sus ponderados'}>
            <Grid container item alignItems="center" xs={12} sm={6} style={{ padding: 10 }}>{slots[0]}</Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={{ padding: 10 }}>{evaluateSlots[0]}</Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={{ padding: 10 }} ><div></div></Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={{ padding: 10 }}>{evaluateSlots[1]}</Grid>
            <div style={{ width: "100%", color: "red", border: "1px solid red" }} />
            <Grid container item alignItems="center" xs={12} sm={6} style={{ padding: 10 }}>{slots[1]}</Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={{ padding: 10 }}>{evaluateSlots[2]}</Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={{ padding: 10 }}
                direction="column" >
                <Grid container item xs={12}>
                    <Grid container alignItems="center" item xs={7}>
                        <Typography className="whiteText" variant="subtitle1" style={{ width: '100%' }}>
                            {"Objetivos solo de visualización"}
                        </Typography>
                    </Grid>
                    <Grid container alignItems="center" item xs={12} sm={4}>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }} onClick={() => formData.inUse || formData.targetsWithPercentage ? null : setFormValue(true, "targetsOnlyVisualization")}>
                            <Radio disabled={formData.inUse || formData.targetsWithPercentage ? true : false} color='primary' checked={formData.targetsOnlyVisualization === true} />
                            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                                {'Si'}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => formData.inUse || formData.targetsWithPercentage ? null : setFormValue(false, "targetsOnlyVisualization")}>
                            <Radio disabled={formData.inUse || formData.targetsWithPercentage ? true : false} color='primary' checked={formData.targetsOnlyVisualization === false} />
                            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                                {'No'}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid container alignItems="center" item xs={7}>
                        <Typography className="whiteText" variant="subtitle1" style={{ width: '100%' }}>
                            {"Califica a los objetivos con %"}
                        </Typography>
                    </Grid>
                    <Grid container alignItems="center" item xs={12} sm={4}>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }} onClick={() => formData.inUse || formData.targetsOnlyVisualization ? null : setFormValue(true, "targetsWithPercentage")}>
                            <Radio disabled={formData.inUse || formData.targetsOnlyVisualization ? true : false} color='primary' checked={formData.targetsWithPercentage === true} />
                            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                                {'Si'}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => formData.inUse || formData.targetsOnlyVisualization ? null : setFormValue(false, "targetsWithPercentage")}>
                            <Radio disabled={formData.inUse || formData.targetsOnlyVisualization ? true : false} color='primary' checked={formData.targetsWithPercentage === false} />
                            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                                {'No'}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={{ padding: 10 }}>{evaluateSlots[3]}</Grid>
        </StepContainer>
    )
}


export default StepTwo;
