import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin0Max0,
	numberFormatterMin1Max1,
	numberFormatterMin2Max2,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Evaluador descendente',
            field: 'Evaluador',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Evaluado',
            field: 'Evaluado',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Estado de la nota',
            field: 'Estado de la nota',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Calibración realizada',
            field: 'Calibración realizada',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Evaluación aceptada por el evaluado',
            field: 'Evaluación aceptada por el evaluado',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Qty. calificaciones mínimas',
            field: 'Qty calificaciones mínimas',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Qty. calificaciones máximas',
            field: 'Qty calificaciones máximas',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Nota final desempeño calibrada',
            field: 'Nota final desempeño calibrada',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Nota numérica desempeño calibrada',
            field: 'Nota numérica desempeño calibrada',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            cellRenderer: "ProgressByRankingRendererDynamicColor",
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Ranking',
            field: 'Ranking',
            cellRenderer: "ProgressRenderer",
            filter: "ProgressFilter",
            cellClass: "percentageValue",
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Nota final desempeño estándar',
            field: 'Nota final desempeño estándar',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Nota numérica desempeño estándar',
            field: 'Nota numérica desempeño estándar',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: "Indicador de desempeño",
            field: "Indicador de desempeño",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            cellRenderer: 'IndicatorRenderer'
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Nota final potencial calibrada',
            field: 'Nota final potencial calibrada',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Nota numérica potencial calibrada',
            field: 'Nota numérica potencial calibrada',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Nota final potencial estándar',
            field: 'Nota final potencial estándar',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: 'Nota numérica potencial estándar',
            field: 'Nota numérica potencial estándar',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: "Indicador de potencial",
            field: "Indicador de potencial",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            cellRenderer: 'IndicatorRenderer'
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: "Fecha de calibración",
            field: "Fecha de calibración",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },

    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: "Responsable calibración",
            field: "Responsable calibración",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: "Comité de calibración",
            field: 'Grupo de calibración',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluationsCalibration],
        config: {
            headerName: "Comentarios de la calibración",
            field: "Comentarios de la calibración",
            filter: "agTextColumnFilter",
        }
    },

    // grilla 1 de graficos
    {
        pages: ['performanceEvaluationsCalibrationsBySkills'],
        config: {
            headerName: 'Competencia',
            field: 'skill',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsBySkills'],
        config: {
            headerName: 'Promedio competencias',
            field: 'skillAverage',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin1Max1,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },

    // grilla 2 de graficos
    {
        pages: ['performanceEvaluationsCalibrationsByEvaluated'],
        config: {
            headerName: 'Evaluado',
            field: 'evaluated',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
            pinned: "left",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsByEvaluated'],
        config: {
            headerName: 'Fotografía',
            field: 'image',
            filter: "agTextColumnFilter",
            cellRenderer: "AvatarRenderer",
            pinned: "left",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsByEvaluated'],
        config: {
            headerName: 'Promedio competencias',
            field: 'average',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin1Max1,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsByEvaluated'],
        config: {
            headerName: 'Fue calibrada',
            field: 'calibrated',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsByEvaluated'],
        config: {
            headerName: 'Categoría',
            field: "category",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsByEvaluated'],
        config: {
            headerName: 'Cargo',
            field: "position",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsByEvaluated'],
        config: {
            headerName: 'Función',
            field: "function",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsByEvaluated'],
        config: {
            headerName: 'Gerencia',
            field: "management",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsByEvaluated'],
        config: {
            headerName: 'Departamento',
            field: "department",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsByEvaluated'],
        config: {
            headerName: 'Obra o Faena',
            field: "workOrTask",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsByEvaluated'],
        config: {
            headerName: 'Centro de costo de gestión',
            field: 'costCenter',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsByEvaluated'],
        config: {
            headerName: 'Lugar de trabajo',
            field: 'workplace',
            filter: 'agSetColumnFilter',
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsByEvaluated'],
        config: {
            headerName: 'Tipo de funcionario',
            field: 'functionaryType',
            filter: 'agSetColumnFilter',
        }
    },

    // grilla evaluados y grupo de calibración
    {
        pages: ['performanceEvaluationsCalibrationsEvaluatedAndCalibrationGroup'],
        config: {
            headerName: 'Evaluador descendente',
            field: 'evaluator',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsEvaluatedAndCalibrationGroup'],
        config: {
            headerName: 'Nota final',
            field: 'finalNote',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsEvaluatedAndCalibrationGroup'],
        config: {
            headerName: 'Evaluado',
            field: 'evaluated',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsEvaluatedAndCalibrationGroup'],
        config: {
            headerName: 'Nota numérica',
            field: 'numericNote',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin1Max1,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsEvaluatedAndCalibrationGroup'],
        config: {
            headerName: 'Grupo',
            field: 'group',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsEvaluatedAndCalibrationGroup'],
        config: {
            headerName: 'Calificaciones mínimas',
            field: 'minQualification',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin1Max1,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsEvaluatedAndCalibrationGroup'],
        config: {
            headerName: 'Calificaciones máximas',
            field: 'maxQualification',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin1Max1,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },

    // grilla ranking
    {
        pages: ['performanceEvaluationsCalibrationsRanking'],
        config: {
            headerName: 'Evaluador',
            field: 'evaluator',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsRanking'],
        config: {
            headerName: 'Cantidad de evaluados',
            field: 'evaluatedAmount',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['performanceEvaluationsCalibrationsRanking'],
        config: {
            headerName: 'Ranking promedio',
            field: 'ranking',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin1Max1,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
]