import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const CustomTable = ({ list, data }) => {

  const getTableRows = () => {
    const rows = [];
    const fields = Object.keys(data[0]);

    for (let i = 0; i < data.length; i++) {
      const cells = [];
      for (let j = 0; j < data[i].length; j++) {
        const value = data[i][fields[j]];

        cells.push(
          <TableCell key={`${i}${j}`} align="center" style={{ width: '25%' }}>{value}</TableCell>
        )
      }

      rows.push(
        <TableRow key={i}>
          {cells}
        </TableRow>
      )
    }

    return rows;
  }

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {
              list.map((item, index) => {
                return (
                  <TableCell key={index} align="center" style={{ width: '25%' }}>{item}</TableCell>
                )
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {getTableRows()}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CustomTable;