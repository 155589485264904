import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createPerformanceEvaluationsAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { getAppColor } from "src/app/selectors";
import StepFive from "./steps/stepFive/index";

const Form = (props) => {
  const {
    name,
    isReadOnly,
    processName,
    data,
    handleClose,
    handleSetCommentFunction,
  } = props;

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);

  const create = (dataToSend) => {
    dispatch(createPerformanceEvaluationsAction({ dataToSend, process: data.process, evaluatedName: data.evaluatedName, photo: data.photo }))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, create, openValidationError);

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12} style={{ paddingBottom: 20 }}>
          <StepFive
            isReadOnly={isReadOnly}
            setFormValue={setFormValue}
            handleSetCommentFunction={handleSetCommentFunction}
            data={data}
            formData={formData}
            color={color}
            headerData={{ processName, processType: data.typeString, evaluatedPhoto: data.photo, evaluated: data.evaluatedName, evaluator: `Evaluador: ${isReadOnly ? (data.evaluatorName || "Sin evaluador asignado") : name} ${isReadOnly ? " (Acceso solo en visualización)" : ""}` }}
          />
        </Grid>
      </Grid>
      <Grid container item sm={12} justify="center">
        <Grid container item sm={12} md={10} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20, paddingRight: 10 }}>
          {!isReadOnly && <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />}
          {isReadOnly && <ButtonDialogAction isAccept onClick={() => handleClose(false)} text={"Volver"} />}
        </Grid>
      </Grid>

    </>
  );
}



export default Form;
