import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Typography,
  List,
  ListItemText,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";

const ScaleWarningDialog = (props) => {

  const {
    open,
    code,
    handleClose,
  } = props;

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`Atención`}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        {code ?
          <>
            <Grid container item xs={12}>
              <Typography className="whiteText" style={{ width: '100%', textAlign: 'justify', padding: '0px 10px' }}>
                {`Para el mismo código de formulario ${code} todas las escalas utilizadas para Competencias y Objetivos y para cualquier tipo de evaluación (Diseño de formulario) deben tener la misma calificación numérica para los máximos y mínimos`}
              </Typography>
            </Grid>
          </> :
          <>
            <Grid container item xs={12}>
              <Typography className="whiteText" style={{ width: '100%', textAlign: 'justify', padding: '0px 10px' }}>
                {`"La escala de objetivos" y "La escala de competencias" debe tener el mismo valor de calificación numérica para el mínimo y el máximo`}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Typography className="whiteText" style={{ width: '100%', padding: '0px 10px' }}>
                {`Por ejemplo:`}
              </Typography>
            </Grid>
            <Grid container item xs={12} style={{ marginTop: 10 }}>
              <Typography className="whiteText" style={{ weight: 300, width: '100%', padding: '0px 10px' }}>
                {`Objetivos:`}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <List>
                <ListItemText className="truncate pl-8 whiteText" primary={"1. Bajo lo esperado     10,00"} disableTypography={true} />
                <ListItemText className="truncate pl-8 whiteText" primary={"2. Lo esperado          20,00"} disableTypography={true} />
                <ListItemText className="truncate pl-8 whiteText" primary={"3. Sobre lo esperado    30,00"} disableTypography={true} />
              </List>
            </Grid>
            <Grid container item xs={12} style={{ marginTop: 10 }}>
              <Typography className="whiteText" style={{ weight: 300, width: '100%', padding: '0px 10px' }}>
                {`Competencias:`}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <List>
                <ListItemText className="truncate pl-8 whiteText" primary={"1. Malo           10,00"} disableTypography={true} />
                <ListItemText className="truncate pl-8 whiteText" primary={"2. Regular        15,00"} disableTypography={true} />
                <ListItemText className="truncate pl-8 whiteText" primary={"3. Bueno          20,00"} disableTypography={true} />
                <ListItemText className="truncate pl-8 whiteText" primary={"4. Muy bueno      30,00"} disableTypography={true} />
              </List>
            </Grid>
          </>
        }
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog>
  );
}

export default ScaleWarningDialog;
