import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, invalidDataCallback) => {
  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      return data[fieldName] || "";
    }

    const fieldNames = [
      "comment",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'comment': yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = () => {
    if (dataIsValid()) {
      createCallback(formData);

      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    formData,
    setFormValue,
    submit,
  };
}

export default useHandleForm;
