import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import PerformanceResumeBox from "./performanceResumeBox";
import ListAltIcon from '@material-ui/icons/ListAlt';
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const HeaderDataDisplay = ({ level, name, value }) => {
    return (
        <Grid container item direction="column" xs={12} sm={6} md={level === "E" ? 3 : 4}>
            <Typography className="whiteText">
                {name}:
            </Typography>
            <Typography className="whiteText" style={{ fontWeight: 600 }}>
                {value}
            </Typography>
        </Grid>
    )
}

const Row = (props) => {

    const {
        level,
        data,
        handleClick,
        handleOpenControl,
    } = props;

    const color = useSelector(getAppColor);

    return (
        <Grid container item xs={12} spacing={2} style={{ paddingBottom: 40 }}>
            <Grid container item xs={12}>
                <Typography className="whiteText" style={{ fontSize: 20, fontWeight: 600 }}>
                    {data.processName}
                </Typography>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <HeaderDataDisplay level={level} name={'Estado'} value={data.status} />
                <HeaderDataDisplay level={level} name={'Período de evaluación'} value={`Del ${data.periodFromDate} al ${data.periodEndDate}`} />
                {
                    data.periodName &&
                    <HeaderDataDisplay level={level} name={'Período para evaluación de objetivos'} value={data.periodName} />
                }
                {
                    level === "E" &&
                    <Grid container item direction="column" xs={12} sm={2} md={1}>
                        <CustomIconButton
                            title={'Control del progreso'}
                            onClick={() => handleOpenControl(data.process, data.processName, data.periodName)}
                        >
                            <ListAltIcon />
                        </CustomIconButton>
                    </Grid>
                }
            </Grid>
            <Grid container item xs={12}>
                <Typography className="whiteText" style={{ display: 'block', paddingBottom: 5 }}>
                    Evaluaciones del desempeño que debes realizar:
                </Typography>
                <Grid container item xs={12} spacing={1}>
                    {
                        ["Descendente", "Ascendente", "Autoevaluación", "De pares"].map((item, index) => {
                            if (!data[item].total) return null;
                            return (
                                <Grid container item sm={12} md={3} key={index}>
                                    <PerformanceResumeBox
                                        name={item}
                                        progress={data[item].progress}
                                        total={data[item].total}
                                    />
                                </Grid>

                            )
                        })
                    }
                </Grid>
            </Grid>
            {
                data.hasEvaluations &&
                <Grid container item xs={12} justify="center">
                    <Button
                        variant={"outlined"}
                        style={{ backgroundColor: color, color: "white" }}
                        onClick={() => handleClick(data.process, data.processName, data.periodName)}
                    >
                        {"Acceder a las evaluaciones"}
                    </Button>
                </Grid>
            }
        </Grid>
    )
}

export default Row;