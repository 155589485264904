import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Publication from "@icarius-pages/feed/components/publication";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons/index";
import { getFeedbackPublicationCommentsAction } from "@icarius-pages/feedback/actions";
import { Alert } from "@material-ui/lab";

const FeedbackPublication = (props) => {

    const {
        isLoading,
        data,
        people,
        groups,
        tasks,
        comments,
        handleClose,
    } = props;

    const actions = {
        getComments: getFeedbackPublicationCommentsAction,
    }

    return (
        <Dialog
            open
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            maxWidth='md'
            fullWidth
            onClose={handleClose}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <div style={{ width: '96%' }}>
                    <Alert variant="filled" severity="info">
                        Para modificar este feedback o realizar comentarios, acceda a la función Mis Feedbacks desde Mi Escritorio
                    </Alert>
                </div>
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent>
                <Publication
                    data={data}
                    people={people}
                    groups={groups}
                    tasks={tasks}
                    comments={comments}
                    isLoading={isLoading}
                    actions={actions}
                    isFeedback
                    forceReadOnly
                />
            </DialogContent>
        </Dialog>
    )
}

export default FeedbackPublication;