import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  initialData: [],
  yearList: [],
  performanceProcessList: [],
  calibrationCommitteeList: [],
  data: [],
  filterOptions: null,
  formData: null,
  graphicsData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_INITIAL:
      return { ...state, isLoading: true };
    case actionTypes.GET_INITIAL_FULFILLED:
      return {
        ...state,
        isLoading: false,
        yearList: action.payload.yearList,
        performanceProcessList: action.payload.performanceProcessList,
        calibrationCommitteeList: action.payload.calibrationCommitteeList,
      };
    case actionTypes.GET_INITIAL_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        filterOptions: action.payload.filterOptions,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_FORM:
      return { ...state, isLoading: true };
    case actionTypes.GET_FORM_FULFILLED:
      return {
        ...state,
        isLoading: false,
        formData: action.payload.data,
      };
    case actionTypes.GET_FORM_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_GRAPHICS:
      return { ...state, isLoading: true };
    case actionTypes.GET_GRAPHICS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        graphicsData: action.payload.data,
      };
    case actionTypes.GET_GRAPHICS_REJECTED:
      return { ...state, isLoading: false };


    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.SEND_MAIL: {
      return { ...state, isLoading: true };
    }
    case actionTypes.SEND_MAIL_FULFILLED: {
      return { ...state, isLoading: false };
    }
    case actionTypes.SEND_MAIL_REJECTED: {
      return { ...state, isLoading: false };
    }

    case actionTypes.UPDATE_SKILLS: {
      return { ...state, isLoading: true };
    }
    case actionTypes.UPDATE_SKILLS_FULFILLED: {
      return { ...state, isLoading: false };
    }
    case actionTypes.UPDATE_SKILLS_REJECTED: {
      return { ...state, isLoading: false };
    }

    case actionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
