import React from "react";
import { Grid } from "@material-ui/core";
import Bar from "@icarius-pages/performanceSummaryAndResults/components/graphics/bar";
import Table from "@icarius-pages/performanceSummaryAndResults/components/graphics/table";
import { formatNumberExactDecimals } from "@icarius-utils/format";
import { googleChartColors } from "@icarius-utils/colors";
import CommonPage from "@icarius-common/commonPage";
import Container from "./container";

const Ranking = (props) => {

    const {
        data,
        evaluatorList,
        collaboratorList,
    } = props;

    const byGroupTableData = (() => {
        return evaluatorList.map((evaluator, index) => {
            return ({
                evaluatedAmount: data?.byEvaluatedAmount?.[index],
                ranking: data?.byEvaluator?.[index],
                evaluator,
            });
        });
    })();

    const byGroupAndCollaboratorTableData = (() => {
        return collaboratorList.map((collaborator, index) => {
            return ([
                collaborator,
                formatNumberExactDecimals(data?.byEvaluated?.[index], 1),
            ]);
        });
    })();

    return (
        <Container title={'Ranking'}>
            <Grid container item xs={12} spacing={1} style={{ margin: '20px auto' }}>
                <Grid container item xs={12}>
                    <Bar
                        title={'Promedio de ranking porcentual (%) por evaluador'}
                        list={evaluatorList}
                        data={[{ data: data?.byEvaluator }]}
                        colors={[({ dataPointIndex }) => googleChartColors[dataPointIndex]]}
                        horizontal
                    />
                </Grid>
                {
                    Boolean(byGroupTableData.length) &&
                    <Grid container item xs={12}>
                        <CommonPage
                            gridTitle={'Promedio de ranking porcentual (%) global y por grupos'}
                            rowData={byGroupTableData}
                            columnDefPage={'performanceEvaluationsCalibrationsRanking'}
                            hasExpand
                            ignoreProcessesbyPath
                        />
                    </Grid>
                }
                <Grid container item xs={12} style={{ marginTop: 20 }}>
                    <Bar
                        title={'Ranking porcentual (%) por evaluado'}
                        list={collaboratorList}
                        data={[{ data: data?.byEvaluated }]}
                        colors={[({ dataPointIndex }) => googleChartColors[dataPointIndex]]}
                    />
                </Grid>
                {
                    Boolean(byGroupAndCollaboratorTableData.length) &&
                    <Grid container item xs={12}>
                        <Table
                            list={['Evaluado', 'Ranking']}
                            data={byGroupAndCollaboratorTableData}
                        />
                    </Grid>
                }
            </Grid>
        </Container>
    )
}

export default Ranking;