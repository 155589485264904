import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
    numberFormatterMin2,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Código interno',
            field: 'internalCode',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Tipo de evaluación',
            field: 'typeString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Código de empleado',
            field: 'evaluatedCode',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Apellido y nombres del evaluado',
            field: 'evaluatedName',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluations],
        config: {
            headerName: 'Apellido y nombres del evaluador',
            field: 'evaluatorName',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Estado de la evaluación',
            field: 'status',
            filter: "agSetColumnFilter",
            cellRenderer: "PerformanceFormStatusRenderer",
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Fecha de la evaluación',
            field: 'date',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Cargo del evaluado',
            field: 'evaluatedPosition',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Su calificación en competencias',
            field: 'qSkills',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Calificación calibrada competencias',
            field: 'qSkillsC',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Su calificación en objetivos',
            field: 'qTargets',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Calificación calibrada objetivos',
            field: 'qTargetsC',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceEvaluations],
        config: {
            headerName: 'Su calificación en potencial',
            field: 'qPotential',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceEvaluations],
        config: {
            headerName: 'Calificación calibrada potencial',
            field: 'qPotentialC',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Función',
            field: 'function',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Tipo de funcionario',
            field: 'functionaryType',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Gerencia',
            field: 'management',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Departamento',
            field: 'department',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Centro de costo de gestión',
            field: 'costCenter',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceEvaluations, "performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Fecha de contratación',
            field: 'contractDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.performanceEvaluations],
        config: {
            headerName: 'Comentarios del evaluador',
            field: 'comments',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["performanceEvaluationsCollaborator"],
        config: {
            headerName: 'Comentarios',
            field: 'comments',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Código interno',
            field: 'internalCode',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Tipo de evaluación',
            field: 'type',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Estado de la evaluación',
            field: 'status',
            filter: "agSetColumnFilter",
            cellRenderer: "PerformanceFormStatusRenderer",
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Apellido y Nombre del evaluador',
            field: 'evaluatorName',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Cargo del evaluador',
            field: 'evaluatorPosition',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Código de empleado',
            field: 'evaluatedCode',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Apellido y nombres del evaluado',
            field: 'evaluatedName',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Fecha de la evaluación',
            field: 'evaluationDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Cargo del evaluado',
            field: 'evaluatedPosition',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Función',
            field: 'function',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Tipo de funcionario',
            field: 'functionaryType',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Gerencia',
            field: 'management',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Departamento',
            field: 'department',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Centro de costo de gestión',
            field: 'costCenter',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Fecha de contratación',
            field: 'contractDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ["performanceEvaluationsAnalytics"],
        config: {
            headerName: 'Comentarios del evaluador',
            field: 'comments',
            filter: "agTextColumnFilter",
        }
    },
]