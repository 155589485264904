import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const HeaderDataDisplay = ({ name, value }) => {
    return (
        <Grid container item direction="column" xs={12} sm={6} md={4}>
            <Typography className="whiteText">
                {name}:
            </Typography>
            <Typography className="whiteText" style={{ fontWeight: 600 }}>
                {value}
            </Typography>
        </Grid>
    )
}

const Row = ({ data, handleClick }) => {

    const color = useSelector(getAppColor);
    
    return (
        <Grid container item xs={12} spacing={2} style={{ paddingBottom: 40 }}>
            <Grid container item xs={12}>
                <Typography className="whiteText" style={{ fontSize: 20, fontWeight: 600 }}>
                    {data.processName}
                </Typography>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <HeaderDataDisplay name={'Estado'} value={data.status} />
                <HeaderDataDisplay name={'Período de evaluación'} value={`Del ${data.periodFromDate} al ${data.periodEndDate}`} />
                {
                    data.periodName &&
                    <HeaderDataDisplay name={'Período para evaluación de objetivos'} value={data.periodName} />
                }
            </Grid>
            <Grid container item xs={12} justify="center">
                <Button
                    variant={"outlined"}
                    style={{ backgroundColor: color, color: "white" }}
                    onClick={() => handleClick(data)}
                >
                    {"Resumen y resultados"}
                </Button>
            </Grid>
        </Grid>
    )
}

export default Row;