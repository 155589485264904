import React from "react";
import { Grid, Typography } from "@material-ui/core";
import {
    PerformanceFormUpwardIcon,
    PerformanceFormDownwardIcon,
    PerformanceFormSelfEvaluationIcon,
    PerformanceFormPeerEvaluationIcon,
} from "@icarius-icons";

const typeListIcons = {
    "Ascendente": <PerformanceFormUpwardIcon />,
    "Descendente": <PerformanceFormDownwardIcon />,
    "Autoevaluación": <PerformanceFormSelfEvaluationIcon />,
    "De pares": <PerformanceFormPeerEvaluationIcon />,
};

const ProgressBar = ({ progressPercentage, totalPercentage }) => {
    return (
        <div style={{ width: '100%', position: 'relative' }}>
            <div style={{ position: 'absolute', top: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Typography className="whiteText" align="center">
                    {`${progressPercentage}%`}
                </Typography>
            </div>
            {
                Number(progressPercentage) > 0 &&
                <div style={{ height: 22, display: "inline-block", backgroundColor: "#22b14c", width: `${progressPercentage}%` }} />
            }
            {
                Number(totalPercentage) > 0 &&
                <div style={{ height: 22, display: "inline-block", backgroundColor: "#ed1c24", width: `${totalPercentage}%` }} />
            }
        </div>
    );
}

const PerformanceResumeBox = ({ name, progress, total }) => {

    let progressPercentage = total > 0 ? parseInt(progress * 100 / total) : 0;
    let totalPercentage = total > 0 ? 100 - progressPercentage : 0;

    return (
        <Grid container item xs={12} style={{ borderRadius: 10, border: "1px solid var(--icons)" }}>
            <Grid container item xs={12} alignItems="center" style={{ margin: '5px 10px' }}>
                <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', gap: 5 }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 5 }}>
                        <div style={{ display: 'flex' }}>{typeListIcons[name]}</div>
                        <Typography className="whiteText" style={{ fontSize: 18 }}>
                            {name}
                        </Typography>
                    </div>
                    <Typography className="whiteText" style={{ fontSize: 14 }}>
                        {`${progress} de ${total}`}
                    </Typography>
                </div>
            </Grid>
            {
                total > 0 &&
                <Grid container item xs={12} style={{ padding: '5px 10px', paddingTop: 0 }}>
                    <ProgressBar
                        progressPercentage={progressPercentage}
                        totalPercentage={totalPercentage}
                    />
                </Grid>
            }
        </Grid>
    )
}

export default PerformanceResumeBox;