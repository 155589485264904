import React, { useRef } from "react";
import ReactApexChart from 'react-apexcharts';
import { formatNumberExactDecimals } from "@icarius-utils/format";
import { getChartWidth } from "@icarius-pages/pollReview/components/pollSelection";
import useGetRefWidthAndHeight from "@icarius-utils/hooks/useGetRefWidthAndHeight";
import Container from "./container";

const Bar = (props) => {

  const {
    title,
    list,
    data,
    colors,
    horizontal,
    minWidth, // se usa solo para no horizontal, es el ancho minimo permitido
  } = props;

  const ref = useRef();
  const { width: containerWidth } = useGetRefWidthAndHeight(ref);

  const chartWidth = getChartWidth(
    list.length, // cantidad de columnas
    containerWidth - 20, // el - 20 es por el padding
    minWidth,
  );

  const chartData = {
    series: data,
    options: {
      chart: {
        type: 'bar',
        height: 350,
        width: horizontal ? '100%' : chartWidth,
        toolbar: {
          show: false
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: horizontal,
          dataLabels: {
            position: horizontal ? 'center' : 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value) => formatNumberExactDecimals(value, 1),
        offsetY: horizontal ? 0 : -20,
        style: {
          colors: ['var(--mainText)'],
        }
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          }
        },
        active: {
          filter: {
            type: 'none',
          }
        },
      },
      xaxis: {
        categories: list,
        type: 'category',
        labels: {
          trim: !horizontal,
          style: {
            colors: 'var(--mainText)',
          },
          formatter: (value) => horizontal ? formatNumberExactDecimals(value, 1) : value,
        }
      },
      yaxis: {
        decimalsInFloat: 1,
        forceNiceScale: true,
        labels: {
          style: {
            colors: 'var(--mainText)',
          },
          formatter: (value) => !horizontal ? formatNumberExactDecimals(value, 1) : value,
        }
      },
      legend: {
        labels: {
          colors: 'var(--mainText)',
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      colors: colors,
      fill: {
        opacity: 1,
      },
    },
  };

  return (
    <Container title={title}>
      <div ref={ref} style={{ overflow: 'auto', padding: 10 }}>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type={chartData.options.chart.type}
          height={chartData.options.chart.height}
          width={chartData.options.chart.width}
        />
      </div>
    </Container>
  )
}

export default Bar;