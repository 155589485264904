import React from 'react';
import {
    Paper,
    Grid,
    TableCell,
    TableRow,
    TableHead,
    Table,
    TableContainer,
    TableBody,
    Tooltip,
} from "@material-ui/core";
import { getStatusResultBackgroundColor } from '../body';
import Score from './score';

const ResultsTable = ({ summaryData }) => {
    return (
        <TableContainer component={Paper} style={{ marginTop: 10 }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={4}></TableCell>
                        <TableCell align="center">Evaluación Descendente</TableCell>
                        <TableCell align="center">Autoevaluación</TableCell>
                        <TableCell align="center">Evaluación Ascendente</TableCell>
                        <TableCell align="center">Evaluación De Pares</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        summaryData.hasOwnProperty("specificSkills") && summaryData.specificSkills.length > 0 &&
                        <>
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={8} style={{ fontWeight: 700, fontStyle: "italic", fontSize: 16 }}>
                                    {"Competencias específicas del cargo"}
                                </TableCell>
                            </TableRow>
                            {
                                summaryData.specificSkills.map((row, rowIndex) => {
                                    return (
                                        <React.Fragment key={rowIndex}>
                                            <TableRow>
                                                <TableCell />
                                                <Tooltip title={row?.description || ""} placement="bottom-start">
                                                    <TableCell component="th" scope="row" colSpan={7} style={{ fontWeight: 700, textAlign: "justify" }}>
                                                        {row.value}
                                                    </TableCell>
                                                </Tooltip>
                                            </TableRow>
                                            {
                                                row.indicators.map((indicator) => (
                                                    <TableRow key={indicator.key}>
                                                        <TableCell />
                                                        <TableCell component="th" scope="row" />
                                                        <Tooltip title={indicator?.description || ""} placement="bottom-start">
                                                            <TableCell component="th" scope="row">
                                                                {indicator.value}
                                                            </TableCell>
                                                        </Tooltip>
                                                        <TableCell align="center" component="th" scope="row" style={getStatusResultBackgroundColor(indicator.statusResult)}>
                                                            {indicator.statusResult}
                                                        </TableCell>
                                                        <Score data={indicator.qualifications["DES"]} />
                                                        <Score data={indicator.qualifications["AUT"]} />
                                                        <Score data={indicator.qualifications["ASC"]} />
                                                        <Score data={indicator.qualifications["PAR"]} />
                                                    </TableRow>
                                                ))
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </>
                    }
                    {
                        summaryData.hasOwnProperty("transversalSkills") && summaryData.transversalSkills.length > 0 &&
                        <>
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={8} style={{ fontWeight: 700, fontStyle: "italic", fontSize: 16 }}>
                                    {"Competencias transversales"}
                                </TableCell>
                            </TableRow>
                            {
                                summaryData.transversalSkills.map((row, rowIndex) => {
                                    return (
                                        <React.Fragment key={rowIndex}>
                                            <TableRow>
                                                <TableCell />
                                                <Tooltip title={row?.description || ""} placement="bottom-start">
                                                    <TableCell component="th" scope="row" colSpan={7} style={{ fontWeight: 700, textAlign: "justify" }}>
                                                        {row.value}
                                                    </TableCell>
                                                </Tooltip>
                                            </TableRow>
                                            {
                                                row.indicators.map((indicator) => (
                                                    <TableRow key={indicator.key}>
                                                        <TableCell />
                                                        <TableCell component="th" scope="row" />
                                                        <Tooltip title={indicator?.description || ""} placement="bottom-start">
                                                            <TableCell component="th" scope="row" style={{ textAlign: "justify" }}>
                                                                {indicator.value}
                                                            </TableCell>
                                                        </Tooltip>
                                                        <TableCell align="center" component="th" scope="row" style={getStatusResultBackgroundColor(indicator.statusResult)}>
                                                            {indicator.statusResult}
                                                        </TableCell>
                                                        <Score data={indicator.qualifications["DES"]} />
                                                        <Score data={indicator.qualifications["AUT"]} />
                                                        <Score data={indicator.qualifications["ASC"]} />
                                                        <Score data={indicator.qualifications["PAR"]} />
                                                    </TableRow>
                                                ))
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const SkillsTable = ({ summaryData }) => {

    return (
        <Grid container alignItems="center" style={{ padding: '0px 50px 0px 0px', marginTop: -5, marginBottom: 10 }}>
            <ResultsTable summaryData={summaryData} />
        </Grid>
    )
}

export default SkillsTable;