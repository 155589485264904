import React, { useState } from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import PerformanceFormStatusRenderer from "@icarius-table/renderersAndFilters/performanceFormStatusRenderer";
import { AnnotationsPage } from "@icarius-pages/annotationsPage";
import StyledHelpMenu from "@icarius-common/styledMenu";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import usePerformanceEvaluations from "./usePerformanceEvaluations";
import CreateEditForm from "./createEditForm";
import ResumeScreen from "./resumeScreen";
import CommentDialog from "./commentDialog/";

const PerformanceEvaluations = () => {

  const {
    name,
    level,
    data,
    initialData,
    resumeData,
    isLoading,
    state,
    handlers,
  } = usePerformanceEvaluations();
  const [anchorEl, setAnchorEl] = useState(null);

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handlers.handleUnselectProcess}
      type={"goBack"}
    />
  )

  const annotationsButton = (gridRef) => level !== 'C' && (
    <CustomIconButton
      title={'Anotaciones del colaborador'}
      onClick={() => handlers.handleOpenAnnotations(gridRef)}
      type={"annotations"}
    />
  )

  const handleSelectProcess = (process, processName, periodName) => {
    handlers.setSelectedProcess(process);
    handlers.setProcessName(processName);
    handlers.setPeriodName(periodName);
  }

  const handleOpenControl = (process, processName, periodName) => {
    handlers.setSelectedProcess(process);
    handlers.setProcessName(processName);
    handlers.setPeriodName(periodName);
    handlers.handleOpenDetailTable();
  }

  const sendMessageItem = (gridRef) =>
    <>
      <CustomIconButton
        title={getLocalizedString("sendMessage")}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        type={"mail"}
      />
      <StyledHelpMenu
        anchorEl={anchorEl}
        isOpen={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItemWithIcon
          text={'Enviar mensaje de inicio de calificaciones'}
          onClick={() => {
            handlers.handleSendMail(gridRef, "performanceEvaluationsCalificationStart");
            setAnchorEl(null);
          }}
        />
        <MenuItemWithIcon
          text={'Enviar recordatorio de pendiente'}
          onClick={() => {
            handlers.handleSendMail(gridRef, "performanceEvaluations");
            setAnchorEl(null);
          }}
        />
      </StyledHelpMenu>
    </>

  return (
    <>
      {
        !Boolean(state.annotationsData) && !state.selectedProcess && !state.progressControlIsOpen &&
        <ResumeScreen
          isLoading={isLoading}
          data={resumeData}
          handleSelectProcess={handleSelectProcess}
          handleOpenControl={handleOpenControl}
          level={level}
        />
      }
      {
        !Boolean(state.annotationsData) && state.commentFunctionDialogIsOpen &&
        <CommentDialog
          open={state.commentFunctionDialogIsOpen}
          isLoading={isLoading}
          title={state.commentFunctionTitle}
          handleClose={handlers.handleCloseCommentFunctionDialog}
          commentFunctionArgs={state.commentFunctionArgs}
          data={state.commentData}
        />
      }
      {
        !Boolean(state.annotationsData) && state.createDialogIsOpen &&
        <CreateEditForm
          name={name}
          processName={state.processName}
          isLoading={isLoading}
          data={data}
          handleClose={handlers.handleCloseCreateEditDialog}
          handleSetCommentFunction={handlers.handleSetCommentFunction}
          isReadOnly={state.progressControlIsOpen}
        />
      }
      {
        Boolean(state.annotationsData) &&
        <AnnotationsPage
          employee={state.annotationsData.employee}
          userDates={state.annotationsData.userDates}
          handleClose={handlers.handleCloseAnnotations}
        />
      }
      {
        !Boolean(state.annotationsData) && !state.createDialogIsOpen && (state.progressControlIsOpen || state.selectedProcess) &&
        <CommonPage
          handleRowClick={handlers.handleOpenCreateDialog}
          isLoading={isLoading}
          title={`${state.progressControlIsOpen ? "Control del progreso" : "Evaluaciones del desempeño"}: ${state.processName}`}
          gridTitle={state.processName}
          columnDefPage={level === "E" ? paths.performanceEvaluations : "performanceEvaluationsCollaborator"}
          rowData={initialData}
          menuItems={state.progressControlIsOpen ?
            [goBackButton, sendMessageItem] :
            [goBackButton, annotationsButton]
          }
          hasExpand
          hasHelp
          frameworkComponents={{ PerformanceFormStatusRenderer }}
          codeFieldName={'evaluatedCode'}
        >
        </CommonPage>
      }
    </>
  )
}

export default PerformanceEvaluations;