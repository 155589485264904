import { useEffect, useState } from "react";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePerformanceFormsAction,
  getPerformanceFormsAction,
} from "../actions";
import {
  getEvaluationGroupsList,
  getData,
  getTransversalSkillsList,
  getLoading,
  getScaleList,
  getTypeList,
} from "../selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getSocietySelected, getTheme } from "@icarius-pages/login/selectors";
import { getAppColor } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";

const usePerformanceForms = () => {

  const [scaleWarningCode, setScaleWarningCode] = useState("");
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);
  const [scaleWarningDialogIsOpen, setScaleWarningDialogIsOpen] = useState(false);

  const [selectedData, setSelectedData] = useState(null); //seleccionado para editar y detalle

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const typeList = useSelector(getTypeList);
  const scaleList = useSelector(getScaleList);
  const transversalSkillsList = useSelector(getTransversalSkillsList);
  const evaluationGroupsList = useSelector(getEvaluationGroupsList);
  const societySelected = useSelector(getSocietySelected);
  const color = useSelector(getAppColor);
  const theme = useSelector(getTheme);

  useEffect(() => {
    dispatch(getPerformanceFormsAction());
  }, [dispatch])

  const handleOpenCreateDialog = () => {
    setSelectedData(null);
    setCreateDialogIsOpen(true);
  }

  const handleDelete = (document) => {
    dispatch(deletePerformanceFormsAction({ internalCode: document.internalCode }));
  }

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (documents[0].inUse) {
      dispatch(openSnackbarAction({ msg: 'No es posible eliminar el formulario porque está en uso en los procesos de evaluación', duration: 10000, severity: "error" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: '¿Esta seguro que desea eliminar el formulario de desempeño?',
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleOpenDuplicateDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedData(documents[0]);
    setDuplicateDialogIsOpen(true);
  }

  const handleCloseDuplicateDialog = (gridRef) => {
    setDuplicateDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (documents[0].inUse) {
      dispatch(openDialogAction({
        title: getLocalizedString("atention"),
        msg: 'Para este formulario hay datos que ya no pueden modificarse (Se mostrarán solo para visualización) porque el formulario está relacionado con uno o más procesos de evaluación del desempeño que aún no están "cerrados"',
        onConfirm: () => {
          setSelectedData(documents[0]);
          setEditDialogIsOpen(true);
        },
      }));
    } else {
      setSelectedData(documents[0]);
      setEditDialogIsOpen(true);
    }
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleOpenScaleWarningDialog = (code = "") => {
    setScaleWarningCode(code);
    setScaleWarningDialogIsOpen(true);

  }

  const handleCloseScaleWarningDialog = () => {
    setScaleWarningDialogIsOpen(false);
    setScaleWarningCode("");
  }

  const state = {
    scaleWarningDialogIsOpen,
    editDialogIsOpen,
    createDialogIsOpen,
    duplicateDialogIsOpen,
    selectedData,
    theme,
    color,
    societySelected,
    scaleWarningCode,
  }

  const handlers = {
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
    handleDelete,
    handleOpenDuplicateDialog,
    handleCloseDuplicateDialog,
    handleOpenScaleWarningDialog,
    handleCloseScaleWarningDialog,
  }

  return {
    data,
    typeList,
    scaleList,
    transversalSkillsList,
    evaluationGroupsList,
    isLoading,
    state,
    handlers,
  }
}

export default usePerformanceForms;