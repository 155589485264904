import { useState } from "react";

const useHandleForm = (data, createCallback, invalidDataCallback) => {
  const { transversalSkills, specificSkills, personalTargets, organizationalTargets, potentialQuestions } = data;
  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "transversalSkills" || fieldName === "specificSkills") {
        let clonedArray = fieldName === "specificSkills" ? [...specificSkills] : [...transversalSkills];
        return clonedArray;
      }

      if (fieldName === "personalTargets" || fieldName === "organizationalTargets") {
        let clonedArray = fieldName === "personalTargets" ? [...personalTargets] : [...organizationalTargets];
        return clonedArray;
      }

      if (fieldName === "potentialQuestions") {
        return potentialQuestions;
      }

      return data[fieldName] || "";
    }

    const fieldNames = [
      "transversalSkills",
      "specificSkills",
      "personalTargets",
      "organizationalTargets",
      "potentialQuestions",
      "comments",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = () => {
    if (
      (!data.targetsOnlyVisualization && data.targetsWithPercentage && formData.personalTargets.some(el => el.qualification < 0 || el.qualification === 0 || el.qualification > 100)) ||
      (!data.targetsOnlyVisualization && data.targetsWithPercentage && formData.organizationalTargets.some(el => el.qualification < 0 || el.qualification === 0 || el.qualification > 100)) ||
      (!data.targetsOnlyVisualization && formData.personalTargets.some(el => el.qualification === 0)) ||
      (!data.targetsOnlyVisualization && formData.organizationalTargets.some(el => el.qualification === 0)) ||
      formData.potentialQuestions.some(el => el.qualification === 0) ||
      formData.specificSkills.some(el => el.indicators.some(indicator => indicator.qualification === 0)) ||
      formData.transversalSkills.some(el => el.indicators.some(indicator => indicator.qualification === 0))
    ) {
      return false;
    }
    return true;
  }

  const submit = () => {
    if (dataIsValid()) {
      let dataToSend = {
        "process": data.process,
        "code": data.code,
        "type": data.type,
        "group": data.group,
        "evaluatedCode": data.evaluatedCode,
        "specificSkills": formData.specificSkills,
        "transversalSkills": formData.transversalSkills,
        "personalTargets": formData.personalTargets.map(el => ({ key: el.key, qualification: el.qualification, comments: el.comments })),
        "organizationalTargets": formData.organizationalTargets.map(el => ({ key: el.key, qualification: el.qualification, comments: el.comments })),
        "potentialQuestions": formData.potentialQuestions.map(el => ({ number: el.number, qualification: el.qualification, comments: el.comments })),
        "comments": formData.comments
      }
      createCallback(dataToSend);

      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName, code, scaleElemeent, comments) => {
    if (fieldName === "transversalSkills" || fieldName === "specificSkills") {
      let arrayToUse = fieldName === "specificSkills" ? [...specificSkills] : [...transversalSkills];

      // Busco el elemento correspondiente
      let elementIndex = arrayToUse.findIndex(el => el.value === code);

      if (elementIndex > -1) {
        // Clono el objeto
        let newData = { ...formData };

        // Voy a buscar la key correspondiente
        let indicatorIndex = arrayToUse[elementIndex].indicators.findIndex(el => el.value === scaleElemeent);

        if (indicatorIndex > -1) {
          newData[fieldName][elementIndex]["indicators"][indicatorIndex]["qualification"] = value;
          newData[fieldName][elementIndex]["indicators"][indicatorIndex]["comments"] = comments || "";

          setFormData({
            ...newData
          })
        }
      }
      return;
    }

    if (fieldName === "personalTargets" || fieldName === "organizationalTargets") {
      let arrayToUse = fieldName === "personalTargets" ? [...personalTargets] : [...organizationalTargets];

      // Busco el elemento correspondiente
      let elementIndex = arrayToUse.findIndex(el => el.key === code);
      if (elementIndex > -1) {
        // Clono el objeto
        let newData = { ...formData };

        newData[fieldName][elementIndex]["qualification"] = value;
        newData[fieldName][elementIndex]["comments"] = comments || "";

        setFormData({
          ...newData
        })
      }
      return;
    }

    if (fieldName === "potentialQuestions") {
      let arrayToUse = [...potentialQuestions];

      // Busco el elemento correspondiente
      let elementIndex = arrayToUse.findIndex(el => el.question === code);
      if (elementIndex > -1) {
        // Clono el objeto
        let newData = { ...formData };

        newData[fieldName][elementIndex]["qualification"] = value;

        setFormData({
          ...newData
        })
      }
      return;
    }


    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    formData,
    setFormValue,
    submit,
  };
}

export default useHandleForm;
