import React from "react";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const CustomExpansionPanelDetails = withStyles({
  root: {
    paddingTop: 0,
  },
})(ExpansionPanelDetails);

const CustomExpansionPanel = withStyles({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
    width: '100%'
  },
})(ExpansionPanel);

const CustomExpansionPanelSummary = withStyles({
  root: {
    padding: 0
  },
})(ExpansionPanelSummary);

export const ContainerTitle = ({ title }) => {
  return (
    <Typography className="whiteText" style={{ marginTop: 5, fontWeight: 900, fontSize: 24, marginBottom: 5 }}>
      {title}
    </Typography>
  )
}

const Container = ({ title, children }) => {

  const color = useSelector(getAppColor);

  return (
    <>
      <CustomExpansionPanel>
        <CustomExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <ContainerTitle title={title} />
        </CustomExpansionPanelSummary>
        <CustomExpansionPanelDetails>
          {children}
        </CustomExpansionPanelDetails>
      </CustomExpansionPanel>
      <div style={{ width: "100%" }}>
        <Divider style={{ margin: "5px 0px", background: color, height: 2 }} />
      </div>
    </>
  )
}

export default Container;